import React, { Component, FormEvent } from 'react';
import { useMsal, MsalContext, withMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Layout from '../layout/Layout';
import { CustomNavigationClient } from '../../utils/NavigationClient';
import * as UsuarioStore from '../../store/usuario/usuarioStore';
import * as LancamentoContaStore from '../../store/lancamentoConta/lancamentoContaStore';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { IPublicClientApplication } from "@azure/msal-browser";
import { Contas } from '../usuario/usuarioAPI';
import { Button, Form, Col, Row, DropdownButton, Dropdown, Accordion, Card, Pagination } from "react-bootstrap";
import GridLancamentoConta from './gridLancamentoConta';
import { LancamentoConta, TipoLancamento, tiposLancamentos } from './lancamentoContaAPI';
import { ResponsePagination } from '../communs';


type Props = {
    navigation: CustomNavigationClient,
    window?: () => Window,
    instance: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators & LancamentoContaStore.LancamentoContaState & typeof LancamentoContaStore.actionCreators;

type LancamentoContaState = {
    prevState: boolean;
    selectedAccount: Contas[];
    Contas: Contas[];
    isLoading: boolean;
    dataInicial: string;
    dataFinal: string;
    TiposLancamento: TipoLancamento[];
    selectedTiposLancamento: TipoLancamento[];
    lacamentoContaResponse: ResponsePagination<LancamentoConta> | undefined;
    tamanhoPagina: number;
    selectContasTodos: boolean;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
class LancamentoContaUI extends React.Component<Props, LancamentoContaState> {
    constructor(props: any) {
        super(props)
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        this.state = {
            selectedAccount: [],
            prevState: false,
            Contas: [],
            isLoading: false,
            dataInicial: datainicial.toISOString().split('T')[0],
            dataFinal: new Date().toISOString().split('T')[0],
            selectedTiposLancamento: tiposLancamentos,
            TiposLancamento: tiposLancamentos,
            lacamentoContaResponse: undefined,
            tamanhoPagina: 50,
            selectContasTodos: true
        };

    }

    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.state.Contas.find(a => a.id === event.target.value) as Contas;
        if (account != null && account != undefined) {
            if (this.state.selectedAccount?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedAccount: this.state.selectedAccount.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedAccount: [...this.state.selectedAccount, account]
                });
            }
        }
    };

    handleTipoChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.state.TiposLancamento.find(a => a.id === event.target.value) as TipoLancamento;
        if (account != null && account != undefined) {
            if (this.state.selectedTiposLancamento?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedTiposLancamento: this.state.selectedTiposLancamento.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedTiposLancamento: [...this.state.selectedTiposLancamento, account]
                });
            }
        }
    };

    handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        this.props.listarLancamentosContaAsync({
            contas: this.state.selectedAccount.map(c => c.id),
            dataInicial: this.state.dataInicial,
            dataFinal: this.state.dataFinal,
            pagina: 1,
            tamanhoPagina: this.state.tamanhoPagina,
            tiposLancamento: this.state.selectedTiposLancamento.map(t => t.id)
        }, this.props.instance, (responseLancamentos: ResponsePagination<LancamentoConta>) => {
            this.setState({ lacamentoContaResponse: responseLancamentos, isLoading: false });
        });
    };
    componentDidMount(): void {
        var datainicial = new Date();
        this.setState({ isLoading: true });
        datainicial.setUTCDate(1);
        if (this.props.contas == undefined || this.props.contas.length == 0) {
            this.props.getContasAsync(this.props.instance, (response) => {
                this.setState({ Contas: response.contas, selectedAccount: response.contas });
                this.props.listarLancamentosContaAsync({
                    contas: response.contas.map(c => c.id),
                    dataInicial: datainicial.toISOString().split('T')[0],
                    dataFinal: (new Date()).toISOString().split('T')[0],
                    pagina: 1,
                    tamanhoPagina: this.state.tamanhoPagina,
                    tiposLancamento: tiposLancamentos.map(t => t.id)
                }, this.props.instance, (responseLancamentos: ResponsePagination<LancamentoConta>) => {
                    this.setState({ lacamentoContaResponse: responseLancamentos, isLoading: false });
                });
            });
        } else {
            this.setState({ Contas: this.props.contas as Contas[], selectedAccount: this.props.contas as Contas[] });
            this.props.listarLancamentosContaAsync({
                contas: this.props.contas.map(c => c.id as string),
                dataInicial: datainicial.toISOString().split('T')[0],
                dataFinal: (new Date()).toISOString().split('T')[0],
                pagina: 1,
                tamanhoPagina: this.state.tamanhoPagina,
                tiposLancamento: tiposLancamentos.map(t => t.id)
            }, this.props.instance, (responseLancamentos: ResponsePagination<LancamentoConta>) => {
                this.setState({ lacamentoContaResponse: responseLancamentos, isLoading: false });
            });
        }

    }
    render(): React.ReactNode {
        const { selectedAccount, isLoading, selectedTiposLancamento } = this.state;
        return <AuthenticatedTemplate>
            <Layout navigation={this.props.navigation} isLoading={this.state.isLoading} permissoes={this.props.usuario?.permissoes}>
                <div className="container mt-5">
                    <h1>Lançamentos em Contas</h1>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Opções de filtro</Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col >
                                            <Form.Group controlId="selectConta">
                                                <Form.Label>Selecione a conta:</Form.Label>
                                                <DropdownButton title="Selecione as Contas" id="dropdown-contas" autoClose="outside">
                                                    <Dropdown.Item
                                                        key="select-selectConta-filtro"
                                                        as="label"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="select-selectConta-filtro"
                                                            label='Selecione Todos'
                                                            value='select-selectConta-filtro'
                                                            checked={this.state.selectContasTodos}
                                                            onChange={() => { this.setState({ selectedAccount: !this.state.selectContasTodos ? this.state.Contas : [], selectContasTodos: !this.state.selectContasTodos }) }}
                                                        />
                                                    </Dropdown.Item>
                                                    {this.state.Contas.map(conta => (
                                                        <Dropdown.Item
                                                            key={conta.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${conta.id}`}
                                                                label={conta.nome}
                                                                value={conta.id}
                                                                checked={selectedAccount.includes(conta)}
                                                                onChange={this.handleAccountChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>
                                        <Col >
                                            <Form.Group as={Col} controlId="tipoLancamento">
                                                <Form.Label>Tipo de Lançamento</Form.Label>
                                                <DropdownButton title="Selecione os tipos de lançamentos" id="dropdown-contas" autoClose="outside">

                                                    {this.state.TiposLancamento.map(lancamento => (
                                                        <Dropdown.Item
                                                            key={lancamento.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${lancamento.id}`}
                                                                label={lancamento.nome}
                                                                value={lancamento.id}
                                                                checked={selectedTiposLancamento.includes(lancamento)}
                                                                onChange={this.handleTipoChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Form.Group as={Col} controlId="dataInicio">
                                            <Form.Label>Data Inicial</Form.Label>
                                            <Form.Control type="date" value={this.state.dataInicial} onChange={(event) => this.setState({ dataInicial: new Date(event.target.value).toISOString().split('T')[0] })} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="dataFinal">
                                            <Form.Label>Data Final</Form.Label>
                                            <Form.Control type="date" value={this.state.dataFinal} onChange={(event) => this.setState({ dataFinal: new Date(event.target.value).toISOString().split('T')[0] })} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="tamanhoPagina">
                                            <Form.Label>Quantidade de Registros</Form.Label>
                                            <Form.Control as="input" type="number" value={this.state.tamanhoPagina} onChange={(event) => this.setState({ tamanhoPagina: parseFloat(event.target.value) })} />
                                        </Form.Group>
                                    </Row>
                                    <Button className='mt-4'
                                        variant="primary"
                                        type="submit"
                                        disabled={!selectedAccount || isLoading}
                                    >
                                        {isLoading ? "Pesquisando..." : "Pesquisar"}
                                    </Button>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <GridLancamentoConta
                        contas={this.state.Contas}
                        itensResponse={this.props.lancamentosGrid as ResponsePagination<LancamentoConta>}
                        addHandle={(lancamento, callback) => {
                            this.props.adicionarLancamentosContaAsync(lancamento, this.props.instance, (response) => {
                                alert('Lancamento adicionado com sucesso.');
                                callback(response);
                            })
                        }} updateHandle={(lancamento, callback) => {
                            this.props.atualizarLancamentosContaAsync(lancamento, this.props.instance, (response) => {
                                alert('Lancamento atualizado com sucesso.');
                                callback(response);
                            })
                        }} permissoes={this.props.usuario?.permissoes} onExcluirLancamento={(lancamento) => { }} />
                    <Pagination>
                        <Pagination.Prev
                            disabled={this.state.lacamentoContaResponse?.pagina === 1}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.listarLancamentosContaAsync({
                                    contas: this.state.selectedAccount.map(c => c.id),
                                    dataInicial: this.state.dataInicial,
                                    dataFinal: this.state.dataFinal,
                                    pagina: this.state.lacamentoContaResponse?.pagina as number - 1,
                                    tamanhoPagina: this.state.tamanhoPagina,
                                    tiposLancamento: tiposLancamentos.map(t => t.id)
                                }, this.props.instance, (responseLancamentos: ResponsePagination<LancamentoConta>) => {
                                    this.setState({ lacamentoContaResponse: responseLancamentos, isLoading: false });
                                });
                            }}
                        />

                        {[(new Array(this.state.lacamentoContaResponse?.totalPagina as number)).keys()].map((v, page) => (
                            <Pagination.Item
                                key={page + 1}
                                active={page + 1 === this.state.lacamentoContaResponse?.pagina}
                                onClick={() => {
                                    this.setState({ isLoading: true });
                                    this.props.listarLancamentosContaAsync({
                                        contas: this.state.selectedAccount.map(c => c.id),
                                        dataInicial: this.state.dataInicial,
                                        dataFinal: this.state.dataFinal,
                                        pagina: page + 1,
                                        tamanhoPagina: this.state.tamanhoPagina,
                                        tiposLancamento: tiposLancamentos.map(t => t.id)
                                    }, this.props.instance, (responseLancamentos: ResponsePagination<LancamentoConta>) => {
                                        this.setState({ lacamentoContaResponse: responseLancamentos, isLoading: false });
                                    });
                                }}
                            >
                                {page + 1}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next
                            disabled={this.state.lacamentoContaResponse?.pagina === this.state.lacamentoContaResponse?.totalPagina}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.listarLancamentosContaAsync({
                                    contas: this.state.selectedAccount.map(c => c.id),
                                    dataInicial: this.state.dataInicial,
                                    dataFinal: this.state.dataFinal,
                                    pagina: this.state.lacamentoContaResponse?.pagina as number + 1,
                                    tamanhoPagina: this.state.tamanhoPagina,
                                    tiposLancamento: tiposLancamentos.map(t => t.id)
                                }, this.props.instance, (responseLancamentos: ResponsePagination<LancamentoConta>) => {
                                    this.setState({ lacamentoContaResponse: responseLancamentos, isLoading: false });
                                });
                            }}
                        />
                    </Pagination>
                </div>
            </Layout>
        </AuthenticatedTemplate>
    }
}


export default connect(
    (state: ApplicationState) => ({
        ...state.usuarioState, ...state.lancamentoContaState
    }),
    { ...UsuarioStore.actionCreators, ...LancamentoContaStore.actionCreators })(LancamentoContaUI);