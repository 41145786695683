import { MsalContext,AuthenticatedTemplate,UnauthenticatedTemplate } from "@azure/msal-react";
import React, { ReactNode } from "react";
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Container, Row, Col, Image, Card, Placeholder, Tooltip, OverlayTrigger, Popover, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Loading from "../ui/Loading";
import { CustomNavigationClient } from "../../utils/NavigationClient";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import PromptEffect from "../ui/PromptEffect";
import WelcomeSection from './WelcomeSection';
import { ApplicationState } from '../../store';
import * as UsuarioStore from '../../store/usuario/usuarioStore';
import { connect } from 'react-redux';
import Home from './Home';
import LoadingHome from './Loading';

type HomeState = {
    prevState: boolean,
    showLoginModal: boolean
}
type Props = {
    navigation: CustomNavigationClient,
    window?: () => Window;
    instance: IPublicClientApplication;
}& UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators;



class PreHome extends React.PureComponent<Props, HomeState> {
    constructor(props: any) {
        super(props)
        this.state = {
            prevState: false,
            showLoginModal: false
        }

    }
    static contextType = MsalContext;
    context!: React.ContextType<typeof MsalContext>
    render(): ReactNode {
        return (<div>
            <UnauthenticatedTemplate>
                <Navbar bg="dark" expand="lg" variant='dark' className="shadow" >
                    <Link to="/" className='ms-3 navbar-brand' >
                        <div style={{ display: 'inline-flex', color: 'white', fontFamily:'TheSeasons' }}><span style={{ paddingRight: '2px', color: 'white', fontFamily:'Blanka' }}>BX</span><span style={{ color: '#C31E22', paddingRight: '2px', fontWeight:'bold' }}>|</span >Invest</div>
                    </Link>
                    <Navbar.Toggle aria-controls="navbar-nav" />
                    <Navbar.Collapse id="navbar-nav">
                    </Navbar.Collapse>
                    <Button className='me-3' variant="outline-primary" onClick={async () => {
                        const msalInstance = this.context.instance;
                        await msalInstance.loginPopup(loginRequest).then(
                            response => {
                                this.props.getUsuarioSSOAsync(
                                    { authority: response.authority, uniqueId: response.uniqueId, username: response.account?.username as string },
                                    msalInstance, (usuarioResponse) => {
                                        return response;
                                    });
                            }
                        );
                    }} >Entrar</Button>
                </Navbar>
                <Loading isLoading={this.props.status == 'loading'} />
                <div className="container mt-4">
                    {/* <WelcomeSection /> */}
                    <div className="home-invest">INVEST<span style={{color:'#004AAD'}}>.</span>
                    <div className="home-invest-subtitulo">um produto da <span style={{fontFamily: 'Blanka'}}>BX</span><span style={{color:'#C31E22', fontWeight:'bold'}}>|</span>Capital</div>
                    </div>
                </div>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <LoadingHome  navigation={this.props.navigation} instance={this.props.instance}/>
            </AuthenticatedTemplate>
        </div>)
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.usuarioState
    }),
    { ...UsuarioStore.actionCreators })(PreHome);