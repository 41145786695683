import { Produto, RequestPaginacao, ResponsePagination } from "../communs";
import ConnectionAPI from "../connection/connectionAPI";

export interface ListarProdutosRequest extends RequestPaginacao {
      tipos: string[];
}

export async function getListarProdutos(request: ListarProdutosRequest, connection: ConnectionAPI) {
    return await connection.Get<ResponsePagination<Produto>>(ConnectionAPI.Endpoints.ListarProdutos(request));
}
export async function postAdicionarProduto(request: Produto, connection: ConnectionAPI) {
    return await connection.Post<Produto>(ConnectionAPI.Endpoints.AdicionarProduto, JSON.stringify( request), {'Content-Type':'application/json' });
}

export async function postAtualizarProduto(request: Produto, connection: ConnectionAPI) {
    return await connection.Put<Produto>(ConnectionAPI.Endpoints.AtualizarProduto, JSON.stringify( request), {'Content-Type':'application/json' });
}