import React, { FormEvent } from 'react';
import { MsalContext } from "@azure/msal-react";
import { CustomNavigationClient } from '../../utils/NavigationClient';
import { ApplicationState } from '../../store';
import * as UsuarioStore from '../../store/usuario/usuarioStore';
import { connect } from 'react-redux';
import Layout from '../layout/Layout';
import { Button, Form, Container, Row, Col, Popover, DropdownButton, Dropdown } from 'react-bootstrap';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Contas, KPIContratoContaView, KPIDadosContaView, KPIRentabilidadeContaView } from '../usuario/usuarioAPI';
import IndicadorDadosConta from './IndicadorDadosConta';
import IndicadorDadosRentabilidade from './IndicadorDadosRentabilidade';
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import IndicadorContratoConta from './IndicadorContratoConta';
import * as ContratoStore from '../../store/contratos/contratoStore';
import { Contrato } from '../contratos/contratoAPI';
import { ResponsePagination, dataMaxima } from '../communs';

type HomeState = {
    prevState: boolean,
    showLoginModal: boolean,
    isLoading: boolean,
    selectedAccount: Contas[];
    Contas: Contas[];
    isTooltipVisible: boolean;
    isPoperOverVisible: boolean;
    kpiDadosConta: KPIDadosContaView | undefined;
    KPIRentabilidadeConta: KPIRentabilidadeContaView | undefined;
    KPIDadosContrato: any;
    tourstart: boolean;
    indextourstart: number;
    contratos: ResponsePagination<Contrato> | undefined;
    fechamentoContratos: KPIContratoContaView[] | [];
}
type Props = {
    navigation: CustomNavigationClient,
    window?: () => Window;
    instance: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators & ContratoStore.ContratoState & typeof ContratoStore.actionCreators;


type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
class Home extends React.PureComponent<Props, HomeState> {
    constructor(props: any) {
        super(props);
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        var exibirTourHome = localStorage.getItem('home-tour');
        this.state = {
            prevState: false,
            showLoginModal: false,
            isLoading: true,
            selectedAccount: [],
            Contas: [],
            isTooltipVisible: true,
            isPoperOverVisible: false,
            tourstart: exibirTourHome == null || exibirTourHome == 'true' ? true : false,
            indextourstart: 0,
            kpiDadosConta: { aportes: 0, bens: 0, lucroPrejuizo: 0, saldo: 0 },
            KPIRentabilidadeConta: { rentabilidades: [] },
            contratos: undefined,
            KPIDadosContrato: {},
            fechamentoContratos: []
        }

        this.cardDadosConta = React.createRef<IndicadorDadosConta>();
        this.cardDadosRentabilidade = React.createRef<IndicadorDadosRentabilidade>();
        this.cardDadosContrato = React.createRef<IndicadorContratoConta>();
    }
    cardDadosConta: React.RefObject<IndicadorDadosConta>;
    cardDadosRentabilidade: React.RefObject<IndicadorDadosRentabilidade>;
    cardDadosContrato: React.RefObject<IndicadorContratoConta>;
    static contextType = MsalContext;
    context!: React.ContextType<typeof MsalContext>

    handleDrawerToggle = () => {
        this.setState({ prevState: !this.state.prevState });
    };

    handleSubmitFiltroConta = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true, isPoperOverVisible: false });
        // this.props.getContasAsync(this.props.instance, (response) => {
        //     this.setState({ isLoading: false, Contas: response.contas, selectedAccount: response.contas });
        // });
    }
    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.state.Contas.find(a => a.id === event.target.value) as Contas;
        if (account != null && account != undefined) {
            if (this.state.selectedAccount?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedAccount: this.state.selectedAccount.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedAccount: [...this.state.selectedAccount, account]
                });
            }
        }
    };
    componentDidMount(): void {
        try {
            var dataCompetencia = new Date();
            dataCompetencia.setUTCDate(1);
            if (this.props.contas == null || this.props.contas.length == 0) {
                this.props.getContasAsync(this.props.instance, (response) => {
                    this.setState({ isLoading: false, Contas: response.contas, selectedAccount: response.contas });
                    this.props.getKPIDadosConta(this.props.instance, response.contas.map(c => c.id), (kpidadosconta) => {
                        this.setState({ kpiDadosConta: kpidadosconta });
                        if (this.cardDadosConta.current) {
                            this.cardDadosConta.current?.handleSetIsloading(false);
                            this.cardDadosConta.current?.handleSetSelectedAccount(response.contas);

                        }
                    });
                    this.props.getKPIRentablidadeConta(this.props.instance, response.contas.map(c => c.id), (kpiresponse) => {
                        this.setState({ KPIRentabilidadeConta: kpiresponse });
                        this.cardDadosRentabilidade.current?.handleSetIsloading(false);
                        this.cardDadosRentabilidade.current?.handleSetSelectedAccount(response.contas);
                    });
                    this.props.listarContratosContaAsync({ contas: response.contas.map(c => c.id), pagina: 1, tamanhoPagina: 100 }, this.props.instance, (responseContratos) => {
                        this.setState({ KPIDadosContrato: responseContratos, contratos: responseContratos });
                        this.cardDadosContrato.current?.handleSetIsloading(false);
                        this.cardDadosContrato.current?.handleSetSelectedAccount(response.contas);
                        this.props.getKPIContratoConta(this.props.instance, response.contas?.map(c => c.id as string) as [], dataCompetencia.toISOString().split('T')[0], (rep) => {
                            this.setState({ fechamentoContratos: rep });
                            this.cardDadosContrato.current?.handleSetIsloadingCompetencia(false);
                        });
                    });
                });
            } else {
                this.setState({ isLoading: false, Contas: this.props.contas as Contas[], selectedAccount: this.props.contas as Contas[] });
                this.props.getKPIDadosConta(this.props.instance, this.props.contas?.map(c => c.id as string), (kpidadosconta) => {
                    this.setState({ kpiDadosConta: kpidadosconta });
                    if (this.cardDadosConta.current) {
                        this.cardDadosConta.current?.handleSetIsloading(false);
                        this.cardDadosConta.current?.handleSetSelectedAccount(this.props.contas as []);

                    }
                });
                this.props.getKPIRentablidadeConta(this.props.instance, this.props.contas?.map(c => c.id as string), (kpiresponse) => {
                    this.setState({ KPIRentabilidadeConta: kpiresponse });
                    this.cardDadosRentabilidade.current?.handleSetIsloading(false);
                    this.cardDadosRentabilidade.current?.handleSetSelectedAccount(this.props.contas as []);
                });
                this.props.listarContratosContaAsync({ contas: this.props.contas?.map(c => c.id as string), pagina: 1, tamanhoPagina: 100 }, this.props.instance, (responseContratos) => {
                    this.setState({ KPIDadosContrato: responseContratos, contratos: responseContratos });
                    this.cardDadosContrato.current?.handleSetIsloading(false);
                    this.cardDadosContrato.current?.handleSetSelectedAccount(this.props.contas as []);
                    this.props.getKPIContratoConta(this.props.instance, this.props.contas?.map(c => c.id as string) as [], dataCompetencia.toISOString().split('T')[0], (rep) => {
                        this.setState({ fechamentoContratos: rep });
                        this.cardDadosContrato.current?.handleSetIsloadingCompetencia(false);
                    });
                });
            }

        } catch (error) {
            this.setState({ isLoading: false })
            if (this.cardDadosConta.current) {
                this.cardDadosConta.current?.handleSetIsloading(false);
                this.cardDadosRentabilidade.current?.handleSetIsloading(false);
                this.cardDadosContrato.current?.handleSetIsloadingCompetencia(false);
            }
        }
    }

    render() {
        const steps = [
            {
                title: 'BX Invest',
                intro: 'Seja bem vindo a BX Invest, aqui é seu portal do investidor, nele você acompanhará seus bens e investimentos.'
            },
            {
                element: '.page-home-kpi-rentabilidade',
                intro: 'Esse card você verá informações de suas rentabilidades.',
            },
            {
                element: '.page-home-kpi-dadosconta',
                intro: 'Esse card você verá informações resumidas de suas contas correntes.',
            }, {
                element: '.page-home-kpi-info',
                intro: 'Sempre que precisar de informações detalhadas de um card, você poderá clicar nesse ícone de informações e será iniciado uma explicação sobre o card.',
            }
        ];
        const { selectedAccount, isLoading } = this.state;

        return <Layout navigation={this.props.navigation} isLoading={this.props.status == 'loading'} permissoes={this.props.usuario?.permissoes}>

            <Container className='page-home' fluid>
                <Steps
                    enabled={this.state.tourstart}
                    steps={steps}
                    initialStep={this.state.indextourstart}
                    onExit={() => { localStorage.setItem('home-tour', 'false'); this.setState({ indextourstart: 0, tourstart: false }); }}
                    options={{ nextLabel: "Próximo", prevLabel: "Anterior", doneLabel: "Fim" }}
                />
                <Row>

                    <Col className='page-home-kpi-rentabilidade'>
                        <IndicadorDadosRentabilidade ref={this.cardDadosRentabilidade}
                            Contas={this.state.Contas}
                            initialLoading={this.state.isLoading}
                            OnFilterHandle={(contas) => {
                                this.props.getKPIRentablidadeConta(this.props.instance, contas.map(c => c.id), (responseKPI) => {
                                    this.setState({ KPIRentabilidadeConta: responseKPI });
                                    this.cardDadosRentabilidade.current?.handleSetIsloading(false);
                                })
                            }}
                            Rentabildades={this.state.KPIRentabilidadeConta?.rentabilidades ? this.state.KPIRentabilidadeConta?.rentabilidades.filter(p => {
                                if (p.valorInvestido > 0 || p.percentualLucroPrejuizo > 0) return p;
                            }) as [] : []}
                        />
                    </Col>
                    <Col className='page-home-kpi-dadosconta'>
                        <IndicadorDadosConta
                            ref={this.cardDadosConta}
                            Contas={this.state.Contas}
                            initialLoading={this.state.isLoading}
                            aportes={this.state.kpiDadosConta?.aportes}
                            bens={this.state.kpiDadosConta?.bens}
                            lucroPrejuizo={this.state.kpiDadosConta?.lucroPrejuizo}
                            saldo={this.state.kpiDadosConta?.saldo}
                            OnFilterHandle={(contas) => {
                                this.props.getKPIDadosConta(this.props.instance, contas.map(c => c.id), (responseKPI) => {
                                    this.setState({ kpiDadosConta: responseKPI });
                                    this.cardDadosConta.current?.handleSetIsloading(false);
                                })
                            }}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <IndicadorContratoConta
                            ref={this.cardDadosContrato}
                            Contas={this.state.Contas}
                            initialLoading={this.state.isLoading}
                            ContratosResponse={this.state.contratos as ResponsePagination<Contrato>}
                            FechamentoMesResponse={this.state.fechamentoContratos as KPIContratoContaView[]}
                            OnFilterHandle={(contas) => {
                                var dataCompetencia = new Date();
                                dataCompetencia.setUTCDate(1)
                                this.props.listarContratosContaAsync(
                                    { contas: contas.map(c => c.id as string), pagina: 1, tamanhoPagina: 100 }, this.props.instance, (responseKPI) => {
                                        this.setState({ contratos: responseKPI });
                                        this.cardDadosContrato.current?.handleSetIsloading(false);
                                        this.props.getKPIContratoConta(this.props.instance, contas.map(c => c.id as string), dataCompetencia.toISOString().split('T')[0], (rep) => {
                                            this.setState({ fechamentoContratos: rep });
                                            this.cardDadosContrato.current?.handleSetIsloadingCompetencia(false);
                                        });
                                    })
                            }}
                            OnCompetenciaHandle={(contas, competencia) => {
                                this.props.getKPIContratoConta(this.props.instance, contas.map(c => c.id as string), competencia, (rep) => {
                                    this.setState({ fechamentoContratos: rep });
                                    this.cardDadosContrato.current?.handleSetIsloadingCompetencia(false);
                                });
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Layout>
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.usuarioState, ...state.contratoState
    }),
    { ...UsuarioStore.actionCreators, ...ContratoStore.actionCreators })(Home);



//     Na plataforma, oferecemos os seguintes produtos e serviços:
// - Gestão de carteira: Aportes, Ações, Fundos Imobiliários, Opções, Proventos, Produtos BX;
// - BX Assitente: Assistente com inteligência Artificial para informar tudo sobre sua carteira e efetuar operações conforme seu consentimento;
// - BX Options: Robô inteligente que investe em estratégias de opções;
// - BX Credit: Produto com rendimentos em emprestimos garantidos por crédito de clientes;
// - BX Trade: Robô inteligente que faz operações de daytrade oferecendo uma maior rentabilidade;
// - BX Indicação: Indicações baseadas em oportunidades de mercado e com base em dados fundamentalistas, esse produto tem comunicação atravez dos canais Telegram, WhatsApp, E-mail, SMS e Telefone.
// - BX Repositório: Ecommerce de indicadores, código fonte de indicadores, robôs para plataforma de investimentos, treinamentos, cursos e tudo que um investidor possa oferecer para sua comunidade. 