import { Produto, ResponsePagination } from "../communs";
import ConnectionAPI from "../connection/connectionAPI";
import { LancamentoConta } from "../lancamentosConta/lancamentoContaAPI";
import { Contas } from "../usuario/usuarioAPI";



// public Guid  Id { get; set; }
// public string Codigo { get; set; }
// public string Descricao { get; set; }
// public string Nome { get; set; }
// public string Tipo { get; set; }
// public DateTime? Vencimento { get; set; }
// public string CodigoProdutoBase { get; set; }
// public decimal? Strike { get; set; }
// public bool? Ativo { get; set; }
// public string[]? CodigosAlternativos { get; set; }
// public DateTime? DataAtualizacao { get; set; } = DateTime.UtcNow;
// public Dictionary<string,string> DadosAdicionais { get; set; }


export interface Ativo {
    id: string;
    contaId: string;
    dataCompra: string;
    quantidade: number;
    precoCompra: number;
    tipoAtivo: string;
    produto: Produto;
    valorTotal: number;
    conta: Contas;
    observacao: string;
}

export interface ProcessamentoNotasCorretagem {
    id: string;
    contaId: string;
    data: string;
    valor: number;
    tipoProcessamento: string;
    corretoraId: string;
    status: string;
    lancamentosConta: LancamentoConta[];
    lancamentosAtivos: Ativo[];
    numeroNota: string;
}

export interface ProcessamentoNotasCorretagemRequest {
    contaId: string;
    pagina: number;
    tamanhoPagina: number;
    notas: string[];
}


export interface ListarProcessamentoNotasCorretagemRequest {
    contasId: string[];
    pagina: number;
    tamanhoPagina: number;
}

export async function postGerarProcessarNotasCorretagem(request: ProcessamentoNotasCorretagemRequest, connection: ConnectionAPI) {
    return await connection.Post<ResponsePagination<ProcessamentoNotasCorretagem>>(ConnectionAPI.Endpoints.GerarProcessarNotasCorretagem, JSON.stringify( request), {'Content-Type':'application/json' });
}

export async function getListarLancamentosPendenteAprovacao(request: Partial<ListarProcessamentoNotasCorretagemRequest>, connection: ConnectionAPI) {
    return await connection.Get<ResponsePagination<ProcessamentoNotasCorretagem>>(ConnectionAPI.Endpoints.ListarLancamentosPendenteAprovacao(request), {'Content-Type':'application/json' });
}

export async function putAprovarProcessamento(request: Partial<ProcessamentoNotasCorretagem>[], connection: ConnectionAPI) {
    return await connection.Put<Response>(ConnectionAPI.Endpoints.AprovarProcessamentoPendente, JSON.stringify( request), {'Content-Type':'application/json' });
}

export async function putRejeitarProcessamento(request: Partial<ProcessamentoNotasCorretagem>[], connection: ConnectionAPI) {
    return await connection.Put<Response>(ConnectionAPI.Endpoints.RejeitarProcessamentoPendente, JSON.stringify( request), {'Content-Type':'application/json' });
}