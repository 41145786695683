import { AccountInfo, IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest, loginRequest } from "../../authConfig";
import { DefaultConnection } from "../../features/connection/connectionAPI";
import { AppThunkAction } from "..";
import { Action, Reducer } from 'redux';
import { Ativo, ListarProcessamentoNotasCorretagemRequest, ProcessamentoNotasCorretagem, ProcessamentoNotasCorretagemRequest, getListarLancamentosPendenteAprovacao, postGerarProcessarNotasCorretagem, putAprovarProcessamento, putRejeitarProcessamento } from "../../features/importarNotas/importarNotasAPI";
import { ListarAtivosRequest, getListarAtivosConta, normalizarAtivoProduto, postAdicionarAtivo, postAtualizarAtivo } from "../../features/ativos/ativoAPI";
import { appInsights } from "../..";
import { ResponsePagination } from "../../features/communs";

export interface AtivoState {
    ativoResponse: Partial<ResponsePagination<Ativo>> | undefined;
    listarAtivosFiltro: Partial<ListarAtivosRequest> | undefined;
    status: 'idle' | 'loading' | 'failed';
}

const unloadedState: AtivoState = {
    ativoResponse: undefined,
    listarAtivosFiltro: undefined,
    status: 'idle',
};
interface RequestGetListaraAtivosAction {
    type: 'REQUEST_GET_LISTAR_ATIVOS';
}

interface ReceiveGetListarAtivosAction {
    type: 'RECEIVE_GET_LISTAR_ATIVOS';
    response: ResponsePagination<Ativo>;
}
interface RequestAdicionarAtivoAction {
    type: 'REQUEST_POST_ADICIONAR_ATIVO';
}

interface ReceiveAdicionarAtivoAction {
    type: 'RECEIVE_POST_ADICIONAR_ATIVO';
    response: Ativo;
}
interface RequestAtualizarAtivoAction {
    type: 'REQUEST_PUT_ATUALIZAR_ATIVO';
}

interface ReceiveAtualizarAtivoAction {
    type: 'RECEIVE_PUT_ATUALIZAR_ATIVO';
    response: Ativo;
}
interface RequestNormalizarAtivoProdutoAction {
    type: 'REQUEST_NORMALIZAR_ATIVO_PRODUTO';
}

interface ReceiveNormalizarAtivoProdutoAction {
    type: 'RECEIVE_NORMALIZAR_ATIVO_PRODUTO';
}
interface ReceiveErrorNotasAction {
    type: 'RECEIVE_POST_ERROR';
}
type KnownAction = RequestNormalizarAtivoProdutoAction | ReceiveNormalizarAtivoProdutoAction | RequestAtualizarAtivoAction | ReceiveAtualizarAtivoAction | ReceiveErrorNotasAction | RequestGetListaraAtivosAction | ReceiveGetListarAtivosAction | RequestAdicionarAtivoAction | ReceiveAdicionarAtivoAction;

export const actionCreators = {
    normalizarAtivoProduto: (instance: IPublicClientApplication, contas: string[],
        callback: () => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:normalizarAtivoProduto ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.ativoState && appState.ativoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_NORMALIZAR_ATIVO_PRODUTO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_NORMALIZAR_ATIVO_PRODUTO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    await normalizarAtivoProduto(DefaultConnection, contas);
                    dispatch({ type: 'RECEIVE_NORMALIZAR_ATIVO_PRODUTO' });
                    callback();
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao normalizar ativos com produtos pendentes:' + JSON.stringify(error));
            }

        },
    listarAtivosAsync: (request: ListarAtivosRequest, instance: IPublicClientApplication,
        callback: (response: ResponsePagination<Ativo>) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:listarAtivosAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.ativoState && appState.ativoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_GET_LISTAR_ATIVOS' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_GET_LISTAR_ATIVOS' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    actionCreators.listarAtivosAsync(request, instance, callback);
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await getListarAtivosConta(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_GET_LISTAR_ATIVOS', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao lista de lançamentos:' + JSON.stringify(error));
            }

        },
    adicionarAtivoAsync: (request: Ativo, instance: IPublicClientApplication,
        callback: (response: Ativo) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:getUsuarioSSOAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.ativoState && appState.ativoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_POST_ADICIONAR_ATIVO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_POST_ADICIONAR_ATIVO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    actionCreators.adicionarAtivoAsync(request, instance, callback);
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await postAdicionarAtivo(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_POST_ADICIONAR_ATIVO', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        },
    atualizarAtivoAsync: (request: Ativo, instance: IPublicClientApplication,
        callback: (response: Ativo) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:atualizarAtivoAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.ativoState && appState.ativoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_PUT_ATUALIZAR_ATIVO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_PUT_ATUALIZAR_ATIVO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await postAtualizarAtivo(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_PUT_ATUALIZAR_ATIVO', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        }
}




export const reducer: Reducer<AtivoState> = (state: AtivoState | undefined, incomingAction: Action): AtivoState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_GET_LISTAR_ATIVOS':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_GET_LISTAR_ATIVOS':
            return { ...state, ...{ status: 'idle', ativoResponse: action.response } }
        case 'REQUEST_POST_ADICIONAR_ATIVO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_POST_ADICIONAR_ATIVO':
            return { ...state, ...{ status: 'idle' } }
        case 'REQUEST_PUT_ATUALIZAR_ATIVO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_PUT_ATUALIZAR_ATIVO':
            return { ...state, ...{ status: 'idle' } }
        case 'REQUEST_NORMALIZAR_ATIVO_PRODUTO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_NORMALIZAR_ATIVO_PRODUTO':
            return { ...state, ...{ status: 'idle' } }
        case 'RECEIVE_POST_ERROR':
            return { ...state, ...{ status: 'failed' } }
        default:
            break;
    }

    return state;
}