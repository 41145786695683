import { AccountInfo, AuthenticationResult, IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../index';
import { apiRequest, loginRequest, msalConfig } from '../../authConfig';
import ConnectionAPI, { DefaultConnection } from '../../features/connection/connectionAPI';
import { Contas, getKPIContratoContaView, getKPIDadosContaView, getKPIRentabilidadeContaView, getUsuarioSSO, IUsuarioSSORequest, IUsuarioSSOResponse, KPIContratoContaView, KPIDadosContaView, KPIProcessarContratoConta, KPIProcessarRentabilidadeConta, KPIRentabilidadeContaView, postProcessarNotas, Usuario } from '../../features/usuario/usuarioAPI';
import { appInsights } from '../..';
import { ResponsePagination } from '../../features/communs';
import { AtivoB3, ConsultarRelatorioAtivoPendenteNormalizacao, ConsultarRelatorioPosicaoAtivo, MonitorarAtivos, RelatorioPosicaoItem } from '../../features/relatorios/posicaoAtivo/relatorioAPI';
import { Exception } from '@microsoft/applicationinsights-web';
import { Ativo } from '../../features/importarNotas/importarNotasAPI';


export interface RelatoriosState {
    RelatorioPosicaoAtivos: {
        status: 'idle' | 'loading' | 'failed';
        relatorioResponse: ResponsePagination<RelatorioPosicaoItem> | undefined;
    },
    RelatorioPendenteNormalizacao: {
        status: 'idle' | 'loading' | 'failed';
        relatorioResponse: ResponsePagination<Ativo> | undefined;
    },
    ativosB3: Map<string, AtivoB3>;
    status: 'idle' | 'loading' | 'failed';
}
const unloadedState: RelatoriosState = {
    RelatorioPosicaoAtivos: {
        status: 'idle',
        relatorioResponse: undefined
    },
    RelatorioPendenteNormalizacao: {
        status: 'idle',
        relatorioResponse: undefined
    },
    ativosB3: new Map<string, AtivoB3>(),
    status: 'idle'
};
interface RequestConsultarRelatorioPosicaoAtivoAction {
    type: 'REQUEST_CONSULTAR_REL_POSICAO_ATIVO';
}
interface ReceiveConsultarRelatorioPosicaoAtivoAction {
    type: 'RECEIVE_CONSULTAR_REL_POSICAO_ATIVO';
    response: ResponsePagination<RelatorioPosicaoItem> | undefined;
}
interface RequestRelPendenteNormalizacaoAction {
    type: 'REQUEST_CONSULTAR_REL_PENDENTE_NORMALIZACAO';
}
interface ReceiveRelPendenteNormalizacaoAction {
    type: 'RECEIVE_CONSULTAR_REL_PENDENTE_NORMALIZACAO';
    response: ResponsePagination<Ativo> | undefined;
}
interface RequestAtualizarAtivoB3Action {
    type: 'REQUEST_ATUALIZAR_ATIVO_B3';
}
interface ReceiveAtualizarAtivoB3Action {
    type: 'RECEIVE_ATUALIZAR_ATIVO_B3';
    response: AtivoB3;
}
interface ReceiveErrorNotasAction {
    type: 'RECEIVE_POST_ERROR';
}
type KnownAction = RequestRelPendenteNormalizacaoAction | ReceiveRelPendenteNormalizacaoAction | RequestAtualizarAtivoB3Action | ReceiveAtualizarAtivoB3Action | RequestConsultarRelatorioPosicaoAtivoAction | ReceiveConsultarRelatorioPosicaoAtivoAction | ReceiveErrorNotasAction;


export const actionCreators = {
    ConsultarRelatorioAtivosPendentesNormalizacao: (instance: IPublicClientApplication, pagina: number, tamanhoPagina: number,contas: string[], callback: (response: ResponsePagination<Ativo> | undefined) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log("Entrou:ConsultarRelatorioAtivosPendentesNormalizacao");
        const appState = getState();
        console.log("AppState", appState);
        if (appState && appState.relatoriosState && appState.relatoriosState.RelatorioPosicaoAtivos && appState.relatoriosState.RelatorioPosicaoAtivos.status !== "loading") {
            dispatch({ type: 'REQUEST_CONSULTAR_REL_PENDENTE_NORMALIZACAO' });
            const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
            appInsights.trackEvent({ name: 'REQUEST_CONSULTAR_REL_PENDENTE_NORMALIZACAO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
            var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });
            const bearer = `Bearer ${responseToken.accessToken}`;
            DefaultConnection.header = { "Authorization": bearer };
            try {
                const response = await ConsultarRelatorioAtivoPendenteNormalizacao(pagina, tamanhoPagina,contas, DefaultConnection);
                dispatch({ type: 'RECEIVE_CONSULTAR_REL_PENDENTE_NORMALIZACAO', response: response });
                callback(response);
            } catch (error) {
                dispatch({ type: 'RECEIVE_CONSULTAR_REL_PENDENTE_NORMALIZACAO', response: undefined });
                callback(undefined);
            }

        }
    },
    ConsultarRelatorioPosicaoAtivo: (instance: IPublicClientApplication, contas: string[], dataBase: string, pagina: number, tamanhoPagina: number, callback: (response: ResponsePagination<RelatorioPosicaoItem> | undefined) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log("Entrou:ConsultarRelatorioPosicaoAtivo");
        const appState = getState();
        console.log("AppState", appState);
        if (appState && appState.relatoriosState && appState.relatoriosState.RelatorioPosicaoAtivos && appState.relatoriosState.RelatorioPosicaoAtivos.status !== "loading") {
            dispatch({ type: 'REQUEST_CONSULTAR_REL_POSICAO_ATIVO' });
            const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
            appInsights.trackEvent({ name: 'REQUEST_CONSULTAR_REL_POSICAO_ATIVO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
            var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });
            const bearer = `Bearer ${responseToken.accessToken}`;
            DefaultConnection.header = { "Authorization": bearer };
            try {
                const response = await ConsultarRelatorioPosicaoAtivo(contas, dataBase, pagina, tamanhoPagina, DefaultConnection);
                dispatch({ type: 'RECEIVE_CONSULTAR_REL_POSICAO_ATIVO', response: response });
                callback(response);
            } catch (error) {
                dispatch({ type: 'RECEIVE_CONSULTAR_REL_POSICAO_ATIVO', response: undefined });
                callback(undefined);
            }

        }
    },
    AtualizarDicionarioAtivo: (instance: IPublicClientApplication, ativoB3: AtivoB3): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log("Entrou:AtualizarDicionarioAtivo");
        const appState = getState();
        console.log("AppState", appState);
        const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
        appInsights.trackEvent({ name: 'REQUEST_ATUALIZAR_ATIVO_B3' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
        try {
            dispatch({ type: 'REQUEST_ATUALIZAR_ATIVO_B3' });
            dispatch({ type: 'RECEIVE_ATUALIZAR_ATIVO_B3', response: ativoB3 });
        } catch (error) {
            dispatch({ type: 'RECEIVE_ATUALIZAR_ATIVO_B3', response: ativoB3 });
        }
    },
    MonitorarAtivos: (instance: IPublicClientApplication, ativos: { Token: string }[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log("Entrou:MonitorarAtivos");
        const appState = getState();
        console.log("AppState", appState);

        const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
        appInsights.trackEvent({ name: 'MonitorarAtivos' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
        var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });
        const bearer = `Bearer ${responseToken.accessToken}`;
        DefaultConnection.header = { "Authorization": bearer };
        try {
            console.log(ativos);
            const response = await MonitorarAtivos(ativos, DefaultConnection);
        } catch (error) {
            appInsights.trackException({}, { error: error, UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
        }


    },
}

export const reducer: Reducer<RelatoriosState> = (state: RelatoriosState | undefined, incomingAction: Action): RelatoriosState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CONSULTAR_REL_POSICAO_ATIVO':
            return { ...state, ...{ RelatorioPosicaoAtivos: { ...state.RelatorioPosicaoAtivos, ...{ status: 'loading' } } } }
        case 'RECEIVE_CONSULTAR_REL_POSICAO_ATIVO':
            return { ...state, ...{ RelatorioPosicaoAtivos: { relatorioResponse: action.response, status: 'idle' } } }
        case 'REQUEST_CONSULTAR_REL_PENDENTE_NORMALIZACAO':
            return { ...state, ...{ RelatorioPendenteNormalizacao: { ...state.RelatorioPendenteNormalizacao, ...{ status: 'loading' } } } }
        case 'RECEIVE_CONSULTAR_REL_PENDENTE_NORMALIZACAO':
            return { ...state, ...{ RelatorioPendenteNormalizacao: { relatorioResponse: action.response, status: 'idle' } } }
        case 'REQUEST_ATUALIZAR_ATIVO_B3':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_ATUALIZAR_ATIVO_B3':
            var ativos = new Map<string, AtivoB3>();
            state.ativosB3.forEach((b3, key) => { ativos.set(key, b3); })
            return { ...state, ...{ ativosB3: ativos.set(action.response.Token, action.response), status: 'idle' } }
        default:
            break;
    }

    return state;
}