import React, { Component, FormEvent } from 'react';
import { useMsal, MsalContext, withMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Layout from '../layout/Layout';
import { CustomNavigationClient } from '../../utils/NavigationClient';
import * as UsuarioStore from '../../store/usuario/usuarioStore';
import * as ProdutoStore from '../../store/produto/produtoStore';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { IPublicClientApplication } from "@azure/msal-browser";
import { Contas } from '../usuario/usuarioAPI';
import { Button, Form, Col, Row, DropdownButton, Dropdown, Accordion, Card, Pagination } from "react-bootstrap";
import { Ativo } from '../importarNotas/importarNotasAPI';
import GridProduto from './gridProduto';
import { Produto, ResponsePagination, TipoProduto, tiposProduto } from '../communs';



type Props = {
    navigation: CustomNavigationClient,
    window?: () => Window,
    instance: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators & ProdutoStore.ProdutoState & typeof ProdutoStore.actionCreators;

type ProdutoUIState = {
    prevState: boolean;
    isLoading: boolean;
    TiposProduto: TipoProduto[];
    selectedTiposProduto: TipoProduto[];
    ProdutoResponse: ResponsePagination<Produto> | undefined;
    tamanhoPagina: number;
    nomeAtivo: string;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
class ProdutoUI extends React.Component<Props, ProdutoUIState> {
    constructor(props: any) {
        super(props)
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        this.state = {
            prevState: false,
            isLoading: false,
            selectedTiposProduto: tiposProduto,
            TiposProduto: tiposProduto,
            ProdutoResponse: undefined,
            tamanhoPagina: 15,
            nomeAtivo: ""
        };

    }
    handleTiposChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const tipo = this.state.TiposProduto.find(a => a.codigo === event.target.value) as TipoProduto;
        if (tipo != null && tipo != undefined) {
            if (this.state.selectedTiposProduto?.includes(tipo)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedTiposProduto: this.state.selectedTiposProduto.filter(t => t.codigo !== tipo.codigo)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedTiposProduto: [...this.state.selectedTiposProduto, tipo]
                });
            }
        }
    };

    handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        try {
            this.props.listarProdutosAsync({
                pagina: 1,
                tamanhoPagina: this.state.tamanhoPagina,
                tipos: this.state.selectedTiposProduto.map(t => t.codigo)
            }, this.props.instance, (responseProdutos: ResponsePagination<Produto>) => {
                this.setState({ ProdutoResponse: responseProdutos, isLoading: false });
            });
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };

    componentDidMount(): void {
        this.setState({ isLoading: true });
        try {
            this.props.listarProdutosAsync({
                pagina: 1,
                tamanhoPagina: this.state.tamanhoPagina,
                tipos: this.state.selectedTiposProduto.map(t => t.codigo)
            }, this.props.instance, (responseProdutos: ResponsePagination<Produto>) => {
                this.setState({ ProdutoResponse: responseProdutos, isLoading: false });
            });
        } catch (error) {
            this.setState({ isLoading: false });
        }
    }
    render(): React.ReactNode {
        const { isLoading, selectedTiposProduto } = this.state;
        return <AuthenticatedTemplate>
            <Layout navigation={this.props.navigation} isLoading={this.props.status == 'loading'} permissoes={this.props.usuario?.permissoes}>
                <div className="container mt-5">
                    <h1>Produtos</h1>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Opções de filtro</Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col >
                                            <Form.Group as={Col} controlId="tipoProduto">
                                                <Form.Label>Tipo de Produto</Form.Label>
                                                <DropdownButton title="Selecione os tipos de Ativos" id="dropdown-contas" autoClose="outside">
                                                    {this.state.TiposProduto.map(tipo => (
                                                        <Dropdown.Item
                                                            key={tipo.codigo}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${tipo.codigo}`}
                                                                label={tipo.descricao}
                                                                value={tipo.codigo}
                                                                checked={selectedTiposProduto.includes(tipo)}
                                                                onChange={this.handleTiposChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Form.Group as={Col} controlId="tamanhoPagina">
                                            <Form.Label>Quantidade de Registros</Form.Label>
                                            <Form.Control as="input" type="number" value={this.state.tamanhoPagina} onChange={(event) => this.setState({ tamanhoPagina: parseFloat(event.target.value) })} />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="nomeAtivo">
                                            <Form.Label>Nome ativo</Form.Label>
                                            <Form.Control as="input" type="text" value={this.state.nomeAtivo} onChange={(event) => this.setState({ nomeAtivo: event.target.value })} />
                                        </Form.Group>
                                    </Row>
                                    <Button className='mt-4'
                                        variant="primary"
                                        type="submit"
                                        disabled={!selectedTiposProduto || isLoading}
                                    >
                                        {isLoading ? "Pesquisando..." : "Pesquisar"}
                                    </Button>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <GridProduto
                        itensResponse={this.props.produtoResponse as ResponsePagination<Produto>}
                        addHandle={(produto, callback) => {
                            this.props.adicionarProdutoAsync(produto, this.props.instance, (response) => {
                                alert('Produto adicionado com sucesso.');
                                callback(response);
                            })
                        }} updateHandle={(produto, callback) => {
                            this.props.atualizarProdutoAsync(produto, this.props.instance, (response) => {
                                alert('Produto atualizado com sucesso.');
                                callback(response);
                            })
                        }}
                        onExcluirAtivo={(ativo) => { }} permissoes={this.props.usuario?.permissoes} />
                    <Pagination>
                        <Pagination.Prev
                            disabled={this.state.ProdutoResponse?.pagina === 1}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.listarProdutosAsync({
                                    pagina: this.state.ProdutoResponse?.pagina as number - 1,
                                    tamanhoPagina: this.state.tamanhoPagina,
                                    tipos: this.state.selectedTiposProduto.map(t => t.codigo)
                                }, this.props.instance, (responseAtivos: ResponsePagination<Produto>) => {
                                    this.setState({ ProdutoResponse: responseAtivos, isLoading: false });
                                });
                            }}
                        />
                        
                        {Array.from( {length: this.state.ProdutoResponse?.totalPagina as number }, (_, index)=>index).map((v, page) => {
                              return <Pagination.Item
                                key={page + 1}
                                active={page + 1 === this.state.ProdutoResponse?.pagina}
                                onClick={() => {
                                    this.setState({ isLoading: true });
                                    this.props.listarProdutosAsync({
                                        pagina: page + 1,
                                        tamanhoPagina: this.state.tamanhoPagina,
                                        tipos: this.state.selectedTiposProduto.map(t => t.codigo)
                                    }, this.props.instance, (responseAtivos: ResponsePagination<Produto>) => {
                                        this.setState({ ProdutoResponse: responseAtivos, isLoading: false });
                                    });
                                }}
                            >
                                {page + 1}
                            </Pagination.Item>
                        })}

                        <Pagination.Next
                            disabled={this.state.ProdutoResponse?.pagina === this.state.ProdutoResponse?.totalPagina}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.listarProdutosAsync({
                                    pagina: this.state.ProdutoResponse?.pagina as number + 1,
                                    tamanhoPagina: this.state.tamanhoPagina,
                                    tipos: this.state.selectedTiposProduto.map(t => t.codigo)
                                }, this.props.instance, (responseAtivos: ResponsePagination<Produto>) => {
                                    this.setState({ ProdutoResponse: responseAtivos, isLoading: false });
                                });
                            }}
                        />
                    </Pagination>
                </div>
            </Layout>
        </AuthenticatedTemplate>
    }
}


export default connect(
    (state: ApplicationState) => ({
        ...state.produtoState, ...state.usuarioState
    }),
    { ...UsuarioStore.actionCreators, ...ProdutoStore.actionCreators })(ProdutoUI);