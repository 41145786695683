import React, { FormEvent } from 'react';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Container, Row, Col, Image, Card, Placeholder, Tooltip, OverlayTrigger, Popover, DropdownButton, Dropdown, Table, Badge, Tabs, Tab } from 'react-bootstrap';
import { Contas, KPIContratoContaView } from '../usuario/usuarioAPI';
import { BsPiggyBankFill, BsFillFilterSquareFill, BsQuestionCircle, BsArrowClockwise, BsFillPlusSquareFill, BsDashSquareFill } from 'react-icons/bs';
import { IconContext } from "react-icons";
import { NumericFormat } from 'react-number-format';
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import { ResponsePagination, formatarDataPorExtenso, obterDatasPrimeiroDiaMes } from '../communs';
import { Contrato } from '../contratos/contratoAPI';


interface expandItem {
    id: string;
    expended: boolean;
}
type IndicadorContratoContaState = {
    isLoading: boolean,
    isTooltipVisible: boolean;
    isPoperOverVisible: boolean;
    selectedAccount: Contas[];
    tourstart: boolean;
    indextourstart: number;
    visibleDetalhe: boolean;
    isLoadingCompetencia: boolean;
    selectContasTodos: boolean;
    expendItens: expandItem[];
}



type indicadorContratoContaProps = {
    Contas: Contas[],
    initialLoading: boolean,
    ContratosResponse: ResponsePagination<Contrato>,
    FechamentoMesResponse: KPIContratoContaView[],
    OnFilterHandle: (contas: Contas[]) => void;
    OnCompetenciaHandle: (contas: Contas[], competencia: string) => void;
}
type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
export default class IndicadorContratoConta extends React.PureComponent<indicadorContratoContaProps, IndicadorContratoContaState>{
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: props.initialLoading,
            selectedAccount: props.Contas,
            isTooltipVisible: true,
            isPoperOverVisible: false,
            tourstart: false,
            indextourstart: 0,
            visibleDetalhe: false,
            isLoadingCompetencia: props.initialLoading,
            selectContasTodos : true,
            expendItens: []
        }
        this.handleSetIsloading = this.handleSetIsloading.bind(this);
        this.handleSetIsloadingCompetencia = this.handleSetIsloadingCompetencia.bind(this);
        this.handleSetSelectedAccount = this.handleSetSelectedAccount.bind(this);
    }
    handleSetIsloading = (v: boolean) => {
        this.setState({ isLoading: v });
    }
    handleSetIsloadingCompetencia = (v: boolean) => {
        this.setState({ isLoadingCompetencia: v });
    }
    handleSetSelectedAccount = (contas: Contas[]) => {
        this.setState({ selectedAccount: contas });
    }

    handleSubmitFiltroConta = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true, isPoperOverVisible: false });
        this.props.OnFilterHandle(this.state.selectedAccount);
    }

    handleOnSelectCompetencia = (eventKey: string | null) => {
        this.setState({ isLoadingCompetencia: true });
        this.props.OnCompetenciaHandle(this.state.selectedAccount, eventKey as string);
    }
    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.props.Contas.find(a => a.id === event.target.value) as Contas;
        if (account != null && account != undefined) {
            if (this.state.selectedAccount?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedAccount: this.state.selectedAccount.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedAccount: [...this.state.selectedAccount, account]
                });
            }
        }
    };
    render(): React.ReactNode {
        const steps = [
            {
                element: '.kpi-dadosconta',
                intro: 'Esse card contém informações resumidas das contas correntes que você possui.'
            },
            {
                element: '.kpi-dadosconta-aportes',
                intro: 'Em Aportes, você verá o somatório de capital que você dedicou para investimento',
            },
            {
                element: '.kpi-dadosconta-lp',
                intro: 'Em Lucros/Prejuízo, você verá o acumulado de lucro ou prejuízo de suas contas.',
            }, {
                element: '.kpi-dadosconta-bens',
                intro: 'Em bens, você verá o quanto do seu capital está investido em bens ou ações, já não mais disponível em sua conta corrente como dinheiro, mas sim em bens.',
            },
            {
                element: '.kpi-dadosconta-saldo',
                intro: 'Aqui você verá o saldo disponível em dinheiro em sua conta corrente.',
            },
            {
                element: '.kpi-dadosconta-filtro',
                intro: 'Aqui você poderá filtrar quais contas você deseja visualizar os dados.',
            }
            ,
            {
                element: '.kpi-dadosconta-info',
                intro: 'Sempre que tiver alguma dúvida sobre informações do card, você pode clicar aqui e será explicado cada informação dentro do card.',
            },
            {
                element: '.kpi-dadosconta-refresh',
                intro: 'Caso precise carregar as informações novamente, você poderá clicar aqui.',
            }
        ];
        const { selectedAccount, isLoading } = this.state;
        const popoverfiltrodadosconta = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">Filtros</Popover.Header>
                <Popover.Body>
                    <Form onSubmit={this.handleSubmitFiltroConta}>
                        <Row>
                            <Col >
                                <Form.Group controlId="selectConta">
                                    <Form.Label>Selecione a conta:</Form.Label>
                                    <DropdownButton title="Selecione as Contas" id="dropdown-contas" autoClose="outside" >
                                    <Dropdown.Item
                                                        key="select-conta-filtro"
                                                        as="label"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="select-conta-filtro"
                                                            label='Selecione Todos'
                                                            value='select-modelo-filtro'
                                                            checked={this.state.selectContasTodos}
                                                            onChange={() => { this.setState({ selectedAccount: !this.state.selectContasTodos ? this.props.Contas : [], selectContasTodos: !this.state.selectContasTodos }) }}
                                                        />
                                                    </Dropdown.Item>
                                        {this.props.Contas.map(conta => (
                                            <Dropdown.Item
                                                key={conta.id}
                                                as="label"
                                                className="d-flex align-items-center"
                                            >
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`conta-${conta.id}`}
                                                    label={conta.nome}
                                                    value={conta.id}
                                                    checked={selectedAccount.includes(conta)}
                                                    onChange={this.handleAccountChange}
                                                />
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button className='mt-4'
                            variant="primary"
                            type="submit"
                            disabled={!selectedAccount || isLoading}
                        >
                            {isLoading ? "Pesquisando..." : "Pesquisar"}
                        </Button>
                    </Form>
                </Popover.Body>
            </Popover>
        );



        const dataMinima: Date | null = this.props.ContratosResponse?.result.map(c => new Date(c.dataInicio)).reduce((minDate: Date | null, currentDate: Date) => {
            if (!minDate || currentDate < minDate) {
                return currentDate;
            }
            return minDate;
        }, null);
        return (
            <> <Steps
                enabled={this.state.tourstart}
                steps={steps}
                initialStep={this.state.indextourstart}
                onExit={() => { this.setState({ indextourstart: 0, tourstart: false }) }}
                options={{ nextLabel: "Próximo", prevLabel: "Anterior", doneLabel: "Fim" }}
            />
                <Card className="mb-2 shadow kpi-dadosconta">
                    <Card.Header style={{ backgroundColor: 'black', textAlign: 'center' }}>
                        <div style={{ color: 'white' }}><b>Dados de Contrato</b>

                            <OverlayTrigger placement='auto'
                                overlay={
                                    <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-filtro`}>
                                        Filtros
                                    </Tooltip>
                                } >
                                <div className='kpi-dadosconta-filtro' style={{ float: 'right', cursor: 'pointer' }} >
                                    <OverlayTrigger trigger="click" placement="auto" show={this.state.isPoperOverVisible && !this.state.isTooltipVisible} onToggle={(event) => { this.setState({ isTooltipVisible: !event, isPoperOverVisible: event }) }} overlay={popoverfiltrodadosconta}>
                                        <div>
                                            <BsFillFilterSquareFill />
                                        </div>
                                    </OverlayTrigger>

                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger placement='auto'
                                overlay={
                                    <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-info`}>
                                        Informações
                                    </Tooltip>
                                } >
                                <div className='kpi-dadosconta-info me-3' onClick={() => { this.setState({ tourstart: true, indextourstart: 0 }) }} style={{ float: 'right', cursor: 'pointer' }} >
                                    <div>
                                        <BsQuestionCircle />
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger placement='auto'
                                overlay={
                                    <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-refresh`}>
                                        Atualizar
                                    </Tooltip>
                                } >
                                <div className='kpi-dadosconta-refresh me-3' onClick={() => {
                                    this.setState({ isLoading: true, isPoperOverVisible: false });
                                    this.props.OnFilterHandle(this.state.selectedAccount);
                                }} style={{ float: 'right', cursor: 'pointer' }} >
                                    <div>
                                        <BsArrowClockwise />
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </div>
                    </Card.Header>
                    <Card.Body style={{ whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        {this.state.isLoading ? <Placeholder animation="glow" >
                            <Placeholder xs={6} style={{ height: '100%', width: '100%' }} />
                        </Placeholder> : <Tabs onSelect={this.handleOnSelectCompetencia}>
                            {
                                obterDatasPrimeiroDiaMes(dataMinima as Date, new Date()).map(data => {
                                    return (
                                        <Tab key={formatarDataPorExtenso(data)} eventKey={data.toISOString().split('T')[0]} title={formatarDataPorExtenso(data)} >
                                            {this.state.isLoadingCompetencia ? <Placeholder animation="glow" >
                                                <Placeholder xs={6} style={{ height: '100%', width: '100%' }} />
                                            </Placeholder> :
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Contratante</th>
                                                            <th>Valor Investido</th>
                                                            <th>Lucro/Prejuízo</th>
                                                            <th>Valor Cliente</th>
                                                            <th>I.R</th>
                                                            <th>Valor BX</th>
                                                            <th>Saldo (Sem Retirada)</th>
                                                            <th>Situação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.props.FechamentoMesResponse.map(fechamento => {
                                                            
                                                            return <React.Fragment key={fechamento.id}>
                                                                <tr>
                                                                    <td onClick={() => {
                                                                        var itemFechamento = this.state.expendItens.findIndex(i=>i.id == fechamento.id);
                                                                        const {expendItens} = this.state;
                                                                        if(itemFechamento == -1){
                                                                            var item = { id : fechamento.id, expended: true }
                                                                            expendItens.push(item);    
                                                                        }else{
                                                                            expendItens[itemFechamento].expended = !expendItens[itemFechamento].expended; 
                                                                        }
                                                                        console.log(expendItens);
                                                                        this.setState({expendItens : expendItens },()=>{
                                                                            this.forceUpdate();
                                                                        })
                                                                    }}>{this.state.expendItens.find(i=>i.id == fechamento.id)?.expended ? <BsDashSquareFill style={{ cursor: 'pointer' }} /> : <BsFillPlusSquareFill style={{ cursor: 'pointer' }} />}</td>
                                                                    <td>{fechamento.contrato.pessoa.nomeFantasia}</td>
                                                                    <td>
                                                                        <NumericFormat value={fechamento.valorInvestido} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' />
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <NumericFormat value={fechamento.valorLucroPrejuizo} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' />
                                                                            <br />
                                                                            <span style={{ fontSize: '0.8rem', color: 'gray' }}>Rentabilidade: <span>{(fechamento.percentialLucroPrejuizo * 100.00).toLocaleString()} %</span></span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <NumericFormat value={fechamento.valorCliente} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' />
                                                                            <br />
                                                                            <span style={{ fontSize: '0.8rem', color: 'gray' }}>Rentabilidade: <span>{(fechamento.percentialCliente * 100.00).toLocaleString()} %</span></span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <NumericFormat value={fechamento.valorIR} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <NumericFormat value={fechamento.valorBX} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' />
                                                                            <br />
                                                                            <span style={{ fontSize: '0.8rem', color: 'gray' }}>Rentabilidade: <span>{(fechamento.percentialBX * 100.00).toLocaleString()} %</span></span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <NumericFormat value={fechamento.valorInvestido + fechamento.valorLucroPrejuizo} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <OverlayTrigger placement='auto'
                                                                            overlay={
                                                                                <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-situacao-info`}>
                                                                                    {fechamento.descricaoStatus}
                                                                                </Tooltip>
                                                                            } >
                                                                            <Badge pill bg={fechamento.codigoStatus == "A" ? "info" : fechamento.codigoStatus == "F" ? "success" : "danger"}>
                                                                                {fechamento.codigoStatus}
                                                                            </Badge>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger placement='auto'
                                                                            overlay={
                                                                                <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-meta-info`}>
                                                                                    {fechamento.bateuMeta ? "Meta Batida" : "Meta Falhou"}
                                                                                </Tooltip>
                                                                            } >
                                                                            <Badge pill bg={fechamento.bateuMeta ? "success" : "danger"}>
                                                                                {fechamento.bateuMeta ? "MB" : "MF"}
                                                                            </Badge>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                                <tr hidden={!this.state.expendItens.find(i=>i.id == fechamento.id)?.expended}>
                                                                    <td colSpan={9}>
                                                                        <div>
                                                                            <div style={{ display: 'inline' }}><label>Competência:</label> <span>{formatarDataPorExtenso(data)}</span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Valor Investido:</label> <span><NumericFormat value={fechamento.valorInvestido} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Lucro Líquido s/ IR:</label> <span><NumericFormat value={fechamento.valorLucroPrejuizo} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Valor Elegível I.R:</label> <span><NumericFormat value={fechamento.valorElegivelIR} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>I.R:</label> <span><NumericFormat value={fechamento.valorIR} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Lucro Líquido:</label> <span><NumericFormat value={fechamento.valorLucroPrejuizo - fechamento.valorIR } decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Contrato:</label> <span>{fechamento.contrato.modelo.nome}</span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Meta atingida:</label> <span>{fechamento.bateuMeta ? "Sim": "Não"}</span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Meta:</label> <span><NumericFormat value={fechamento.valorInvestido * fechamento.contrato.condicoes.meta} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Excedente da Meta:</label> <span><NumericFormat value={fechamento.valorLucroPrejuizo - (fechamento.valorInvestido * fechamento.contrato.condicoes.meta)} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Lucro Cliente:</label> <span><NumericFormat value={fechamento.valorCliente} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Lucro Bx:</label> <span><NumericFormat value={fechamento.valorBX} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                            <div style={{ display: 'inline' }}><label>Saldo Sem Retirada:</label> <span><NumericFormat value={fechamento.valorInvestido + fechamento.valorLucroPrejuizo} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></span></div><br />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        })}

                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th>Total</th>
                                                            <th></th>
                                                            <th ><NumericFormat value={this.props.FechamentoMesResponse.map(f=>f.valorInvestido).reduce((acu, cur)=>{return acu + cur},0)} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></th>
                                                            <th ><NumericFormat value={this.props.FechamentoMesResponse.map(f=>f.valorLucroPrejuizo).reduce((acu, cur)=>{return acu + cur},0)} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></th>
                                                            <th ><NumericFormat value={this.props.FechamentoMesResponse.map(f=>f.valorCliente).reduce((acu, cur)=>{return acu + cur},0)} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></th>
                                                            <th ><NumericFormat value={this.props.FechamentoMesResponse.map(f=>f.valorIR).reduce((acu, cur)=>{return acu + cur},0)} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></th>
                                                            <th ><NumericFormat value={this.props.FechamentoMesResponse.map(f=>f.valorBX).reduce((acu, cur)=>{return acu + cur},0)} decimalScale={2} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>}
                                        </Tab>);
                                })
                            }

                        </Tabs>}


                    </Card.Body>
                </Card></>
        )
    }
}