import React, { Component, FormEvent } from 'react';
import { useMsal, MsalContext, withMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Layout from '../layout/Layout';
import { CustomNavigationClient } from '../../utils/NavigationClient';
import * as UsuarioStore from '../../store/usuario/usuarioStore';
import * as ContratoStore from '../../store/contratos/contratoStore';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { IPublicClientApplication } from "@azure/msal-browser";
import { Contas } from '../usuario/usuarioAPI';
import { Button, Form, Col, Row, DropdownButton, Dropdown, Accordion, Card, Pagination } from "react-bootstrap";
import { Ativo } from '../importarNotas/importarNotasAPI';
import GridContrato from './gridContrato';
import { Contrato, ModeloContrato } from './contratoAPI';
import { ResponsePagination } from '../communs';


type Props = {
    navigation: CustomNavigationClient,
    window?: () => Window,
    instance: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators & ContratoStore.ContratoState & typeof ContratoStore.actionCreators;

type ContratoUIState = {
    prevState: boolean;
    selectedAccount: Contas[];
    Contas: Contas[];
    isLoading: boolean;
    dataInicial: string;
    dataFinal: string;
    modelos: ModeloContrato[];
    selectedModelos: ModeloContrato[];
    ContratoResponse: ResponsePagination<Contrato> | undefined;
    tamanhoPagina: number;
    selectModeloTodos: boolean;
    selectContasTodos: boolean;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

class ContratoUI extends React.PureComponent<Props, ContratoUIState>{
    constructor(props: any) {
        super(props);
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        datainicial.setFullYear(datainicial.getFullYear() - 2);
        this.state = {
            selectedAccount: [],
            prevState: false,
            Contas: [],
            isLoading: false,
            dataInicial: datainicial.toISOString().split('T')[0],
            dataFinal: new Date().toISOString().split('T')[0],
            selectedModelos: [],
            modelos: [],
            ContratoResponse: undefined,
            tamanhoPagina: 15,
            selectModeloTodos: true,
            selectContasTodos: true
        };
    }

    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.state.Contas.find(a => a.id === event.target.value) as Contas;
        if (account != null && account != undefined) {
            if (this.state.selectedAccount?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedAccount: this.state.selectedAccount.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedAccount: [...this.state.selectedAccount, account]
                });
            }
        }
    };

    handleModelosChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const modelo = this.state.modelos.find(a => a.id === event.target.value) as ModeloContrato;
        if (modelo != null && modelo != undefined) {
            if (this.state.selectedModelos?.includes(modelo)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedModelos: this.state.selectedModelos.filter(t => t.id !== modelo.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedModelos: [...this.state.selectedModelos, modelo]
                });
            }
        }
    };

    handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        try {
            this.props.listarContratosAsync({
                contas: this.state.selectedAccount.map(c => c.id),
                dataInicial: this.state.dataInicial,
                dataFinal: this.state.dataFinal,
                pagina: 1,
                tamanhoPagina: this.state.tamanhoPagina,
                modelos: this.state.selectedModelos.map(t => t.id)
            }, this.props.instance, (responseContratos: ResponsePagination<Contrato>) => {
                this.setState({ ContratoResponse: responseContratos, isLoading: false });
            });
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };
    componentDidMount(): void {
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        datainicial.setFullYear(datainicial.getFullYear() - 2);
        if (this.props.contas == undefined || this.props.contas.length == 0) {
            this.props.getContasAsync(this.props.instance, (response) => {
                this.setState({ Contas: response.contas, selectedAccount: response.contas });
                if (this.props.modelos == null || this.props.modelos == undefined || this.props.modelos.length == 0) {
                    this.props.listarModelosAsync(this.props.instance, (modelos) => {
                        console.log('Modelos', modelos);
                        this.setState({ modelos: modelos, selectedModelos: modelos });
                        this.props.listarContratosAsync({
                            contas: response.contas.map(c => c.id),
                            dataInicial: this.state.dataInicial,
                            dataFinal: this.state.dataFinal,
                            pagina: 1,
                            tamanhoPagina: this.state.tamanhoPagina,
                            modelos: modelos.map(t => t.id)
                        }, this.props.instance, (responseContratos: ResponsePagination<Contrato>) => {
                            this.setState({ ContratoResponse: responseContratos, isLoading: false });
                        });
                    })
                } else {
                    this.setState({ modelos: this.props.modelos, isLoading: false, selectedModelos: this.props.modelos });
                    this.props.listarContratosAsync({
                        contas: response.contas.map(c => c.id),
                        dataInicial: this.state.dataInicial,
                        dataFinal: this.state.dataFinal,
                        pagina: 1,
                        tamanhoPagina: this.state.tamanhoPagina,
                        modelos: this.props.modelos.map(t => t.id)
                    }, this.props.instance, (responseContratos: ResponsePagination<Contrato>) => {
                        this.setState({ ContratoResponse: responseContratos, isLoading: false });
                    });
                }

            });
        } else {
            this.setState({ Contas: this.props.contas as Contas[], selectedAccount: this.props.contas as Contas[] });
            console.log('Modelos', this.props.modelos);
            if (this.props.modelos == null || this.props.modelos == undefined || this.props.modelos.length == 0) {
                this.props.listarModelosAsync(this.props.instance, (modelos) => {
                    console.log('Modelos', modelos);
                    this.setState({ modelos: modelos, isLoading: false, selectedModelos: modelos });
                    this.props.listarContratosAsync({
                        contas: this.props.contas?.map(c => c.id) as string[],
                        dataInicial: this.state.dataInicial,
                        dataFinal: this.state.dataFinal,
                        pagina: 1,
                        tamanhoPagina: this.state.tamanhoPagina,
                        modelos: modelos.map(t => t.id)
                    }, this.props.instance, (responseContratos: ResponsePagination<Contrato>) => {
                        this.setState({ ContratoResponse: responseContratos, isLoading: false });
                    });
                })
            } else {
                this.setState({ modelos: this.props.modelos, isLoading: false, selectedModelos: this.props.modelos });
                this.props.listarModelosAsync(this.props.instance, (modelos) => {
                    console.log('Modelos', modelos);
                    this.setState({ modelos: modelos, selectedModelos: modelos });
                    this.props.listarContratosAsync({
                        contas: this.props.contas?.map(c => c.id) as string[],
                        dataInicial: this.state.dataInicial,
                        dataFinal: this.state.dataFinal,
                        pagina: 1,
                        tamanhoPagina: this.state.tamanhoPagina,
                        modelos: this.props.modelos.map(t => t.id)
                    }, this.props.instance, (responseContratos: ResponsePagination<Contrato>) => {
                        this.setState({ ContratoResponse: responseContratos, isLoading: false });
                    });
                })
            }


        }

    }

    render(): React.ReactNode {
        const { selectedAccount, isLoading, selectedModelos } = this.state;
        return <AuthenticatedTemplate>
            <Layout navigation={this.props.navigation} isLoading={this.props.status == 'loading'} permissoes={this.props.usuario?.permissoes}>
                <div className="container mt-5">
                    <h1>Contratos</h1>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Opções de filtro</Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col >
                                            <Form.Group controlId="selectConta">
                                                <Form.Label>Contas:</Form.Label>
                                                <DropdownButton title="Selecione as Contas" id="dropdown-contas" autoClose="outside">
                                                    <Dropdown.Item
                                                        key="select-modelo-filtro"
                                                        as="label"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="select-modelo-filtro"
                                                            label='Selecione Todos'
                                                            value='select-modelo-filtro'
                                                            checked={this.state.selectContasTodos}
                                                            onChange={() => { this.setState({ selectedAccount: !this.state.selectContasTodos ? this.state.Contas : [], selectContasTodos: !this.state.selectContasTodos }) }}
                                                        />
                                                    </Dropdown.Item>
                                                    {this.state.Contas.map(conta => (
                                                        <Dropdown.Item
                                                            key={conta.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${conta.id}`}
                                                                label={conta.nome}
                                                                value={conta.id}
                                                                checked={selectedAccount.includes(conta)}
                                                                onChange={this.handleAccountChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>
                                        <Col >
                                            <Form.Group as={Col} controlId="modelo">
                                                <Form.Label>Modelos</Form.Label>
                                                <DropdownButton title="Selecione os tipos de Ativos" id="dropdown-contas" autoClose="outside">
                                                    <Dropdown.Item
                                                        key="select-modelo-filtro"
                                                        as="label"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="select-modelo-filtro"
                                                            label='Selecione Todos'
                                                            value='select-modelo-filtro'
                                                            checked={this.state.selectModeloTodos}
                                                            onChange={() => { this.setState({ selectedModelos: !this.state.selectModeloTodos ? this.state.modelos : [], selectModeloTodos: !this.state.selectModeloTodos }) }}
                                                        />
                                                    </Dropdown.Item>
                                                    {this.state.modelos.map(modelo => (
                                                        <Dropdown.Item
                                                            key={modelo.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${modelo.id}`}
                                                                label={modelo.nome}
                                                                value={modelo.id}
                                                                checked={selectedModelos.includes(modelo)}
                                                                onChange={this.handleModelosChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Form.Group as={Col} controlId="dataInicio">
                                            <Form.Label>Data Inicial</Form.Label>
                                            <Form.Control type="date" value={this.state.dataInicial} onChange={(event) => this.setState({ dataInicial: new Date(event.target.value).toISOString().split('T')[0] })} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="dataFinal">
                                            <Form.Label>Data Final</Form.Label>
                                            <Form.Control type="date" value={this.state.dataFinal} onChange={(event) => this.setState({ dataFinal: new Date(event.target.value).toISOString().split('T')[0] })} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="tamanhoPagina">
                                            <Form.Label>Quantidade de Registros</Form.Label>
                                            <Form.Control as="input" type="number" value={this.state.tamanhoPagina} onChange={(event) => this.setState({ tamanhoPagina: parseFloat(event.target.value) })} />
                                        </Form.Group>
                                    </Row>
                                    <Button className='mt-4'
                                        variant="primary"
                                        type="submit"
                                        disabled={!selectedAccount || isLoading}
                                    >
                                        {isLoading ? "Pesquisando..." : "Pesquisar"}
                                    </Button>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <GridContrato
                        contas={this.state.Contas}
                        itensResponse={this.props.contratoResponse as ResponsePagination<Contrato>}
                        addHandle={(contrato, callback) => {
                            this.props.adicionarContratoAsync(contrato, this.props.instance, (response) => {
                                alert('Contrato adicionado com sucesso.');
                                callback(response);
                            })
                        }} updateHandle={(contrato, callback) => {
                            this.props.atualizarContratoAsync(contrato, this.props.instance, (response) => {
                                alert('Contrato atualizado com sucesso.');
                                callback(response);
                            })
                        }}
                        onExcluirAtivo={(ativo) => { }} permissoes={this.props.usuario?.permissoes} modelos={this.state.modelos}
                        onProcessarContrato={(contrato,callback)=>{
                            this.props.ProcessarKPIContratoConta(this.props.instance, [contrato.contaId],()=>{
                                callback();
                            })
                        }}
                        onProcessarRentabilidade={(contrato, callback)=>{
                            this.props.ProcessarKPIRentabilidadeConta(this.props.instance, [contrato.contaId],()=>{
                                callback();
                            })
                        }}
                        />
                    <Pagination>
                        <Pagination.Prev
                            disabled={this.state.ContratoResponse?.pagina === 1}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.listarContratosAsync({
                                    contas: this.state.selectedAccount.map(c => c.id),
                                    dataInicial: this.state.dataInicial,
                                    dataFinal: this.state.dataFinal,
                                    pagina: this.state.ContratoResponse?.pagina as number - 1,
                                    tamanhoPagina: this.state.tamanhoPagina,
                                    modelos: selectedModelos.map(t => t.id)
                                }, this.props.instance, (responseAtivos: ResponsePagination<Contrato>) => {
                                    this.setState({ ContratoResponse: responseAtivos, isLoading: false });
                                });
                            }}
                        />

                        {[(new Array(this.state.ContratoResponse?.totalPagina as number)).keys()].map((v, page) => (
                            <Pagination.Item
                                key={page + 1}
                                active={page + 1 === this.state.ContratoResponse?.pagina}
                                onClick={() => {
                                    this.setState({ isLoading: true });
                                    this.props.listarContratosAsync({
                                        contas: this.state.selectedAccount.map(c => c.id),
                                        dataInicial: this.state.dataInicial,
                                        dataFinal: this.state.dataFinal,
                                        pagina: page + 1,
                                        tamanhoPagina: this.state.tamanhoPagina,
                                        modelos: selectedModelos.map(t => t.id)
                                    }, this.props.instance, (responseAtivos: ResponsePagination<Contrato>) => {
                                        this.setState({ ContratoResponse: responseAtivos, isLoading: false });
                                    });
                                }}
                            >
                                {page + 1}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next
                            disabled={this.state.ContratoResponse?.pagina === this.state.ContratoResponse?.totalPagina}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.listarContratosAsync({
                                    contas: this.state.selectedAccount.map(c => c.id),
                                    dataInicial: this.state.dataInicial,
                                    dataFinal: this.state.dataFinal,
                                    pagina: this.state.ContratoResponse?.pagina as number + 1,
                                    tamanhoPagina: this.state.tamanhoPagina,
                                    modelos: selectedModelos.map(t => t.id)
                                }, this.props.instance, (responseAtivos: ResponsePagination<Contrato>) => {
                                    this.setState({ ContratoResponse: responseAtivos, isLoading: false });
                                });
                            }}
                        />
                    </Pagination>
                </div>
            </Layout>
        </AuthenticatedTemplate>
    }
}



export default connect(
    (state: ApplicationState) => ({
         ...state.usuarioState, ...state.contratoState
    }),
    { ...UsuarioStore.actionCreators, ...ContratoStore.actionCreators })(ContratoUI);