export interface Response {
  codigo: string;
  mensagem: string;
  sucesso: boolean;
  dataResponse: string;
}
export interface Produto{
  id: string;
  codigo: string;
  nome: string;
  descricao: string;
  tipo: string;
  vencimento: string;
  codigoProdutoBase: string;
  strike: number;
  ativo: boolean;
  codigosAlternativos: string[];
  dadosAdicionais: Object;
}

export interface TipoProduto {
  codigo: string;
  descricao: string;
}

export const tiposProduto: TipoProduto[] = [{codigo: 'ACAO', descricao: 'Ação'}, {codigo:'OPCAO', descricao: 'Opção' }]; 
export interface ResponsePagination<T> extends Response {
  result: T[];
  pagina: number;
  totalRegistros: number;
  tamanhoPagina: number;
  registros: number;
  totalPagina: number;
}

export interface RequestPaginacao {
  pagina: number;
  tamanhoPagina: number;
}

export function obterDatasPrimeiroDiaMes(dataInicial: Date, dataFinal: Date): Date[] {
  if (!dataInicial || !dataFinal) return [];
  const datas: Date[] = [];
  const dataAtual = new Date(dataInicial.getFullYear(), dataInicial.getMonth(), 1);

  while (dataAtual <= dataFinal) {
    datas.push(new Date(dataAtual));
    dataAtual.setMonth(dataAtual.getMonth() + 1);
  }

  return datas.reverse();
}

export function formatarDataPorExtenso(data: Date): string {
  const mesesPorExtenso = [
    'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
    'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
  ];

  const mesPorExtenso = mesesPorExtenso[data.getMonth()];
  const ano = data.getFullYear();

  return `${mesPorExtenso}/${ano}`;
}

export function dataMinima(datas: Date[]) {
  const menorData : Date = datas.reduce((minDate: Date, currentDate: Date) => {
    if (!minDate || currentDate < minDate) {
      return currentDate;
    }
    return minDate;
  });
  return menorData;
};

export function dataMaxima(datas: Date[]) {
  const maiorData : Date = datas.reduce((minDate: Date, currentDate: Date) => {
    if (!minDate || currentDate >= minDate) {
      return currentDate;
    }
    return minDate;
  });
  return maiorData;
};