import { RequestPaginacao, ResponsePagination } from "../communs";
import ConnectionAPI from "../connection/connectionAPI";
import { Ativo } from "../importarNotas/importarNotasAPI";

export interface TipoAtivo{
    id:string;
    nome:string;
}

export const tiposAtivos: TipoAtivo[] = [{ id: 'OPÇÂO', nome: 'OPÇÂO' }, { id: 'AÇÂO', nome: 'AÇÂO' }, { id: 'FII', nome: 'FII' }, { id: 'PRODUTO', nome: 'PRODUTO' }];

export interface ListarAtivosRequest extends RequestPaginacao {
    contas: string[];
    tipos: string[];
    dataInicial: string;
    dataFinal: string;
    nomeAtivo: string;
}

export async function getListarAtivosConta(request: ListarAtivosRequest, connection: ConnectionAPI) {
    return await connection.Get<ResponsePagination<Ativo>>(ConnectionAPI.Endpoints.ListarAtivos(request));
}
export async function normalizarAtivoProduto(connection: ConnectionAPI, contas: string[]) {
    return await connection.Post<void>(ConnectionAPI.Endpoints.NormalizarAtivosPendentesProduto, JSON.stringify( contas));
}
export async function postAdicionarAtivo(request: Ativo, connection: ConnectionAPI) {
    return await connection.Post<Ativo>(ConnectionAPI.Endpoints.AdicionarAtivo, JSON.stringify( request), {'Content-Type':'application/json' });
}

export async function postAtualizarAtivo(request: Ativo, connection: ConnectionAPI) {
    return await connection.Put<Ativo>(ConnectionAPI.Endpoints.AtualizarAtivo, JSON.stringify( request), {'Content-Type':'application/json' });
}