import * as UsuarioStore from "./usuario/usuarioStore";
import * as LancamentoContaStore from "./lancamentoConta/lancamentoContaStore";
import * as ImportacaoNotaStore from "./importacaoNota/importacaoNotaStore";
import * as AtivoStore from "./ativo/ativoStore";
import * as ContratoStore from "./contratos/contratoStore";
import * as RelatoriosStore from "./relatorios/relatoriosStore";
import * as ProdutoStore from "./produto/produtoStore";

export interface ApplicationState {
    usuarioState: UsuarioStore.UsuarioState
    lancamentoContaState: LancamentoContaStore.LancamentoContaState,
    importacaoNotaState:ImportacaoNotaStore.ImportacaoNotaState,
    ativoState: AtivoStore.AtivoState,
    contratoState: ContratoStore.ContratoState,
    relatoriosState: RelatoriosStore.RelatoriosState,
    produtoState: ProdutoStore.ProdutoState
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    usuarioState: UsuarioStore.reducer,
    lancamentoContaState: LancamentoContaStore.reducer,
    importacaoNotaState: ImportacaoNotaStore.reducer,
    ativoState: AtivoStore.reducer,
    contratoState: ContratoStore.reducer,
    relatoriosState: RelatoriosStore.reducer,
    produtoState: ProdutoStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}