import React from 'react';
import * as UsuarioStore from '../../store/usuario/usuarioStore';
import { CustomNavigationClient } from '../../utils/NavigationClient';
import { IPublicClientApplication, ApiId } from '@azure/msal-browser';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { MsalContext, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Container, Modal } from 'react-bootstrap';
import { loginRequest } from '../../authConfig';
import Spinner from 'react-bootstrap/Spinner'

type LoadingState = {
    isLoading: boolean
}
type LoadingProps = {
    navigation: CustomNavigationClient,
    window?: () => Window;
    instance: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators;

class LoadingHome extends React.PureComponent<LoadingProps, LoadingState>  {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true
        }
    }
    static contextType = MsalContext;
    context!: React.ContextType<typeof MsalContext>
    render(): React.ReactNode {
        return <>
            <UnauthenticatedTemplate >
                <Container fluid>
                    <Modal centered onShow={async () => {
                        const msalInstance = this.context.instance;
                        await msalInstance.loginPopup(loginRequest).then(
                            response => {
                                this.props.getUsuarioSSOAsync(
                                    { authority: response.authority, uniqueId: response.uniqueId, username: response.account?.username as string },
                                    msalInstance, (usuarioResponse) => {
                                        return response;
                                    });
                            }
                        );
                    }}>
                        <Modal.Body>
                            <h4 style={{ fontFamily: 'TheSeasons' }} ><span style={{ fontFamily: 'Blanka' }}>BX</span><span style={{ color: '#004AAD', fontWeight: 'bold' }}>|</span>Invest</h4>
                            <div>
                                Carregando informações de usuário...
                            </div>
                        </Modal.Body>
                    </Modal>
                </Container>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Container fluid>
                    <Modal show={true} centered onShow={async () => {
                        try {
                            if (this.props.contas == null || this.props.contas.length == 0) {
                                this.props.getContasAsync(this.props.instance, (response) => {
                                    setTimeout(() => {
                                        this.setState({ isLoading: false });
                                        this.props.navigation.navigateInternal('/dash', { noHistory: false, timeout: 10000, apiId: ApiId.ssoSilent })
                                    }, 3000);
                                });
                            } else {
                                setTimeout(() => {
                                    this.setState({ isLoading: false });
                                    this.props.navigation.navigateInternal('/dash', { noHistory: false, timeout: 10000, apiId: ApiId.ssoSilent })
                                }, 3000);
                            }
                        } catch (error) {
                            this.setState({ isLoading: false });
                        }
                    }}>
                        <Modal.Body>
                            <h4 style={{ fontFamily: 'TheSeasons' }} ><span style={{ fontFamily: 'Blanka' }}>BX</span><span style={{ color: '#004AAD', fontWeight: 'bold' }}>|</span>Invest</h4>
                            <div style={{display:'inline-block'}}>
                                Carregando informações de usuário...<span><Spinner animation="border">
                                </Spinner></span>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Container>
            </AuthenticatedTemplate>
        </>
    }
}

export default connect(
    (state: ApplicationState) => ({
        ...state.usuarioState
    }),
    { ...UsuarioStore.actionCreators })(LoadingHome);