import React, { Component, FormEvent } from 'react';
//import { WebPubSubServiceClient, AzureKeyCredential } from '@azure/web-pubsub'

// == W3C sockets
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { CustomNavigationClient } from '../../../utils/NavigationClient';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import * as UsuarioStore from '../../../store/usuario/usuarioStore';
import * as RelatoriosStore from '../../../store/relatorios/relatoriosStore';
import { Contas } from '../../usuario/usuarioAPI';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { apiRequest } from '../../../authConfig';
import { AuthenticatedTemplate } from "@azure/msal-react";
import Layout from '../../layout/Layout';
import { Accordion, Button, Col, Dropdown, DropdownButton, Form, Pagination, Row, Table } from 'react-bootstrap';
import { TipoAtivo, tiposAtivos } from '../../ativos/ativoAPI';
import { AtivoB3, RelatorioPosicaoItem } from './relatorioAPI';
import { ResponsePagination } from '../../communs';

// == Create the service client
// const hubName = 'ativob3-tick'
// const key = "uY8rN+FuABQbPAuzZfecxFJ3uajejIGLKbgADp9PGZ0="
// const cred = new AzureKeyCredential(key)
// const endpoint = "https://br-bx-ps-invest.webpubsub.azure.com"
// const serviceClient = new WebPubSubServiceClient(endpoint, cred, hubName)
// console.log('Azure WebPubSub service client is', serviceClient)


const urlwws = "wss://br-bx-ps-invest.webpubsub.azure.com/client/hubs/ativob3tick"
/**
 * @summary Connect to the secure socket server thru the WebPubSub service
 */
async function connectWs(instance: IPublicClientApplication, onError: (error: any) => void, onOpen: () => void, onMessage: (message: any) => void, onClose: (event: any) => void, callback: (ws: W3CWebSocket) => void) {
    console.log('About to get auth token…')
    //let token = await serviceClient.getClientAccessToken()
    //console.log('Auth token is', token)

    // == Connect to the secured socket server      

    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });

    const url = urlwws ;//+ responseToken.accessToken;//token.url
    let wsClient = new W3CWebSocket(url)

    console.log('Web socket is', wsClient)


    // == Connect the callbacks
    wsClient.onerror = onError
    wsClient.onopen = onOpen
    wsClient.onmessage = onMessage
    wsClient.onclose = onClose
    callback(wsClient);
}

// == Now actually connect to the secure web sockets

type Props = {
    navigation: CustomNavigationClient,
    window?: () => Window,
    instance: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators & RelatoriosStore.RelatoriosState & typeof RelatoriosStore.actionCreators;

type ContratoUIState = {
    prevState: boolean;
    selectedAccount: Contas[];
    Contas: Contas[];
    isLoading: boolean;
    dataInicial: string;
    dataFinal: string;
    tamanhoPagina: number;
    selectContasTodos: boolean;
    ws: W3CWebSocket | undefined;
    TiposAtivo: TipoAtivo[];
    selectedTiposAtivo: TipoAtivo[];
    RelatorioPosicaoAtivoResponse: ResponsePagination<RelatorioPosicaoItem> | undefined;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

class RelPosicaoAtivo extends React.PureComponent<Props, ContratoUIState>{
    constructor(props: any) {
        super(props);
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        datainicial.setFullYear(datainicial.getFullYear() - 2);
        this.state = {
            selectedAccount: [],
            prevState: false,
            Contas: [],
            isLoading: false,
            dataInicial: datainicial.toISOString().split('T')[0],
            dataFinal: new Date().toISOString().split('T')[0],
            tamanhoPagina: 15,
            selectContasTodos: true,
            ws: undefined,
            TiposAtivo: tiposAtivos,
            selectedTiposAtivo: tiposAtivos,
            RelatorioPosicaoAtivoResponse: undefined
        };

    }


    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.state.Contas.find(a => a.id === event.target.value) as Contas;
        if (account != null && account != undefined) {
            if (this.state.selectedAccount?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedAccount: this.state.selectedAccount.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedAccount: [...this.state.selectedAccount, account]
                });
            }
        }
    };
    handleTiposChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const tipo = this.state.TiposAtivo.find(a => a.id === event.target.value) as TipoAtivo;
        if (tipo != null && tipo != undefined) {
            if (this.state.selectedTiposAtivo?.includes(tipo)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedTiposAtivo: this.state.selectedTiposAtivo.filter(t => t.id !== tipo.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedTiposAtivo: [...this.state.selectedTiposAtivo, tipo]
                });
            }
        }
    };

    handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        try {
            this.props.ConsultarRelatorioPosicaoAtivo(this.props.instance,
                this.state.selectedAccount.map(c => c.id),
                this.state.dataFinal,
                1,
                this.state.tamanhoPagina,
                (responsePosicaoAtivos: ResponsePagination<RelatorioPosicaoItem> | undefined) => {
                    this.setState({ RelatorioPosicaoAtivoResponse: responsePosicaoAtivos, isLoading: false });
                    this.props.MonitorarAtivos(this.props.instance, responsePosicaoAtivos?.result.map((i: RelatorioPosicaoItem) => { return { Token: i.produto.codigo } }) as { Token: string }[]);
                });
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };

    componentDidMount(): void {
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        if (this.props.contas == undefined || this.props.contas.length == 0) {
            this.props.getContasAsync(this.props.instance, (response) => {
                this.setState({ Contas: response.contas, selectedAccount: response.contas });
                this.props.ConsultarRelatorioPosicaoAtivo(this.props.instance,
                    response.contas.map(c => c.id),
                    new Date().toISOString().split('T')[0],
                    1,
                    this.state.tamanhoPagina,
                    (responsePosicaoAtivos: ResponsePagination<RelatorioPosicaoItem> | undefined) => {
                        this.setState({ RelatorioPosicaoAtivoResponse: responsePosicaoAtivos, isLoading: false });
                        this.props.MonitorarAtivos(this.props.instance, responsePosicaoAtivos?.result.map((i: RelatorioPosicaoItem) => { return { Token: i.produto.codigo } }) as { Token: string }[]);
                    });
            });
        } else {
            this.setState({ Contas: this.props.contas as Contas[], selectedAccount: this.props.contas as Contas[] });
            this.props.ConsultarRelatorioPosicaoAtivo(this.props.instance,
                this.props.contas?.map(c => c.id as string),
                new Date().toISOString().split('T')[0],
                1,
                this.state.tamanhoPagina,
                (responsePosicaoAtivos: ResponsePagination<RelatorioPosicaoItem> | undefined) => {
                    this.setState({ RelatorioPosicaoAtivoResponse: responsePosicaoAtivos, isLoading: false });
                    this.props.MonitorarAtivos(this.props.instance, responsePosicaoAtivos?.result.map((i: RelatorioPosicaoItem) => { return { Token: i.produto.codigo } }) as { Token: string }[]);
                });
        }
        connectWs(this.props.instance,
            (err) => { }, () => { },
            (mes) => {
                var ativoB3: AtivoB3 = JSON.parse(JSON.parse(mes.data)) as AtivoB3;
                console.log('atualizando ativo:', ativoB3);
                this.props.AtualizarDicionarioAtivo(this.props.instance, ativoB3)
            },
            (event) => { }, (ws) => {
                this.setState({ ws: ws })
            });
    }
    componentWillUnmount(): void {
        if (this.state.ws) this.state.ws.close();
    }
    render(): React.ReactNode {
        return <AuthenticatedTemplate>
            <Layout navigation={this.props.navigation} isLoading={this.props.RelatorioPosicaoAtivos.status == 'loading'} permissoes={this.props.usuario?.permissoes}>
                <div className="container mt-5">
                    <h1>Posição de Ativos</h1>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Opções de filtro</Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col >
                                            <Form.Group controlId="selectConta">
                                                <Form.Label>Selecione a conta:</Form.Label>
                                                <DropdownButton title="Selecione as Contas" id="dropdown-contas" autoClose="outside">
                                                    <Dropdown.Item
                                                        key="select-selectConta-filtro"
                                                        as="label"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="select-selectConta-filtro"
                                                            label='Selecione Todos'
                                                            value='select-selectConta-filtro'
                                                            checked={this.state.selectContasTodos}
                                                            onChange={() => { this.setState({ selectedAccount: !this.state.selectContasTodos ? this.state.Contas : [], selectContasTodos: !this.state.selectContasTodos }) }}
                                                        />
                                                    </Dropdown.Item>
                                                    {this.state.Contas.map(conta => (
                                                        <Dropdown.Item
                                                            key={conta.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${conta.id}`}
                                                                label={conta.nome}
                                                                value={conta.id}
                                                                checked={this.state.selectedAccount.includes(conta)}
                                                                onChange={this.handleAccountChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>
                                        <Col >
                                            <Form.Group as={Col} controlId="tipoLancamento">
                                                <Form.Label>Tipo de Ativo</Form.Label>
                                                <DropdownButton title="Selecione os tipos de Ativos" id="dropdown-contas" autoClose="outside">
                                                    {this.state.TiposAtivo.map(tipo => (
                                                        <Dropdown.Item
                                                            key={tipo.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${tipo.id}`}
                                                                label={tipo.nome}
                                                                value={tipo.id}
                                                                checked={this.state.selectedTiposAtivo.includes(tipo)}
                                                                onChange={this.handleTiposChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Form.Group as={Col} controlId="dataFinal">
                                            <Form.Label>Data Base</Form.Label>
                                            <Form.Control type="date" value={this.state.dataFinal} onChange={(event) => this.setState({ dataFinal: new Date(event.target.value).toISOString().split('T')[0] })} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="tamanhoPagina">
                                            <Form.Label>Quantidade de Registros</Form.Label>
                                            <Form.Control as="input" type="number" value={this.state.tamanhoPagina} onChange={(event) => this.setState({ tamanhoPagina: parseFloat(event.target.value) })} />
                                        </Form.Group>
                                    </Row>
                                    <Button className='mt-4'
                                        variant="primary"
                                        type="submit"
                                        disabled={!this.state.selectedAccount || this.state.isLoading}
                                    >
                                        {this.state.isLoading ? "Pesquisando..." : "Pesquisar"}
                                    </Button>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>Conta</th>
                                <th>Código</th>
                                <th>Nome</th>
                                <th>Quantidade</th>
                                <th>Preço Médio</th>
                                <th>Valor Investido</th>
                                <th>Preço Atual</th>
                                <th>Valor Atual</th>
                                <th>Lucro/Prejuízo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.RelatorioPosicaoAtivoResponse?.result?.map((item: RelatorioPosicaoItem) => {
                                return <tr key={item.conta.id + item.produto.codigo}>
                                    <td>{item.conta.nome}</td>
                                    <td>{item.produto.codigo}</td>
                                    <td>{item.produto.nome}</td>
                                    <td>{item.quantidade}</td>
                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.precoMedio)}</td>
                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valorInvestido)}</td>
                                    <td>{this.props.ativosB3.has(item.produto.codigo.toUpperCase()) ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.props.ativosB3.get(item.produto.codigo.toUpperCase())?.Preco as number) : '-'}</td>
                                    <td>{this.props.ativosB3.has(item.produto.codigo.toUpperCase()) ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.quantidade * (this.props.ativosB3.get(item.produto.codigo.toUpperCase())?.Preco as number)) : '-'}</td>
                                    <td>{this.props.ativosB3.has(item.produto.codigo.toUpperCase()) ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.quantidade * (this.props.ativosB3.get(item.produto.codigo.toUpperCase())?.Preco as number) - item.valorInvestido) : '-'} </td>
                                </tr>
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={5} >
                                    Totais
                                </th>
                                <th align='right' style={{ 'alignContent': 'flex-end' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((this.state.RelatorioPosicaoAtivoResponse?.result ?? [] as RelatorioPosicaoItem[]).map((v: RelatorioPosicaoItem) => v.valorInvestido).reduce((acc: number, current: number) => acc + current, 0))}</th>
                                <th></th>
                                <th></th>
                                <th align='right' style={{ 'alignContent': 'flex-end' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((this.state.RelatorioPosicaoAtivoResponse?.result ?? [] as RelatorioPosicaoItem[]).map((v: RelatorioPosicaoItem) => (v.quantidade * (this.props.ativosB3.get(v.produto.codigo.toUpperCase())?.Preco as number) - v.valorInvestido)).reduce((acc: number, current: number) => acc + current, 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>
                    <Pagination>
                        <Pagination.Prev
                            disabled={this.state.RelatorioPosicaoAtivoResponse?.pagina === 1}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.ConsultarRelatorioPosicaoAtivo(this.props.instance,
                                    this.state.selectedAccount.map(c => c.id),
                                    this.state.dataFinal,
                                    this.state.RelatorioPosicaoAtivoResponse?.pagina as number - 1,
                                    this.state.tamanhoPagina,
                                    (responseAtivos: ResponsePagination<RelatorioPosicaoItem> | undefined) => {
                                        this.setState({ RelatorioPosicaoAtivoResponse: responseAtivos, isLoading: false });
                                        this.props.MonitorarAtivos(this.props.instance, responseAtivos?.result.map((i: RelatorioPosicaoItem) => { return { Token: i.produto.codigo } }) as { Token: string }[]);
                                    });
                            }}
                        />

                        {[(new Array(this.state.RelatorioPosicaoAtivoResponse?.totalPagina as number)).keys()].map((v, page) => (
                            <Pagination.Item
                                key={page + 1}
                                active={page + 1 === this.state.RelatorioPosicaoAtivoResponse?.pagina}
                                onClick={() => {
                                    this.setState({ isLoading: true });
                                    this.props.ConsultarRelatorioPosicaoAtivo(this.props.instance, this.state.selectedAccount.map(c => c.id),
                                        this.state.dataFinal,
                                        page + 1,
                                        this.state.tamanhoPagina,
                                        (responseAtivos: ResponsePagination<RelatorioPosicaoItem> | undefined) => {
                                            this.setState({ RelatorioPosicaoAtivoResponse: responseAtivos, isLoading: false });
                                            this.props.MonitorarAtivos(this.props.instance, responseAtivos?.result.map((i: RelatorioPosicaoItem) => { return { Token: i.produto.codigo } }) as { Token: string }[]);
                                        });
                                }}
                            >
                                {page + 1}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next
                            disabled={this.state.RelatorioPosicaoAtivoResponse?.pagina === this.state.RelatorioPosicaoAtivoResponse?.totalPagina}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.ConsultarRelatorioPosicaoAtivo(this.props.instance, this.state.selectedAccount.map(c => c.id),
                                    this.state.dataFinal,
                                    this.state.RelatorioPosicaoAtivoResponse?.pagina as number + 1,
                                    this.state.tamanhoPagina,
                                    (responseAtivos: ResponsePagination<RelatorioPosicaoItem> | undefined) => {
                                        this.setState({ RelatorioPosicaoAtivoResponse: responseAtivos, isLoading: false });
                                        this.props.MonitorarAtivos(this.props.instance, responseAtivos?.result.map((i: RelatorioPosicaoItem) => { return { Token: i.produto.codigo } }) as { Token: string }[]);
                                    });
                            }}
                        />
                    </Pagination>
                </div>
            </Layout>
        </AuthenticatedTemplate>
    }
}


export default connect(
    (state: ApplicationState) => ({
        ...state.usuarioState, ...state.relatoriosState
    }),
    { ...UsuarioStore.actionCreators, ...RelatoriosStore.actionCreators })(RelPosicaoAtivo);