import React, { Component, FormEvent } from 'react';
import { useMsal, MsalContext, withMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Layout from '../layout/Layout';
import { CustomNavigationClient } from '../../utils/NavigationClient';
import * as UsuarioStore from '../../store/usuario/usuarioStore';
import * as AtivoStore from '../../store/ativo/ativoStore';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { IPublicClientApplication } from "@azure/msal-browser";
import { Contas } from '../usuario/usuarioAPI';
import { Button, Form, Col, Row, DropdownButton, Dropdown, Accordion, Card, Pagination } from "react-bootstrap";
import { TipoAtivo, tiposAtivos } from './ativoAPI';
import { Ativo } from '../importarNotas/importarNotasAPI';
import GridAtivo from './gridAtivo';
import { ResponsePagination } from '../communs';



type Props = {
    navigation: CustomNavigationClient,
    window?: () => Window,
    instance: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators & AtivoStore.AtivoState & typeof AtivoStore.actionCreators;

type AtivoUIState = {
    prevState: boolean;
    selectedAccount: Contas[];
    Contas: Contas[];
    isLoading: boolean;
    dataInicial: string;
    dataFinal: string;
    TiposAtivo: TipoAtivo[];
    selectedTiposAtivo: TipoAtivo[];
    AtivoResponse: ResponsePagination<Ativo> | undefined;
    tamanhoPagina: number;
    selectContasTodos: boolean;
    nomeAtivo: string;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
class AtivoUI extends React.Component<Props, AtivoUIState> {
    constructor(props: any) {
        super(props)
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        this.state = {
            selectedAccount: [],
            prevState: false,
            Contas: [],
            isLoading: false,
            dataInicial: datainicial.toISOString().split('T')[0],
            dataFinal: new Date().toISOString().split('T')[0],
            selectedTiposAtivo: tiposAtivos,
            TiposAtivo: tiposAtivos,
            AtivoResponse: undefined,
            tamanhoPagina: 15,
            selectContasTodos: true,
            nomeAtivo: ""
        };

    }

    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.state.Contas.find(a => a.id === event.target.value) as Contas;
        if (account != null && account != undefined) {
            if (this.state.selectedAccount?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedAccount: this.state.selectedAccount.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedAccount: [...this.state.selectedAccount, account]
                });
            }
        }
    };
    handleTiposChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const tipo = this.state.TiposAtivo.find(a => a.id === event.target.value) as TipoAtivo;
        if (tipo != null && tipo != undefined) {
            if (this.state.selectedTiposAtivo?.includes(tipo)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedTiposAtivo: this.state.selectedTiposAtivo.filter(t => t.id !== tipo.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedTiposAtivo: [...this.state.selectedTiposAtivo, tipo]
                });
            }
        }
    };

    handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        try {
            this.props.listarAtivosAsync({
                contas: this.state.selectedAccount.map(c => c.id),
                dataInicial: this.state.dataInicial,
                dataFinal: this.state.dataFinal,
                pagina: 1,
                tamanhoPagina: this.state.tamanhoPagina,
                tipos: this.state.selectedTiposAtivo.map(t => t.id),
                nomeAtivo: this.state.nomeAtivo
            }, this.props.instance, (responseAtivos: ResponsePagination<Ativo>) => {
                this.setState({ AtivoResponse: responseAtivos, isLoading: false });
            });
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };

    componentDidMount(): void {
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        if (this.props.contas == undefined || this.props.contas.length == 0) {
            this.props.getContasAsync(this.props.instance, (response) => {
                this.setState({ Contas: response.contas, selectedAccount: response.contas });
                this.props.listarAtivosAsync({
                    contas: response.contas.map(c => c.id),
                    dataInicial: datainicial.toISOString().split('T')[0],
                    dataFinal: (new Date()).toISOString().split('T')[0],
                    pagina: 1,
                    tamanhoPagina: this.state.tamanhoPagina,
                    tipos: tiposAtivos.map(t => t.id),
                    nomeAtivo: this.state.nomeAtivo
                }, this.props.instance, (responseAtivos: ResponsePagination<Ativo>) => {
                    this.setState({ AtivoResponse: responseAtivos });
                });
            });
        } else {
            this.setState({ Contas: this.props.contas as Contas[], selectedAccount: this.props.contas as Contas[] });
            this.props.listarAtivosAsync({
                contas: this.props.contas?.map(c => c.id as string),
                dataInicial: datainicial.toISOString().split('T')[0],
                dataFinal: (new Date()).toISOString().split('T')[0],
                pagina: 1,
                tamanhoPagina: this.state.tamanhoPagina,
                tipos: tiposAtivos.map(t => t.id),
                nomeAtivo: this.state.nomeAtivo
            }, this.props.instance, (responseAtivos: ResponsePagination<Ativo>) => {
                this.setState({ AtivoResponse: responseAtivos });
            });
        }

    }
    render(): React.ReactNode {
        const { selectedAccount, isLoading, selectedTiposAtivo } = this.state;
        return <AuthenticatedTemplate>
            <Layout navigation={this.props.navigation} isLoading={this.props.status == 'loading'} permissoes={this.props.usuario?.permissoes}>
                <div className="container mt-5">
                    <h1>Lançamentos em Ativos</h1>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Opções de filtro</Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col >
                                            <Form.Group controlId="selectConta">
                                                <Form.Label>Selecione a conta:</Form.Label>
                                                <DropdownButton title="Selecione as Contas" id="dropdown-contas" autoClose="outside">
                                                <Dropdown.Item
                                                        key="select-selectConta-filtro"
                                                        as="label"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="select-selectConta-filtro"
                                                            label='Selecione Todos'
                                                            value='select-selectConta-filtro'
                                                            checked={this.state.selectContasTodos}
                                                            onChange={() => { this.setState({ selectedAccount: !this.state.selectContasTodos ? this.state.Contas : [], selectContasTodos: !this.state.selectContasTodos }) }}
                                                        />
                                                    </Dropdown.Item>
                                                    {this.state.Contas.map(conta => (
                                                        <Dropdown.Item
                                                            key={conta.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${conta.id}`}
                                                                label={conta.nome}
                                                                value={conta.id}
                                                                checked={selectedAccount.includes(conta)}
                                                                onChange={this.handleAccountChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>
                                        <Col >
                                            <Form.Group as={Col} controlId="tipoLancamento">
                                                <Form.Label>Tipo de Ativo</Form.Label>
                                                <DropdownButton title="Selecione os tipos de Ativos" id="dropdown-contas" autoClose="outside">
                                                    {this.state.TiposAtivo.map(tipo => (
                                                        <Dropdown.Item
                                                            key={tipo.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${tipo.id}`}
                                                                label={tipo.nome}
                                                                value={tipo.id}
                                                                checked={selectedTiposAtivo.includes(tipo)}
                                                                onChange={this.handleTiposChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Form.Group as={Col} controlId="dataInicio">
                                            <Form.Label>Data Inicial</Form.Label>
                                            <Form.Control type="date" value={this.state.dataInicial} onChange={(event) => this.setState({ dataInicial: new Date(event.target.value).toISOString().split('T')[0] })} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="dataFinal">
                                            <Form.Label>Data Final</Form.Label>
                                            <Form.Control type="date" value={this.state.dataFinal} onChange={(event) => this.setState({ dataFinal: new Date(event.target.value).toISOString().split('T')[0] })} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="tamanhoPagina">
                                            <Form.Label>Quantidade de Registros</Form.Label>
                                            <Form.Control as="input" type="number" value={this.state.tamanhoPagina} onChange={(event) => this.setState({ tamanhoPagina: parseFloat(event.target.value) })} />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="nomeAtivo">
                                            <Form.Label>Nome ativo</Form.Label>
                                            <Form.Control as="input" type="text" value={this.state.nomeAtivo} onChange={(event) => this.setState({ nomeAtivo: event.target.value })} />
                                        </Form.Group>
                                    </Row>
                                    <Button className='mt-4'
                                        variant="primary"
                                        type="submit"
                                        disabled={!selectedAccount || isLoading}
                                    >
                                        {isLoading ? "Pesquisando..." : "Pesquisar"}
                                    </Button>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <GridAtivo
                        contas={this.state.Contas}
                        itensResponse={this.state.AtivoResponse as ResponsePagination<Ativo>}
                        addHandle={(ativo, callback) => {
                            this.props.adicionarAtivoAsync(ativo, this.props.instance, (response) => {
                                alert('Ativo adicionado com sucesso.');
                                callback(response);
                            })
                        }} updateHandle={(ativo, callback) => {
                            this.props.atualizarAtivoAsync(ativo, this.props.instance, (response) => {
                                alert('Ativo atualizado com sucesso.');
                                callback(response);
                            })
                        }}
                        onExcluirAtivo={(ativo) => { }} permissoes={this.props.usuario?.permissoes}/>
                    <Pagination>
                        <Pagination.Prev
                            disabled={this.state.AtivoResponse?.pagina === 1}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.listarAtivosAsync({
                                    contas: this.state.selectedAccount.map(c => c.id),
                                    dataInicial: this.state.dataInicial,
                                    dataFinal: this.state.dataFinal,
                                    pagina: this.state.AtivoResponse?.pagina as number - 1,
                                    tamanhoPagina: this.state.tamanhoPagina,
                                    nomeAtivo: this.state.nomeAtivo,
                                    tipos: tiposAtivos.map(t => t.id)
                                }, this.props.instance, (responseAtivos: ResponsePagination<Ativo>) => {
                                    this.setState({ AtivoResponse: responseAtivos, isLoading: false });
                                });
                            }}
                        />

                        {[(new Array(this.state.AtivoResponse?.totalPagina as number)).keys()].map((v, page) => (
                            <Pagination.Item
                                key={page + 1}
                                active={page + 1 === this.state.AtivoResponse?.pagina}
                                onClick={() => {
                                    this.setState({ isLoading: true });
                                    this.props.listarAtivosAsync({
                                        contas: this.state.selectedAccount.map(c => c.id),
                                        dataInicial: this.state.dataInicial,
                                        dataFinal: this.state.dataFinal,
                                        pagina: page + 1,
                                        tamanhoPagina: this.state.tamanhoPagina,
                                        nomeAtivo: this.state.nomeAtivo,
                                        tipos: tiposAtivos.map(t => t.id)
                                    }, this.props.instance, (responseAtivos: ResponsePagination<Ativo>) => {
                                        this.setState({ AtivoResponse: responseAtivos, isLoading: false });
                                    });
                                }}
                            >
                                {page + 1}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next
                            disabled={this.state.AtivoResponse?.pagina === this.state.AtivoResponse?.totalPagina}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.listarAtivosAsync({
                                    contas: this.state.selectedAccount.map(c => c.id),
                                    dataInicial: this.state.dataInicial,
                                    dataFinal: this.state.dataFinal,
                                    pagina: this.state.AtivoResponse?.pagina as number + 1,
                                    tamanhoPagina: this.state.tamanhoPagina,
                                    nomeAtivo: this.state.nomeAtivo,
                                    tipos: tiposAtivos.map(t => t.id)
                                }, this.props.instance, (responseAtivos: ResponsePagination<Ativo>) => {
                                    this.setState({ AtivoResponse: responseAtivos, isLoading: false });
                                });
                            }}
                        />
                    </Pagination>
                </div>
            </Layout>
        </AuthenticatedTemplate>
    }
}


export default connect(
    (state: ApplicationState) => ({
        ...state.ativoState, ...state.usuarioState
    }),
    { ...UsuarioStore.actionCreators, ...AtivoStore.actionCreators })(AtivoUI);