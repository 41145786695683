import { Produto, ResponsePagination } from "../../communs";
import ConnectionAPI from "../../connection/connectionAPI";
import { Ativo } from "../../importarNotas/importarNotasAPI";
import { Contas } from "../../usuario/usuarioAPI";

export interface RelatorioPosicaoItem {
    conta: Contas;
    produto: Produto;
    quantidade: number;
    precoMedio: number;
    valorInvestido: number;
}

export interface AtivoB3 {
    Token : string;
    Nome: string;
    Descricao: string;
    Preco: number;    
}

export  async function  ConsultarRelatorioPosicaoAtivo(request: string[], dataBase: string, pagina: number, tamanhoPagina: number, connection: ConnectionAPI ){
    return await connection.Get<ResponsePagination<RelatorioPosicaoItem>>(ConnectionAPI.Endpoints.ConsultarRelatorioPosicaoAtivo(request, dataBase, pagina, tamanhoPagina));
}
export  async function  ConsultarRelatorioAtivoPendenteNormalizacao( pagina: number, tamanhoPagina: number, contas: string[], connection: ConnectionAPI ){
    return await connection.Get<ResponsePagination<Ativo>>(ConnectionAPI.Endpoints.ConsultarRelatorioAtivoPendenteNormalizacao(pagina, tamanhoPagina, contas));
}
export  async function  MonitorarAtivos(request:  { Token: string}[], connection: ConnectionAPI ){
    return await connection.Post<ResponsePagination<{ Token: string}[]>>(ConnectionAPI.Endpoints.MonitorarAtivo, JSON.stringify( request), {'Content-Type':'application/json' });
}

