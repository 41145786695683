import React from 'react';
import { CustomNavigationClient } from '../../utils/NavigationClient';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../ui/Loading';
import { MsalContext } from '@azure/msal-react';

type Props = {
    children: JSX.Element | null,
    navigation: CustomNavigationClient,
    window?: () => Window;
    isLoading: boolean;
    permissoes: string[]|undefined;
}
type LayoutState = {
    prevState: boolean
}

const drawerWidth = 240;
export default class Layout extends React.Component<Props, LayoutState> {
    constructor(props: any) {
        super(props);
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.state = {
            prevState: false
        }
    }
    static contextType = MsalContext;
    context!: React.ContextType<typeof MsalContext>
    handleDrawerToggle = () => {
        this.setState({ prevState: !this.state.prevState });
    };
    render(): React.ReactNode {
        return (<div>
            <Navbar variant="dark" bg="black" expand="lg">
                <Link to="/dash" className='ms-3 navbar-brand' >
                    <div style={{ display: 'inline-flex', color: 'white', fontFamily: 'TheSeasons' }}><span style={{ paddingRight: '2px', color: 'white', fontFamily: 'Blanka' }}>BX</span><span style={{ color: '#C31E22', paddingRight: '2px', fontWeight: 'bold' }}>|</span >Invest</div>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-3" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto ms-3" hidden={this.props.permissoes && !this.props.permissoes?.some(p=>["Admin"].includes(p))}>
                        <NavDropdown title="Corretora" id="navbarScrollingDropdown">
                            <NavDropdown.Item as={Link} to="/importarNotas" title='Importar Notas de Corretagem'>
                                Importar Notas de Corretagem
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav className="mr-auto ms-3">
                        <NavDropdown title="Conta Corrente" id="navbarScrollingDropdown">
                            <NavDropdown.Item as={Link} to="/lancamentoConta" title='Lançamentos'>
                                Lançamentos
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav className="mr-auto ms-3">
                        <NavDropdown title="Bens e Ativos" id="navbarScrollingDropdown">
                            <NavDropdown.Item as={Link} to="/ativos" title='Lançamentos'>
                                Lançamentos
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav className="mr-auto ms-3">
                        <NavLink as={Link} to="/contratos">Contratos</NavLink>
                        
                    </Nav>
                    <Nav className="mr-auto ms-3" hidden={this.props.permissoes && !this.props.permissoes?.some(p=>["Admin"].includes(p))}>
                        <NavLink as={Link} to="/produto">Produtos</NavLink>
                        
                    </Nav>
                    <Nav className="mr-auto ms-3">
                    <NavDropdown title="Relatórios" id="navbarScrollingDropdown">
                            <NavDropdown.Item as={Link} to="/relposicaoativo" title='Posição de Ativos'>
                                Posição de Ativos
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/relpendentenormalizacao" title='Rel. Pendentes Normalização' hidden={this.props.permissoes && !this.props.permissoes?.some(p=>["Admin"].includes(p))}>
                                Rel. Pendentes Normalização
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className='justify-content-end' id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Button className='me-3 ms-3' variant="outline-primary" onClick={() => {
                            const msalInstance = this.context.instance;
                            msalInstance.logoutPopup({
                                mainWindowRedirectUri: "/"
                            });
                        }} >Sair</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Loading isLoading={this.props.isLoading} />
            <div style={{ marginTop: '20px', maxWidth: '100%' }}>
                {
                    this.props.children
                }
            </div>
        </div>
        )
    }
}