import React, { Component } from 'react';
import { Container, Row, Col, Button, Table, Collapse, Form, Modal, DropdownButton, Dropdown, FormFloating, Pagination } from 'react-bootstrap';
import { ProcessamentoNotasCorretagem } from './importarNotasAPI';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ResponsePagination } from '../communs';


interface GridProcessamentoNotaState {
    isLoading: boolean
}

interface GridProcessamentoNotaProps {
    responsePagination: ResponsePagination<ProcessamentoNotasCorretagem>;
    onApprove: (processamento: ProcessamentoNotasCorretagem, callback: () => void) => void;
    onCancel: (processamento: ProcessamentoNotasCorretagem, callback: () => void) => void;
}

export default class GridProcessamentoNota extends React.Component<GridProcessamentoNotaProps, GridProcessamentoNotaState>{
    constructor(props: GridProcessamentoNotaProps) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    render(): React.ReactNode {
        const { onApprove, onCancel } = this.props;
        return <div>
            <Table key={'212102919238'} striped bordered hover>
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Data</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.responsePagination?.result?.map((processamento: ProcessamentoNotasCorretagem) => {
                            return <React.Fragment key={'00-' + processamento.id}><tr key={'00-' + processamento.id}>
                                <th>{processamento.tipoProcessamento}</th>
                                <th>{processamento.data}</th>
                                <th>{processamento.status}</th>
                                <th>
                                    <Button variant="success" disabled={this.state.isLoading} onClick={() => {
                                        this.setState({ isLoading: true });
                                        onApprove(processamento, () => {
                                            this.setState({ isLoading: false })
                                        })
                                    }}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>{" "}
                                    <Button variant="danger" disabled={this.state.isLoading} onClick={() => {
                                        this.setState({ isLoading: true });
                                        onCancel(processamento, () => {
                                            this.setState({ isLoading: false })
                                        })
                                    }}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </th>
                            </tr><tr >
                                    <td colSpan={4}>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Tipo</th>
                                                    <th>Código</th>
                                                    <th>Descrição</th>
                                                    <th>Data Aquisição</th>
                                                    <th>Quantidade</th>
                                                    <th>Preço Compra</th>
                                                    <th>Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {processamento.lancamentosAtivos.map((ativo, index) => {
                                                    return (<tr key={'01-' + index + processamento.id}>
                                                        <td>{ativo.tipoAtivo}</td>
                                                        <td>{ativo.produto.codigo}</td>
                                                        <td>{ativo.produto.descricao}</td>
                                                        <td>{ativo.dataCompra}</td>
                                                        <td>{ativo.quantidade}</td>
                                                        <td>{ativo.precoCompra}</td>
                                                        <td>{ativo.valorTotal}</td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Tipo</th>
                                                    <th>Data Lançamento</th>
                                                    <th>Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {processamento.lancamentosConta.map((lancamento, index) => {
                                                    return (<tr key={'02-' + index + processamento.id}>
                                                        <td>{lancamento.tipoLancamento.nome}</td>
                                                        <td>{lancamento.dataLancamento}</td>
                                                        <td>{lancamento.valor}</td>
                                                    </tr>)

                                                })}
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </React.Fragment>
                        })
                    }
                </tbody>
            </Table>
        </div>
    }
}