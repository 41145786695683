import { AccountInfo, IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest, loginRequest } from "../../authConfig";
import { DefaultConnection } from "../../features/connection/connectionAPI";
import { AppThunkAction } from "..";
import { Action, Reducer } from 'redux';
import { ListarProcessamentoNotasCorretagemRequest, ProcessamentoNotasCorretagem, ProcessamentoNotasCorretagemRequest, getListarLancamentosPendenteAprovacao, postGerarProcessarNotasCorretagem, putAprovarProcessamento, putRejeitarProcessamento } from "../../features/importarNotas/importarNotasAPI";
import { appInsights } from "../..";
import { ResponsePagination } from "../../features/communs";



export interface ImportacaoNotaState {
    processamentoNotaResponse: Partial<ResponsePagination<ProcessamentoNotasCorretagem>> | undefined;
    listarProcessamentoPendenteFiltro: Partial<ProcessamentoNotasCorretagemRequest> | undefined;
    status: 'idle' | 'loading' | 'failed';
}

const unloadedState: ImportacaoNotaState = {
    processamentoNotaResponse: undefined,
    listarProcessamentoPendenteFiltro: undefined,
    status: 'idle',
};
interface RequestGetProcessamentoPendenteAction {
    type: 'REQUEST_GET_LISTAR_PROCESSAMENTO_PENDENTE';
}

interface ReceiveGetListarProcessamentoPendenteAction {
    type: 'RECEIVE_GET_LISTAR_PROCESSAMENTO_PENDENTE';
    response: ResponsePagination<ProcessamentoNotasCorretagem>;
}
interface RequestGerarLancamentoAction {
    type: 'REQUEST_POST_GERAR_PROCESSAMENTO';
}

interface ReceiveGerarLancamentoAction {
    type: 'RECEIVE_POST_GERAR_PROCESSAMENTO';
    response: ResponsePagination<ProcessamentoNotasCorretagem>;
}

interface RequestAprovarProcessamentoAction {
    type: 'REQUEST_PUT_APROVAR_PROCESSAMENTO';
}

interface ReceiveAprovarProcessamentoAction {
    type: 'RECEIVE_PUT_APROVAR_PROCESSAMENTO';
    response: Response;
}
interface RequestRejeitarProcessamentoAction {
    type: 'REQUEST_PUT_REJEITAR_PROCESSAMENTO';
}

interface ReceiveRejeitarProcessamentoAction {
    type: 'RECEIVE_PUT_REJEITAR_PROCESSAMENTO';
    response: Response;
}
interface ReceiveErrorNotasAction {
    type: 'RECEIVE_POST_ERROR';
}
type KnownAction = ReceiveRejeitarProcessamentoAction | RequestRejeitarProcessamentoAction | ReceiveAprovarProcessamentoAction | RequestAprovarProcessamentoAction | RequestGetProcessamentoPendenteAction | ReceiveGetListarProcessamentoPendenteAction | ReceiveErrorNotasAction | RequestGerarLancamentoAction | ReceiveGerarLancamentoAction;

export const actionCreators = {
    GerarLancamentosContaAsync: (request: ProcessamentoNotasCorretagemRequest, instance: IPublicClientApplication,
        callback: (response: ResponsePagination<ProcessamentoNotasCorretagem>) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:getUsuarioSSOAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.importacaoNotaState && appState.importacaoNotaState.status !== "loading") {
                    dispatch({ type: 'REQUEST_POST_GERAR_PROCESSAMENTO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({name:'REQUEST_POST_GERAR_PROCESSAMENTO'}, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username});
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    //actionCreators.GerarLancamentosContaAsync(request, instance, callback);
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    }).then(async (responseToken) => {
                        
                        const bearer = `Bearer ${responseToken?.accessToken}`;
                        DefaultConnection.header = { "Authorization": bearer };
                        const response = await postGerarProcessarNotasCorretagem(request, DefaultConnection);
                        dispatch({ type: 'RECEIVE_POST_GERAR_PROCESSAMENTO', response: response });
                        callback(response);
                    });
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao lista de lançamentos:' + JSON.stringify(error));
            }

        },
    ListarPendenteAprovacaoAsync: (request: Partial<ListarProcessamentoNotasCorretagemRequest>, instance: IPublicClientApplication,
        callback: (response: ResponsePagination<ProcessamentoNotasCorretagem>) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:getUsuarioSSOAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.importacaoNotaState && appState.importacaoNotaState.status !== "loading") {
                    dispatch({ type: 'REQUEST_GET_LISTAR_PROCESSAMENTO_PENDENTE' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({name:'REQUEST_GET_LISTAR_PROCESSAMENTO_PENDENTE'}, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username});
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    //actionCreators.ListarPendenteAprovacaoAsync(request, instance, callback);
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    }).then(async (responseToken) => {
                        const bearer = `Bearer ${responseToken?.accessToken}`;
                        DefaultConnection.header = { "Authorization": bearer };
                        const response = await getListarLancamentosPendenteAprovacao(request, DefaultConnection);
                        dispatch({ type: 'RECEIVE_GET_LISTAR_PROCESSAMENTO_PENDENTE', response: response });
                        callback(response);
                    });


                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        },
    AprovarProcessamentoAsync: (request: Partial<ProcessamentoNotasCorretagem>[], instance: IPublicClientApplication,
        callback: (response: Response) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:getUsuarioSSOAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.importacaoNotaState && appState.importacaoNotaState.status !== "loading") {
                    dispatch({ type: 'REQUEST_PUT_APROVAR_PROCESSAMENTO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({name:'REQUEST_PUT_APROVAR_PROCESSAMENTO'}, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username});
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    actionCreators.AprovarProcessamentoAsync(request, instance, callback);
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    }).then(async (responseToken) => {

                        
                        const bearer = `Bearer ${responseToken?.accessToken}`;
                        DefaultConnection.header = { "Authorization": bearer };
                        const response = await putAprovarProcessamento(request, DefaultConnection);
                        dispatch({ type: 'RECEIVE_PUT_APROVAR_PROCESSAMENTO', response: response });
                        callback(response);
                    });


                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
                callback(new Response());
            }

        },
    RejeitarProcessamentoAsync: (request: Partial<ProcessamentoNotasCorretagem>[], instance: IPublicClientApplication,
        callback: (response: Response) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:getUsuarioSSOAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.importacaoNotaState && appState.importacaoNotaState.status !== "loading") {
                    dispatch({ type: 'REQUEST_PUT_REJEITAR_PROCESSAMENTO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                     appInsights.trackEvent({name:'REQUEST_PUT_REJEITAR_PROCESSAMENTO'}, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username});
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    actionCreators.RejeitarProcessamentoAsync(request, instance, callback);
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    }).then(async (responseToken) => {

                        
                        const bearer = `Bearer ${responseToken?.accessToken}`;
                        DefaultConnection.header = { "Authorization": bearer };
                        const response = await putRejeitarProcessamento(request, DefaultConnection);
                        dispatch({ type: 'RECEIVE_PUT_REJEITAR_PROCESSAMENTO', response: response });
                        callback(response);
                    });


                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        }
}


export const reducer: Reducer<ImportacaoNotaState> = (state: ImportacaoNotaState | undefined, incomingAction: Action): ImportacaoNotaState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_POST_GERAR_PROCESSAMENTO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_POST_GERAR_PROCESSAMENTO':
            return { ...state, ...{ status: 'idle', processamentoNotaResponse: action.response } }
        case 'REQUEST_GET_LISTAR_PROCESSAMENTO_PENDENTE':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_GET_LISTAR_PROCESSAMENTO_PENDENTE':
            return { ...state, ...{ status: 'idle', processamentoNotaResponse: action.response } }
        case 'REQUEST_PUT_APROVAR_PROCESSAMENTO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_PUT_APROVAR_PROCESSAMENTO':
            return { ...state, ...{ status: 'idle' } }
        case 'REQUEST_PUT_REJEITAR_PROCESSAMENTO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_PUT_REJEITAR_PROCESSAMENTO':
            return { ...state, ...{ status: 'idle' } }
        case 'RECEIVE_POST_ERROR':
            return { ...state, ...{ status: 'failed' } }
        default:
            break;
    }

    return state;
}