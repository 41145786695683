import React, { FormEvent } from 'react';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Container, Row, Col, Image, Card, Placeholder, Tooltip, OverlayTrigger, Popover, DropdownButton, Dropdown } from 'react-bootstrap';
import { Contas, KPIRentabilidadeItem } from '../usuario/usuarioAPI';
import { BsPiggyBankFill, BsFillFilterSquareFill, BsQuestionCircle, BsArrowClockwise } from 'react-icons/bs';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { IconContext } from "react-icons";
import { NumericFormat } from 'react-number-format';
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import { ComposedChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip as TooltipChart, Legend, ResponsiveContainer, Rectangle, Customized } from 'recharts';

interface IndicadorDadosContaProps {
    Contas: Contas[],
    initialLoading: boolean,
    OnFilterHandle: (contas: Contas[]) => void;
    Rentabildades: KPIRentabilidadeItem[];
}
interface IndicadorDadosContaState {
    isLoading: boolean,
    isTooltipVisible: boolean;
    isPoperOverVisible: boolean;
    selectedAccount: Contas[];
    tourstart: boolean;
    indextourstart: number;
    selectContasTodos: boolean;
}

interface IDataItem {
    label: string,
    valor: number,
    rentabilidade: number,
    isProjection: boolean,
    meta: number,
    valorInvestido: number
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
export default class IndicadorDadosRentabilidade extends React.PureComponent<IndicadorDadosContaProps, IndicadorDadosContaState>{
    constructor(props: IndicadorDadosContaProps) {
        super(props)
        this.state = {
            isLoading: props.initialLoading,
            selectedAccount: props.Contas,
            isTooltipVisible: true,
            isPoperOverVisible: false,
            tourstart: false,
            indextourstart: 0,
            selectContasTodos: true
        }
        this.handleSetIsloading = this.handleSetIsloading.bind(this);
        this.handleSetSelectedAccount = this.handleSetSelectedAccount.bind(this);
    }

    handleSetIsloading = (v: boolean) => {
        this.setState({ isLoading: v });
    }

    handleSetSelectedAccount = (contas: Contas[]) => {
        this.setState({ selectedAccount: contas });
    }

    handleSubmitFiltroConta = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true, isPoperOverVisible: false });
        this.props.OnFilterHandle(this.state.selectedAccount);
    }
    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.props.Contas.find(a => a.id === event.target.value) as Contas;
        if (account != null && account != undefined) {
            if (this.state.selectedAccount?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedAccount: this.state.selectedAccount.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedAccount: [...this.state.selectedAccount, account]
                });
            }
        }
    };
    componentDidMount() {

    }
    render(): React.ReactNode {
        const meses = [
            'jan', 'fev', 'mar', 'abr', 'mai', 'jun',
            'jul', 'ago', 'set', 'out', 'nov', 'dez',
        ];
        const steps = [
            {
                element: '.kpi-rentabilidade',
                intro: 'Esse card contém informações resumidas de suas rentabilidades e histórico.'
            },
            {
                element: '.kpi-rentabilidade-lp',
                intro: 'Em Lucros/Prejuízo m0 (mês corrente), você verá a valor que rendeu bruto (sem dedução de I.R) e o percentual referente ao valor investido no mês.',
            }, {
                element: '.kpi-rentabilidade-grafico',
                intro: 'No gráfico você verá o histórico da rentabilidade bruta (sem dedução de I.R), confrontando o percentual rentabilidade x investido x meta',
            },
            {
                element: '.kpi-rentabilidade-filtro',
                intro: 'Aqui você poderá filtrar quais contas você deseja visualizar os dados.',
            }
            ,
            {
                element: '.kpi-rentabilidade-info',
                intro: 'Sempre que tiver alguma dúvida sobre informações do card, você pode clicar aqui e será explicado cada informação dentro do card.',
            },
            {
                element: '.kpi-rentabilidade-refresh',
                intro: 'Caso precise carregar as informações novamente, você poderá clicar aqui.',
            }
        ];
        const { selectedAccount, isLoading } = this.state;
        const popoverfiltrodadosconta = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">Filtros</Popover.Header>
                <Popover.Body>
                    <Form onSubmit={this.handleSubmitFiltroConta}>
                        <Row>
                            <Col >
                                <Form.Group controlId="selectConta">
                                    <Form.Label>Selecione a conta:</Form.Label>
                                    <DropdownButton title="Selecione as Contas" id="dropdown-contas" autoClose="outside" >
                                        <Dropdown.Item
                                            key="select-modelo-filtro"
                                            as="label"
                                            className="d-flex align-items-center"
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                id="select-modelo-filtro"
                                                label='Selecione Todos'
                                                value='select-modelo-filtro'
                                                checked={this.state.selectContasTodos}
                                                onChange={() => { this.setState({ selectedAccount: !this.state.selectContasTodos ? this.props.Contas : [], selectContasTodos: !this.state.selectContasTodos }) }}
                                            />
                                        </Dropdown.Item>
                                        {this.props.Contas.map(conta => (
                                            <Dropdown.Item
                                                key={conta.id}
                                                as="label"
                                                className="d-flex align-items-center"
                                            >
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`conta-${conta.id}`}
                                                    label={conta.nome}
                                                    value={conta.id}
                                                    checked={selectedAccount.includes(conta)}
                                                    onChange={this.handleAccountChange}
                                                />
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button className='mt-4'
                            variant="primary"
                            type="submit"
                            disabled={!selectedAccount || isLoading}
                        >
                            {isLoading ? "Pesquisando..." : "Pesquisar"}
                        </Button>
                    </Form>
                </Popover.Body>
            </Popover>
        );

        return (<><Steps
            enabled={this.state.tourstart}
            steps={steps}
            initialStep={this.state.indextourstart}
            onExit={() => { this.setState({ indextourstart: 0, tourstart: false }) }}
            options={{ nextLabel: "Próximo", prevLabel: "Anterior", doneLabel: "Fim" }}
        />


            <Card className="mb-2 shadow kpi-rentabilidade">
                <Card.Header style={{ backgroundColor: 'black', textAlign: 'center' }}>
                    <div style={{ color: 'white' }}><b>Dados de Rentabilidade</b>

                        <OverlayTrigger placement='auto'
                            overlay={
                                <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-rentabilidade-filtro`}>
                                    Filtros
                                </Tooltip>
                            } >
                            <div className='kpi-rentabilidade-filtro' style={{ float: 'right', cursor: 'pointer' }} >
                                <OverlayTrigger trigger="click" placement="auto" show={this.state.isPoperOverVisible && !this.state.isTooltipVisible} onToggle={(event) => { this.setState({ isTooltipVisible: !event, isPoperOverVisible: event }) }} overlay={popoverfiltrodadosconta}>
                                    <div>
                                        <BsFillFilterSquareFill />
                                    </div>
                                </OverlayTrigger>

                            </div>
                        </OverlayTrigger>
                        <OverlayTrigger placement='auto'
                            overlay={
                                <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-rentabilidade-info`}>
                                    Informações
                                </Tooltip>
                            } >
                            <div className='kpi-rentabilidade-info page-home-kpi-info me-3' onClick={() => { this.setState({ tourstart: true, indextourstart: 0 }) }} style={{ float: 'right', cursor: 'pointer' }} >
                                <div>
                                    <BsQuestionCircle />
                                </div>
                            </div>
                        </OverlayTrigger>
                        <OverlayTrigger placement='auto'
                            overlay={
                                <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-refresh`}>
                                    Atualizar
                                </Tooltip>
                            } >
                            <div className='kpi-rentabilidade-refresh me-3' onClick={() => {
                                this.setState({ isLoading: true, isPoperOverVisible: false });
                                this.props.OnFilterHandle(this.state.selectedAccount);
                            }} style={{ float: 'right', cursor: 'pointer' }} >
                                <div>
                                    <BsArrowClockwise />
                                </div>
                            </div>
                        </OverlayTrigger>
                    </div>
                </Card.Header>
                <Card.Body style={{ height: '240px', whiteSpace: 'nowrap' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <div style={{ verticalAlign: 'middle', justifyContent: 'center', display: 'flex', alignItems: 'center', paddingRight: '5px' }}>
                            <IconContext.Provider value={{ style: { verticalAlign: 'middle' }, size: '40px', color: 'green' }} >
                                <MdOutlineAttachMoney />
                            </IconContext.Provider>

                        </div>
                        <div>

                            <Card.Title className="m-2 kpi-rentabilidade-lp">
                                <div style={{ display: 'inline-flex' }}>
                                    <div style={{ display: 'inline-flex', color: 'gray', fontWeight: 'bold', marginRight: '2px' }}>L/P m0:</div>
                                    {(this.state.isLoading ?
                                        <Placeholder as={Card.Title} animation="glow" >
                                            <Placeholder xs={6} style={{ width: '100px' }} />
                                        </Placeholder> :
                                        <div style={{ fontWeight: 'bold' }}>
                                            <NumericFormat
                                                value={this.props.Rentabildades.length > 0 ? this.props.Rentabildades[this.props.Rentabildades.length - 1].valorLucroPrejuizo : 0} prefix='R$ ' decimalScale={2} decimalSeparator=',' thousandSeparator='.' displayType='text' />
                                            <span style={{ paddingLeft: '4px', color: this.props.Rentabildades.length > 0 && this.props.Rentabildades[this.props.Rentabildades.length - 1].percentualLucroPrejuizo < 0.00 ? 'red' : 'green' }}>({(this.props.Rentabildades.length > 0 ? this.props.Rentabildades[this.props.Rentabildades.length - 1].percentualLucroPrejuizo * 100.00 : 0).toLocaleString()}%)</span>
                                        </div>)}
                                    <span>
                                        <OverlayTrigger placement='auto'
                                            overlay={
                                                <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-rentabilidade-info`}>
                                                    {steps[1].intro}
                                                </Tooltip>
                                            } >
                                            <div style={{ cursor: 'pointer', fontSize: '0.85rem', color: 'blue', marginLeft: '5px' }} >
                                                <div>
                                                    <BsQuestionCircle />
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    </span>
                                </div>
                            </Card.Title>
                        </div>
                    </div>
                    <ResponsiveContainer className='kpi-rentabilidade-grafico' width="100%" height="80%">
                        
                            {this.state.isLoading ? <Placeholder animation="glow" >
                                <Placeholder xs={6} style={{ height: '100%', width: '100%' }} />
                            </Placeholder> :<ComposedChart width={500}
                                height={140} margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }} data={
                                    this.props.Rentabildades.map(rent => {
                                        var item: Partial<IDataItem> = {};
                                        item.label = meses[new Date(rent.competencia).getUTCMonth()] + "/" + new Date(rent.competencia).getUTCFullYear();
                                        item.meta = rent.percentualMeta;
                                        item.rentabilidade = rent.percentualLucroPrejuizo;
                                        item.valor = rent.valorLucroPrejuizo;
                                        item.valorInvestido = rent.valorInvestido;
                                        return item;
                                    })}
                            >
                                <XAxis dataKey='label' hide={true} />
                                <YAxis dataKey="valor" hide={true} />
                                <YAxis yAxisId="meta" dataKey="rentabilidade" orientation="right" hide={true} />
                                <TooltipChart content={({ active, payload, label }) => {
                                    if (active && payload && label) {
                                        return <div style={{ backgroundColor: 'white', padding: '5px' }}>
                                            <span><label style={{ marginRight: "3px", fontWeight: 'bold' }}>Competência:</label>{label}</span><br />
                                            <span><label style={{ marginRight: "3px", fontWeight: 'bold' }}>Meta:</label>{(payload[0].payload.meta * 100.00).toLocaleString()} %</span><br />
                                            <span><label style={{ marginRight: "3px", fontWeight: 'bold' }}>Valor Investido:</label>{
                                                new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(payload[0].payload.valorInvestido)
                                            }</span><br />
                                            <span><label style={{ marginRight: "3px", fontWeight: 'bold' }}>Valor:</label>{
                                                new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(payload[0].payload.valor)
                                            }</span><br />
                                            <span><label style={{ marginRight: "3px", fontWeight: 'bold' }}>Rentabilidade:</label>{(payload[0].payload.rentabilidade * 100.00).toLocaleString()} %</span><br />
                                        </div>
                                    }

                                }} />
                                <Bar dataKey="valor">
                                    {
                                        this.props.Rentabildades.map((entry: KPIRentabilidadeItem, index) => (
                                            <Cell key={`cell-${index}`} fill={'green'} />
                                        ))
                                    }

                                </Bar>
                                <Line yAxisId="meta" type="linear" dataKey="meta" stroke="#ff7300" />
                                <Line yAxisId="meta" type="monotone" dataKey="rentabilidade" stroke="blue" />
                            </ComposedChart>}
                            
                    </ResponsiveContainer>
                </Card.Body>
            </Card></>
        )
    }
}