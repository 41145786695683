import React, { ChangeEvent } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';

interface CurrencyInputProps {
    value: number;
    onChange?: (value: number) => void;
    readonly?: boolean ;
  }
  export const CurrencyInput: React.FC<CurrencyInputProps> = ({ value, onChange = (value)=>{}, readonly = false } : CurrencyInputProps) => {
    
    const [inputValue, setInputValue] = React.useState<string>(
      value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    );
  
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        //setInputValue(event.target.value);
        const parsedValue = parseFloat(event.target.value.replace('.','').replace(',','.').replace(/[^\d.-]/g, ''));
        onChange(parsedValue);
        //setInputValue(parsedValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
        setInputValue(event.target.value);
      };
      
      const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        //if(inputValue != event.target.value){
            console.log(inputValue)
            console.log(event.target.value)
            const parsedValue = parseFloat(inputValue.replace('.','').replace(',','.').replace(/[^\d.-]/g, ''));
            onChange(parsedValue);
            setInputValue(parsedValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
        //}
      };
      
    return (
      <InputGroup>
        <FormControl
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          aria-label="Valor em reais (R$)"
        />
      </InputGroup>
    );
  };
  