import React, { FormEvent } from 'react';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Container, Row, Col, Image, Card, Placeholder, Tooltip, OverlayTrigger, Popover, DropdownButton, Dropdown } from 'react-bootstrap';
import { Contas } from '../usuario/usuarioAPI';
import { BsPiggyBankFill, BsFillFilterSquareFill, BsQuestionCircle, BsArrowClockwise } from 'react-icons/bs';
import { IconContext } from "react-icons";
import { NumericFormat } from 'react-number-format';
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';

interface IndicadorDadosContaProps {
    Contas: Contas[],
    initialLoading: boolean,
    aportes: number | undefined,
    bens: number | undefined,
    lucroPrejuizo: number | undefined,
    saldo: number | undefined,
    OnFilterHandle: (contas: Contas[]) => void;
}
interface IndicadorDadosContaState {
    isLoading: boolean,
    isTooltipVisible: boolean;
    isPoperOverVisible: boolean;
    selectedAccount: Contas[];
    tourstart: boolean;
    indextourstart: number;
    selectContasTodos: boolean;
}
type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
export default class IndicadorDadosConta extends React.PureComponent<IndicadorDadosContaProps, IndicadorDadosContaState>{
    constructor(props: IndicadorDadosContaProps) {
        super(props)
        this.state = {
            isLoading: props.initialLoading,
            selectedAccount: props.Contas,
            isTooltipVisible: true,
            isPoperOverVisible: false,
            tourstart: false,
            indextourstart: 0,
            selectContasTodos: true
        }
        this.handleSetIsloading = this.handleSetIsloading.bind(this);
        this.handleSetSelectedAccount = this.handleSetSelectedAccount.bind(this);
    }

    handleSetIsloading = (v: boolean) => {
        this.setState({ isLoading: v });
    }

    handleSetSelectedAccount = (contas: Contas[]) => {
        this.setState({ selectedAccount: contas });
    }

    handleSubmitFiltroConta = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true, isPoperOverVisible: false });
        this.props.OnFilterHandle(this.state.selectedAccount);
    }
    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.props.Contas.find(a => a.id === event.target.value) as Contas;
        if (account != null && account != undefined) {
            if (this.state.selectedAccount?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedAccount: this.state.selectedAccount.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedAccount: [...this.state.selectedAccount, account]
                });
            }
        }
    };
    componentDidMount() {

    }
    render(): React.ReactNode {
        const steps = [
            {
                element: '.kpi-dadosconta',
                intro: 'Esse card contém informações resumidas das contas correntes que você possui.'
            },
            {
                element: '.kpi-dadosconta-aportes',
                intro: 'Em Aportes, você verá o somatório de capital que você dedicou para investimento',
            },
            {
                element: '.kpi-dadosconta-lp',
                intro: 'Em Lucros/Prejuízo, você verá o acumulado de lucro ou prejuízo de suas contas.',
            }, {
                element: '.kpi-dadosconta-bens',
                intro: 'Em bens, você verá o quanto do seu capital está investido em bens ou ações, já não mais disponível em sua conta corrente como dinheiro, mas sim em bens.',
            },
            {
                element: '.kpi-dadosconta-saldo',
                intro: 'Aqui você verá o saldo disponível em dinheiro em sua conta corrente.',
            },
            {
                element: '.kpi-dadosconta-filtro',
                intro: 'Aqui você poderá filtrar quais contas você deseja visualizar os dados.',
            }
            ,
            {
                element: '.kpi-dadosconta-info',
                intro: 'Sempre que tiver alguma dúvida sobre informações do card, você pode clicar aqui e será explicado cada informação dentro do card.',
            },
            {
                element: '.kpi-dadosconta-refresh',
                intro: 'Caso precise carregar as informações novamente, você poderá clicar aqui.',
            }
        ];
        const { selectedAccount, isLoading } = this.state;
        const popoverfiltrodadosconta = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">Filtros</Popover.Header>
                <Popover.Body>
                    <Form onSubmit={this.handleSubmitFiltroConta}>
                        <Row>
                            <Col >
                                <Form.Group controlId="selectConta">
                                    <Form.Label>Selecione a conta:</Form.Label>
                                    <DropdownButton title="Selecione as Contas" id="dropdown-contas" autoClose="outside" >
                                        <Dropdown.Item
                                            key="select-modelo-filtro"
                                            as="label"
                                            className="d-flex align-items-center"
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                id="select-modelo-filtro"
                                                label='Selecione Todos'
                                                value='select-modelo-filtro'
                                                checked={this.state.selectContasTodos}
                                                onChange={() => { this.setState({ selectedAccount: !this.state.selectContasTodos ? this.props.Contas : [], selectContasTodos: !this.state.selectContasTodos }) }}
                                            />
                                        </Dropdown.Item>
                                        {this.props.Contas.map(conta => (
                                            <Dropdown.Item
                                                key={conta.id}
                                                as="label"
                                                className="d-flex align-items-center"
                                            >
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`conta-${conta.id}`}
                                                    label={conta.nome}
                                                    value={conta.id}
                                                    checked={selectedAccount.includes(conta)}
                                                    onChange={this.handleAccountChange}
                                                />
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button className='mt-4'
                            variant="primary"
                            type="submit"
                            disabled={!selectedAccount || isLoading}
                        >
                            {isLoading ? "Pesquisando..." : "Pesquisar"}
                        </Button>
                    </Form>
                </Popover.Body>
            </Popover>
        );

        return (
            <> <Steps
                enabled={this.state.tourstart}
                steps={steps}
                initialStep={this.state.indextourstart}
                onExit={() => { this.setState({ indextourstart: 0, tourstart: false }) }}
                options={{ nextLabel: "Próximo", prevLabel: "Anterior", doneLabel: "Fim" }}
            />
                <Card className="mb-2 shadow kpi-dadosconta">
                    <Card.Header style={{ backgroundColor: 'black', textAlign: 'center' }}>
                        <div style={{ color: 'white' }}><b>Dados de Conta</b>

                            <OverlayTrigger placement='auto'
                                overlay={
                                    <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-filtro`}>
                                        Filtros
                                    </Tooltip>
                                } >
                                <div className='kpi-dadosconta-filtro' style={{ float: 'right', cursor: 'pointer' }} >
                                    <OverlayTrigger trigger="click" placement="auto" show={this.state.isPoperOverVisible && !this.state.isTooltipVisible} onToggle={(event) => { this.setState({ isTooltipVisible: !event, isPoperOverVisible: event }) }} overlay={popoverfiltrodadosconta}>
                                        <div>
                                            <BsFillFilterSquareFill />
                                        </div>
                                    </OverlayTrigger>

                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger placement='auto'
                                overlay={
                                    <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-info`}>
                                        Informações
                                    </Tooltip>
                                } >
                                <div className='kpi-dadosconta-info me-3' onClick={() => { this.setState({ tourstart: true, indextourstart: 0 }) }} style={{ float: 'right', cursor: 'pointer' }} >
                                    <div>
                                        <BsQuestionCircle />
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger placement='auto'
                                overlay={
                                    <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-refresh`}>
                                        Atualizar
                                    </Tooltip>
                                } >
                                <div className='kpi-dadosconta-refresh me-3' onClick={() => {
                                    this.setState({ isLoading: true, isPoperOverVisible: false });
                                    this.props.OnFilterHandle(this.state.selectedAccount);
                                }} style={{ float: 'right', cursor: 'pointer' }} >
                                    <div>
                                        <BsArrowClockwise />
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </div>
                    </Card.Header>
                    <Card.Body style={{ height: '200px', whiteSpace: 'nowrap' }}>
                        <div style={{ display: 'inline-flex' }}>
                            <div style={{ verticalAlign: 'middle', justifyContent: 'center', display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
                                <IconContext.Provider value={{ style: { verticalAlign: 'middle' }, size: '50px', color: 'orange' }} >
                                    <BsPiggyBankFill />
                                </IconContext.Provider>

                            </div>
                            <div>

                                <Card.Title className="m-3 kpi-dadosconta-aportes">
                                    <div style={{ display: 'inline-flex' }}>
                                        <div style={{ display: 'inline-flex', color: 'gray', fontWeight: 'bold', marginRight: '2px' }}>Aportes:

                                        </div>
                                        {(this.state.isLoading ?
                                            <Placeholder as={Card.Title} animation="glow" >
                                                <Placeholder xs={6} style={{ width: '100px' }} />
                                            </Placeholder> :
                                            <div style={{ fontWeight: 'bold' }}><NumericFormat value={this.props.aportes} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></div>)}
                                        <span>
                                            <OverlayTrigger placement='auto'
                                                overlay={
                                                    <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-info`}>
                                                        {steps[1].intro}
                                                    </Tooltip>
                                                } >
                                                <div style={{ cursor: 'pointer', fontSize: '0.85rem', color: 'blue', marginLeft: '5px' }} >
                                                    <div>
                                                        <BsQuestionCircle />
                                                    </div>
                                                </div>
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                </Card.Title>
                                <Card.Title className="m-3 kpi-dadosconta-lp">
                                    <div style={{ display: 'inline-flex' }}>
                                        <div style={{ display: 'inline-flex', color: 'gray', fontWeight: 'bold', marginRight: '2px' }}>L/P: </div>
                                        {(this.state.isLoading ?
                                            <Placeholder as={Card.Title} animation="glow" >
                                                <Placeholder xs={6} style={{ width: '100px' }} />
                                            </Placeholder> :
                                            <div style={{ fontWeight: 'bold' }}> <NumericFormat value={this.props.lucroPrejuizo} prefix='R$ ' decimalScale={2} decimalSeparator=',' thousandSeparator='.' displayType='text' /></div>)}
                                        <span>
                                            <OverlayTrigger placement='auto'
                                                overlay={
                                                    <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-info`}>
                                                        {steps[2].intro}
                                                    </Tooltip>
                                                } >
                                                <div style={{ cursor: 'pointer', fontSize: '0.85rem', color: 'blue', marginLeft: '5px' }} >
                                                    <div>
                                                        <BsQuestionCircle />
                                                    </div>
                                                </div>
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                </Card.Title>
                                <Card.Title className="m-3 kpi-dadosconta-bens">
                                    <div style={{ display: 'inline-flex' }}>
                                        <div style={{ display: 'inline-flex', color: 'gray', fontWeight: 'bold', marginRight: '2px' }}>Bens:</div>
                                        {(this.state.isLoading ?
                                            <Placeholder as={Card.Title} animation="glow" >
                                                <Placeholder xs={6} style={{ width: '100px' }} />
                                            </Placeholder> :
                                            <div style={{ fontWeight: 'bold' }}> <NumericFormat value={Math.abs(this.props.bens as number)} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></div>)}
                                        <span>
                                            <OverlayTrigger placement='auto'
                                                overlay={
                                                    <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-info`}>
                                                        {steps[3].intro}
                                                    </Tooltip>
                                                } >
                                                <div style={{ cursor: 'pointer', fontSize: '0.85rem', color: 'blue', marginLeft: '5px' }} >
                                                    <div>
                                                        <BsQuestionCircle />
                                                    </div>
                                                </div>
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                </Card.Title>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer className='kpi-dadosconta-saldo'>
                        <div style={{ display: 'inline-flex' }}>
                            <div style={{ display: 'inline-flex', color: 'gray', fontWeight: 'bold', marginRight: '2px' }}>Saldo:</div>
                            {(this.state.isLoading ?
                                <Placeholder animation="glow" >
                                    <Placeholder xs={6} style={{ width: '100px' }} />
                                </Placeholder> :
                                <div style={{ fontWeight: 'bold' }}> <NumericFormat value={this.props.saldo} prefix='R$ ' decimalSeparator=',' thousandSeparator='.' displayType='text' /></div>)}
                            <span>
                                <OverlayTrigger placement='auto'
                                    overlay={
                                        <Tooltip hidden={!this.state.isTooltipVisible} id={`tooltip-dadosconta-info`}>
                                            {steps[4].intro}
                                        </Tooltip>
                                    } >
                                    <div style={{ cursor: 'pointer', fontSize: '0.85rem', color: 'blue', marginLeft: '5px' }} >
                                        <div>
                                            <BsQuestionCircle />
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            </span>
                        </div>
                    </Card.Footer>
                </Card></>
        )
    }
}