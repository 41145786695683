import { AccountInfo, IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { LancamentoConta, ListarLancamentoContaRequest, getListarLancamentoConta, postAdicionarLancamentoConta, putAtualizarLancamentoConta } from "../../features/lancamentosConta/lancamentoContaAPI";
import { apiRequest, loginRequest } from "../../authConfig";
import { DefaultConnection } from "../../features/connection/connectionAPI";
import { AppThunkAction } from "..";
import { Action, Reducer } from 'redux';
import { appInsights } from "../..";
import { ResponsePagination } from "../../features/communs";



export interface LancamentoContaState {
    lancamentosGrid: Partial<ResponsePagination<LancamentoConta>> | undefined;
    lancamentoFiltro: ListarLancamentoContaRequest | undefined;
    status: 'idle' | 'loading' | 'failed';
}

const unloadedState: LancamentoContaState = {
    lancamentosGrid: undefined,
    lancamentoFiltro: undefined,
    status: 'idle',
};
interface RequestGetListarLancamentosAction {
    type: 'REQUEST_GET_LISTAR_LANCAMENTOS';
}

interface ReceiveGetListarLancamentosAction {
    type: 'RECEIVE_GET_LISTAR_LANCAMENTOS';
    response: ResponsePagination<LancamentoConta>;
}
interface RequestAdicionarLancamentosAction {
    type: 'REQUEST_POST_ADICIONAR_LANCAMENTOS';
}

interface ReceiveAdiconarLancamentosAction {
    type: 'RECEIVE_POST_ADICIONAR_LANCAMENTOS';
    response: LancamentoConta;
}
interface RequestAtualizarLancamentosAction {
    type: 'REQUEST_PUT_ATUALIZAR_LANCAMENTOS';
}

interface ReceiveAtualizarLancamentosAction {
    type: 'RECEIVE_PUT_ATUALIZAR_LANCAMENTOS';
    response: LancamentoConta;
}

interface ReceiveErrorNotasAction {
    type: 'RECEIVE_POST_ERROR';
}
type KnownAction = RequestAtualizarLancamentosAction | ReceiveAtualizarLancamentosAction | RequestGetListarLancamentosAction | ReceiveGetListarLancamentosAction | ReceiveErrorNotasAction | RequestAdicionarLancamentosAction | ReceiveAdiconarLancamentosAction;

export const actionCreators = {
    listarLancamentosContaAsync: (request: ListarLancamentoContaRequest, instance: IPublicClientApplication,
        callback: (response: ResponsePagination<LancamentoConta>) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:getUsuarioSSOAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.lancamentoContaState && appState.lancamentoContaState.status !== "loading") {
                    dispatch({ type: 'REQUEST_GET_LISTAR_LANCAMENTOS' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({name:'REQUEST_GET_LISTAR_LANCAMENTOS'}, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username});
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    actionCreators.listarLancamentosContaAsync(request, instance, callback);
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await getListarLancamentoConta(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_GET_LISTAR_LANCAMENTOS', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao lista de lançamentos:' + JSON.stringify(error));
            }

        },
    adicionarLancamentosContaAsync: (request: LancamentoConta, instance: IPublicClientApplication,
        callback: (response: LancamentoConta) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:adicionarLancamentosContaAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.lancamentoContaState && appState.lancamentoContaState.status !== "loading") {
                    dispatch({ type: 'REQUEST_POST_ADICIONAR_LANCAMENTOS' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({name:'REQUEST_POST_ADICIONAR_LANCAMENTOS'}, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username});
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch(async (error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            await instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await postAdicionarLancamentoConta(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_POST_ADICIONAR_LANCAMENTOS', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        },
    atualizarLancamentosContaAsync: (request: LancamentoConta, instance: IPublicClientApplication,
        callback: (response: LancamentoConta) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:atualizarLancamentosContaAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.lancamentoContaState && appState.lancamentoContaState.status !== "loading") {
                    dispatch({ type: 'REQUEST_PUT_ATUALIZAR_LANCAMENTOS' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({name:'REQUEST_PUT_ATUALIZAR_LANCAMENTOS'}, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username});
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch(async (error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            await instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await putAtualizarLancamentoConta(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_PUT_ATUALIZAR_LANCAMENTOS', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        }
}


export const reducer: Reducer<LancamentoContaState> = (state: LancamentoContaState | undefined, incomingAction: Action): LancamentoContaState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_GET_LISTAR_LANCAMENTOS':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_GET_LISTAR_LANCAMENTOS':
            return { ...state, ...{ status: 'idle', lancamentosGrid: action.response } }
        case 'REQUEST_POST_ADICIONAR_LANCAMENTOS':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_POST_ADICIONAR_LANCAMENTOS':
            state.lancamentosGrid?.result?.push(action.response);
            return { ...state, ...{ status: 'idle', lancamentosGrid:state.lancamentosGrid }}
        case 'REQUEST_PUT_ATUALIZAR_LANCAMENTOS':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_PUT_ATUALIZAR_LANCAMENTOS':
            return { ...state, ...{ status: 'idle', lancamentosGrid: {
                ...state.lancamentosGrid, 
                ...{result:state.lancamentosGrid?.result?.map((l :LancamentoConta) =>{ 
                    if(l.id == action.response.id){ 
                        return action.response; 
                    }else{ 
                        return l; 
                    } })} } }}
        case 'RECEIVE_POST_ERROR':
            return { ...state, ...{ status: 'failed' } }
        default:
            break;
    }

    return state;
}