import React, { Component, FormEvent } from 'react';
import { useMsal, MsalContext, withMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Layout from '../layout/Layout';
import { CustomNavigationClient } from '../../utils/NavigationClient';
import * as UsuarioStore from '../../store/usuario/usuarioStore';
import * as ImportacaoNotaStore from '../../store/importacaoNota/importacaoNotaStore';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { IPublicClientApplication } from "@azure/msal-browser";
import { Contas } from '../usuario/usuarioAPI';
import { Button, Form, Pagination } from "react-bootstrap";
import GridProcessamentoNota from './gridProcessamentoNotas';
import { ProcessamentoNotasCorretagem } from './importarNotasAPI';
import { ResponsePagination } from '../communs';

type Props = {
    navigation: CustomNavigationClient,
    window?: () => Window,
    instance: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators & ImportacaoNotaStore.ImportacaoNotaState & typeof ImportacaoNotaStore.actionCreators;
type ImportarNotasState = {
    prevState: boolean;
    selectedAccount: Contas | undefined;
    selectedFile: FileList | undefined;
    Contas: Contas[];
    isLoading: boolean;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
class ImportarNotas extends React.Component<Props, ImportarNotasState> {
    constructor(props: any) {
        super(props)
        this.state = {
            selectedAccount: undefined,
            selectedFile: undefined,
            prevState: false,
            Contas: [],
            isLoading: false
        };

    }

    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        const account = this.state.Contas.find(a => a.id === event.target.value);
        this.setState({ selectedAccount: account });
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files;
        if (file) {
            this.setState({ selectedFile: file });
        }
    };

    handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        var formData = new FormData();
        formData.append("contaId", this.state.selectedAccount?.id as string);
        var len = this.state.selectedFile?.length as number;
        for (let index = 0; index < len; index++) {
            const element = this.state.selectedFile?.item(index) as Blob;
            formData.append("Files" + index, element);
        }

        this.props.postProcessarNotasAsync(this.props.instance, formData, (response) => {
            this.setState({ selectedFile: undefined });
            var input = document.getElementById("fileUpload") as HTMLInputElement;
            input.value = '';
            alert('Notas processadas com sucesso');
            this.props.ListarPendenteAprovacaoAsync({ contasId: this.props.contas?.map(c => c.id as string), pagina: 1, tamanhoPagina: 15 }, this.props.instance, (resultResponse) => {
                this.setState({ isLoading: false });
            });
            //this.setState({ isLoading: false });
            // this.props.GerarLancamentosContaAsync({ contaId: this.state.selectedAccount?.id as string, notas: response, pagina: 1, tamanhoPagina: 15 }, this.props.instance, () => {
            //     this.setState({ isLoading: false });
            // });

        });
        // fetch("http://localhost:7062/api/processarnotas",{
        //     method : 'POST',
        //     body: formData
        // })
        // processamento de arquivo aqui...


    };

    componentDidMount(): void {
        this.setState({ isLoading: true });
        if (this.props.contas == undefined || this.props.contas.length == 0) {
            this.props.getContasAsync(this.props.instance, (response) => {
                this.setState({ Contas: response.contas });
                this.props.ListarPendenteAprovacaoAsync({ contasId: response.contas.map(c => c.id), pagina: 1, tamanhoPagina: 15 }, this.props.instance, (resultResponse) => {
                    this.setState({ isLoading: false });
                });
            });
        } else {
            this.setState({ Contas: this.props.contas as Contas[] });
            this.props.ListarPendenteAprovacaoAsync({ contasId: this.props.contas?.map(c => c.id as string), pagina: 1, tamanhoPagina: 15 }, this.props.instance, (resultResponse) => {
                this.setState({ isLoading: false });
            });
        }

    }
    render(): React.ReactNode {
        const { selectedAccount, selectedFile, isLoading } = this.state;
        return <AuthenticatedTemplate>
            <Layout navigation={this.props.navigation} isLoading={this.state.isLoading} permissoes={this.props.usuario?.permissoes}>
                <div className="container mt-5">
                    <h1>Processamento de Nota de corretagem</h1>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="selectConta">
                            <Form.Label>Selecione a conta:</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedAccount?.id}
                                onChange={this.handleAccountChange}
                            >
                                <option value="">Selecione...</option>
                                {this.state.Contas.map(conta =>
                                    <option key={conta.id} id={conta.id} value={conta.id}>{conta.nome}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="fileUpload">
                            <Form.Label>Selecione os arquivos (PDF):</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".pdf"
                                multiple
                                onChange={this.handleFileChange}
                            />
                        </Form.Group>
                        <Button className='mt-4 mb-3'
                            variant="primary"
                            type="submit"
                            disabled={!selectedAccount || !selectedFile || isLoading}
                        >
                            {isLoading ? "Processando..." : "Processar"}
                        </Button>
                    </Form>
                    <GridProcessamentoNota onApprove={(processamento, callback) => {
                        this.setState({ isLoading: true });
                        this.props.AprovarProcessamentoAsync([processamento], this.props.instance, (response) => {
                            callback()
                            this.props.ListarPendenteAprovacaoAsync({
                                contasId: this.state.Contas.map(c => c.id),
                                pagina: 1,
                                tamanhoPagina: this.props.processamentoNotaResponse?.tamanhoPagina
                            }, this.props.instance, (resultResponse) => {
                                this.setState({ isLoading: false });
                            });
                        });
                    }} onCancel={(processamento, callback) => {
                        this.setState({ isLoading: true });
                        this.props.RejeitarProcessamentoAsync([processamento], this.props.instance, (response) => {
                            callback()
                            this.props.ListarPendenteAprovacaoAsync({
                                contasId: this.state.Contas.map(c => c.id),
                                pagina: 1,
                                tamanhoPagina: this.props.processamentoNotaResponse?.tamanhoPagina
                            }, this.props.instance, (resultResponse) => {
                                this.setState({ isLoading: false });
                            });
                        });
                    }} responsePagination={this.props.processamentoNotaResponse as ResponsePagination<ProcessamentoNotasCorretagem>} />
                    <Pagination>
                        <Pagination.Prev
                            disabled={this.props.processamentoNotaResponse?.pagina === 1}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.ListarPendenteAprovacaoAsync({
                                    contasId: this.state.Contas.map(c => c.id),
                                    pagina: this.props.processamentoNotaResponse?.pagina as number - 1,
                                    tamanhoPagina: this.props.processamentoNotaResponse?.tamanhoPagina
                                }, this.props.instance, (resultResponse) => {
                                    this.setState({ isLoading: false });
                                });
                            }}
                        />

                        {[(new Array(this.props.processamentoNotaResponse?.totalPagina as number)).keys()].map((v, page) => (
                            <Pagination.Item
                                key={page + 1}
                                active={page + 1 === this.props.processamentoNotaResponse?.pagina}
                                onClick={() => {
                                    this.setState({ isLoading: true });
                                    this.props.ListarPendenteAprovacaoAsync({
                                        contasId: this.state.Contas.map(c => c.id),
                                        pagina: page + 1,
                                        tamanhoPagina: this.props.processamentoNotaResponse?.tamanhoPagina
                                    }, this.props.instance, (resultResponse) => {
                                        this.setState({ isLoading: false });
                                    });
                                }}
                            >
                                {page + 1}
                            </Pagination.Item>
                        ))}

                        <Pagination.Next
                            disabled={this.props.processamentoNotaResponse?.pagina === this.props.processamentoNotaResponse?.totalPagina}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.ListarPendenteAprovacaoAsync({
                                    contasId: this.state.Contas.map(c => c.id),
                                    pagina: this.props.processamentoNotaResponse?.pagina as number + 1,
                                    tamanhoPagina: this.props.processamentoNotaResponse?.tamanhoPagina
                                }, this.props.instance, (resultResponse) => {
                                    this.setState({ isLoading: false });
                                });
                            }}
                        />
                    </Pagination>
                </div>

            </Layout>
        </AuthenticatedTemplate>
    }
}


export default connect(
    (state: ApplicationState) => ({
        ...state.usuarioState, ...state.importacaoNotaState
    }),
    { ...UsuarioStore.actionCreators, ...ImportacaoNotaStore.actionCreators })(ImportarNotas);