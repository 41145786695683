import React, { Component } from 'react';
import { Container, Row, Col, Button, Table, Collapse, Form, Modal, DropdownButton, Dropdown, FormFloating, Pagination } from 'react-bootstrap';
import { Contas } from '../usuario/usuarioAPI';
import 'intl';
import 'intl/locale-data/jsonp/pt-BR';
import { CurrencyInput } from '../ui/CurrencyInput';
import { Ativo } from '../importarNotas/importarNotasAPI';
import {  TipoLancamento } from '../lancamentosConta/lancamentoContaAPI';
import { tiposAtivos } from './ativoAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faPen, faX } from "@fortawesome/free-solid-svg-icons";
import { Produto, ResponsePagination } from '../communs';

interface GridAtivoState {
    showModal: boolean;
    isEdit: boolean;
    novoItem: Partial<Ativo>;

}

interface GridAtivoProps {
    itensResponse: ResponsePagination<Ativo>;
    contas: Contas[];
    addHandle: (ativo: Ativo, callback: (ativo: Ativo) => void) => void;
    updateHandle: (ativo: Ativo, callback: (ativo: Ativo) => void) => void;
    onExcluirAtivo: (ativo: Ativo) => void;
    permissoes: string[] | undefined;
}

class GridAtivo extends Component<GridAtivoProps, GridAtivoState> {
    constructor(props: GridAtivoProps) {
        super(props);
        this.state = {
            showModal: false,
            novoItem: {
                dataCompra: new Date().toISOString().split('T')[0],
                precoCompra: 0,
                quantidade: 0,
                valorTotal: 0
            },
            isEdit: false
        };
    }

    handleOpenModal = (isEdit: boolean) => {
        this.setState({ showModal: true, isEdit: isEdit });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleAddItem = () => {
        const { novoItem } = this.state;
        // Adicionar novo item à lista de itens
        this.setState({ isEdit: false });
        this.props.addHandle(novoItem as Ativo, (ativo) => {
            this.handleCloseModal();
        });

    };

    handleEditItem = (item: Ativo) => {
        // Abrir o modal para editar o item
        this.setState({ novoItem: item, showModal: true, isEdit: true });
    };

    handleUpdateItem = () => {
        const { novoItem } = this.state;
        this.props.updateHandle(novoItem as Ativo, (ativo) => {
            this.handleCloseModal();
        });
    };

    handleDeleteItem = (lancamentoId: number) => {

        // Excluir o item da lista de itens
        //const updatedItems = items.filter(item => item.id !== itemId);
        //this.setState({ items: updatedItems });
    };

    render() {
        const { showModal, novoItem, isEdit } = this.state;

        return (
            <Container>
                <Row>
                    <Col md={{ offset: 12 }} >
                        <div className='mt-2 mb-2' style={{ float: 'right' }}>
                            <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => {
                                this.setState({ isEdit: false, novoItem: { ...this.state.novoItem, id: undefined } });
                                this.handleOpenModal(false)
                            }}>
                                Adicionar Novo Ativo
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Conta</th>
                                <th>Codigo</th>
                                <th>Descricao</th>
                                <th>Tipo</th>
                                <th>Data</th>
                                <th>Quantidade</th>
                                <th>Preço</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.itensResponse?.result?.map((ativo: Ativo) => (
                                <tr key={ativo.id}>
                                    <td>{ativo.conta.nome}</td>
                                    <td>{ativo.produto.codigo}</td>
                                    <td>{ativo.produto.nome}</td>
                                    <td>{ativo.tipoAtivo}</td>
                                    <td>{(new Date(ativo.dataCompra)).toLocaleDateString('pt-BR')}</td>
                                    <td>{ativo.quantidade}</td>
                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(ativo.precoCompra)}</td>
                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(ativo.valorTotal)}</td>
                                    <td>
                                        <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => {
                                            this.setState({ novoItem: ativo, showModal: true, isEdit: true });
                                        }}>
                                            <FontAwesomeIcon icon={faPen} />
                                        </Button>{" "}
                                        <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="danger" onClick={() => this.props.onExcluirAtivo(ativo)}>
                                            <FontAwesomeIcon icon={faX} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Total Geral</th>
                                <th colSpan={7} align='right' style={{ 'alignContent': 'flex-end' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.props.itensResponse?.result?.map((v: Ativo) => v.valorTotal).reduce((acc: number, current: number) => acc + current, 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>

                </Row>

                <Modal show={showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{novoItem.id ? 'Editar Item' : 'Adicionar Novo Item'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group hidden={!isEdit} controlId="id">
                                <Form.Label>ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    disabled
                                    value={novoItem.id}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="dataLancamento">
                                <Form.Label>Data</Form.Label>
                                <Form.Control type="date" value={novoItem.dataCompra?.split('T')[0]} onChange={(event) => this.setState({ novoItem: { ...novoItem, ...{ dataCompra: new Date(event.target.value).toISOString().split('T')[0] } } })} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="selectConta">
                                <Form.Label>Selecione a conta:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={novoItem.contaId}
                                    onChange={(event) => { this.setState({ novoItem: { ...novoItem, ...{ contaId: event.target.value, conta: this.props.contas.find((v) => v.id == event.target.value) } } }) }}
                                >
                                    <option value="">Selecione...</option>
                                    {this.props.contas.map(conta =>
                                        <option key={conta.id} id={conta.id} value={conta.id}>{conta.nome}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="selectTipoLancamento">
                                <Form.Label>Selecione a tipo Ativo:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={novoItem.tipoAtivo}
                                    onChange={(event) => {
                                        this.setState({
                                            novoItem: {
                                                ...novoItem, ...{
                                                    tipoAtivo: event.target.value
                                                }
                                            }
                                        })
                                    }}
                                >
                                    <option value="">Selecione...</option>
                                    {tiposAtivos.map(tipoAtivo =>
                                        <option key={tipoAtivo.id} id={tipoAtivo.id} value={tipoAtivo.id}>{tipoAtivo.nome}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="produtoCodigo">
                                <Form.Label>Código Produto</Form.Label>
                                <Form.Control as="input" value={novoItem.produto?.codigo} onChange={(event) => {
                                    this.setState({
                                        novoItem: {
                                            ...novoItem,
                                            ...{
                                                produto: {
                                                    ...novoItem.produto as Produto,
                                                    ...{ codigo: event.target.value }
                                                }
                                            }
                                        }
                                    })
                                }}
                                />
                            </Form.Group>
                            <Form.Group controlId="produtoDescricao">
                                <Form.Label>Produto Descrição</Form.Label>
                                <Form.Control as="input" value={novoItem.produto?.nome} onChange={(event) => {
                                    this.setState({
                                        novoItem: {
                                            ...novoItem,
                                            ...{
                                                produto: {
                                                    ...novoItem.produto as Produto,
                                                    ...{ nome: event.target.value }
                                                }
                                            }
                                        }
                                    })
                                }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="PrecoCompra">
                                <Form.Label>Preço</Form.Label>
                                <CurrencyInput value={this.state.novoItem.precoCompra as number} onChange={(nv) => this.setState({ novoItem: { ...novoItem, ...{ precoCompra: nv, valorTotal: nv * (novoItem.quantidade as number) } } })} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="quantidade">
                                <Form.Label>Quantidade</Form.Label>
                                <Form.Control type="number" value={novoItem.quantidade} onBlur={(event) => {
                                    this.setState({
                                        novoItem: {
                                            ...novoItem,
                                            ...{ quantidade: Number.parseInt(event.target.value), valorTotal: (novoItem.precoCompra as number) * Number.parseInt(event.target.value) }
                                        }
                                    }, () => { console.log(this.state) })
                                }} onChange={(event) => {
                                    this.setState({
                                        novoItem: {
                                            ...novoItem,
                                            ...{ quantidade: Number.parseInt(event.target.value), valorTotal: (novoItem.precoCompra as number) * Number.parseInt(event.target.value) }
                                        }
                                    }, () => { console.log(this.state) })
                                }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mt-2" controlId="valorTotal">
                                <Form.Label>Total: {this.state.novoItem.valorTotal?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Form.Label>
                                {/* <CurrencyInput readonly={true} value={this.state.novoItem.valorTotal as number} /> */}
                            </Form.Group>
                        </Form>
                        {/* export interface Ativo {
    id: string;
    contaId: string;
    dataCompra: string;
    quantidade: number;
    precoCompra: number;
    tipoAtivo: string;
    produto: Produto;
    valorTotal: number;
    conta: Contas;
} */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button
                            variant={novoItem.id ? 'warning' : 'success'}
                            onClick={novoItem.id ? this.handleUpdateItem : this.handleAddItem}
                        >
                            {novoItem.id ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        );
    }
}

export default GridAtivo;