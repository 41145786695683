import ConnectionAPI from "../connection/connectionAPI";
import { Contrato } from "../contratos/contratoAPI";

export interface IUsuarioSSORequest {
    authority: string,
    username: string,
    uniqueId: string
}

export interface LoginSSO {
    authority: string;
    username: string;
    uniqueId: string;
    status: StatusLoingSSO;
}

export enum StatusLoingSSO {
    Ativo,
    Inativo
}

export interface ChatTelegram {
    chat_id: string;
}

export interface Usuario {
    nome: string;
    sobrenome: string;
    username: string;
    permissoes: string[];
    chats: ChatTelegram[];
    loginsSSO: LoginSSO[];
    contas: string[];
}

export interface Contas {
    id: string;
    nome: string;
    descricao: string;
}

export interface IUsuarioSSOResponse extends Response {
    usuario: Usuario;
    contas: Contas[];
}


export interface KPIDadosContaView{
    aportes:number;
    bens:number;
    lucroPrejuizo:number;
    saldo:number;
}

export interface KPIRentabilidadeItem {
    competencia: string,
    valorLucroPrejuizo: number,
    valorInvestido: number,
    percentualLucroPrejuizo: number,
    percentualMeta: number,
}

export interface KPIRentabilidadeContaView{
    rentabilidades: KPIRentabilidadeItem[]
}

export interface KPIContratoContaView{
    id: string
    contratoId: string
    contaId: string
    competencia: string
    valorInvestido: number
    valorLucroPrejuizo: number
    valorElegivelIR: number
    lucroAcaoACumulado: number
    lucroDayTradeAcumulado: number
    lucroOpcoes: number
    lucroProventos: number
    valorDayTradeIR: number
    valorAcaoIR: number
    valorOpcoesIR: number
    valorIR: number
    valorCliente: number
    valorBX: number
    bateuMeta: boolean
    lucroGarantido: boolean
    conta: Contas
    contrato: Contrato
    codigoStatus: string
    descricaoStatus: string
    percentialLucroPrejuizo: number
    percentialCliente: number
    percentialBX: number
}

export  async function  getUsuarioSSO(request: IUsuarioSSORequest, connection: ConnectionAPI ){
    var tentativas = 3;
    var errorException: any = undefined;
    do {
        try {
            return await connection.Get<IUsuarioSSOResponse>(ConnectionAPI.Endpoints.GetUsuarioSSO(request.authority, request.username, request.uniqueId));    
        } catch (error) {
            console.log('tentativas restantes:', tentativas);
            console.log('Erro:', error);
            errorException = error;
        }finally{
            tentativas--;
        }
    } while (tentativas > 0);
    throw { sucesso: false, error: errorException, mensagem:'falha ao carregar usuário' };
}

export  async function  postProcessarNotas(request: FormData, connection: ConnectionAPI ){
    return await connection.Post<string[]>(ConnectionAPI.Endpoints.ProcessarNotas, request);
}

export  async function  getKPIDadosContaView(request: string[], connection: ConnectionAPI ){
    return await connection.Get<KPIDadosContaView>(ConnectionAPI.Endpoints.KPIDadosConta(request));
}

export  async function  getKPIRentabilidadeContaView(request: string[], connection: ConnectionAPI ){
    return await connection.Get<KPIRentabilidadeContaView>(ConnectionAPI.Endpoints.KPIRentablidadeConta(request));
}

export  async function  getKPIContratoContaView(request: string[],  competencia : string, connection: ConnectionAPI ){
    return await connection.Get<KPIContratoContaView[]>(ConnectionAPI.Endpoints.KPIContratoConta(request,competencia));
}

export  async function  KPIProcessarRentabilidadeConta(request: string[], connection: ConnectionAPI ){
    return await connection.Get<KPIRentabilidadeContaView>(ConnectionAPI.Endpoints.ProcessarKPIRentablidadeConta(request));
}

export  async function  KPIProcessarContratoConta(request: string[],  connection: ConnectionAPI ){
    return await connection.Get<KPIContratoContaView[]>(ConnectionAPI.Endpoints.ProcessarKPIContratoConta(request));
}