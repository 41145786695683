import { RequestPaginacao, ResponsePagination } from "../communs";
import ConnectionAPI from "../connection/connectionAPI";
import { Contas } from "../usuario/usuarioAPI";


export const tiposLancamentos: TipoLancamento[] = [{ id: 'PV', nome: 'Proventos' },{ id: 'DP', nome: 'Despesas' },{ id: 'OP', nome: 'Opções' }, { id: 'DT', nome: 'Day Trade' }, { id: 'AP', nome: 'Aporte' }, { id: 'AC', nome: 'Ações' }];


export interface ListarLancamentoContaRequest extends RequestPaginacao {
    contas: string[];
    tiposLancamento: string[];
    dataInicial: string;
    dataFinal: string;
}



export interface LancamentoConta {
    id: string;
    dataLancamento: string;
    contaId: string;
    tipoLancamentoId: string;
    tipoLancamento: TipoLancamento;
    conta: Contas;
    ativo: boolean;
    valor: number ;
    descricao?: string;
}
export interface TipoLancamento{
    id: string;
    nome: string;
}

export async function getListarLancamentoConta(request: ListarLancamentoContaRequest, connection: ConnectionAPI) {
    return await connection.Get<ResponsePagination<LancamentoConta>>(ConnectionAPI.Endpoints.ListarLancamentoConta(request));
}
export async function postAdicionarLancamentoConta(request: LancamentoConta, connection: ConnectionAPI) {
    return await connection.Post<LancamentoConta>(ConnectionAPI.Endpoints.AdicionarLancamentosConta, JSON.stringify( request), {'Content-Type':'application/json' });
}

export async function putAtualizarLancamentoConta(request: LancamentoConta, connection: ConnectionAPI) {
    return await connection.Put<LancamentoConta>(ConnectionAPI.Endpoints.AtualizarLancamentosConta, JSON.stringify( request), {'Content-Type':'application/json' });
}

export async function deleteLancamentoConta(request: LancamentoConta, connection: ConnectionAPI) {
    return await connection.Delete<LancamentoConta>(ConnectionAPI.Endpoints.DeleteLancamentosConta, JSON.stringify( request), {'Content-Type':'application/json' });
}

//  public ResponsePagination()
//         {
//             this.Result = new List<T>();

//         }
//         public ICollection<T> Result { get; set; }
//         public int Pagina { get; set; }
//         public int TotalRegistros { get; set; }
//         public int TamanhoPagina { get; set; }
//         public int Registros { get { return Result.Count(); } }
//         public int TotalPagina
//         {
//             get
//             {
//                 return (int)Math.Ceiling(TotalRegistros / Convert.ToDecimal(TamanhoPagina == 0 ? 1 : TamanhoPagina));
//             }
//         }