import React, { Component } from 'react';
import { Container, Row, Col, Button, Table, Collapse, Form, Modal, DropdownButton, Dropdown, FormFloating, Pagination } from 'react-bootstrap';
import { Contas } from '../usuario/usuarioAPI';
import 'intl';
import 'intl/locale-data/jsonp/pt-BR';
import { CurrencyInput } from '../ui/CurrencyInput';
import { Ativo } from '../importarNotas/importarNotasAPI';
import { TipoLancamento } from '../lancamentosConta/lancamentoContaAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faPen, faX } from "@fortawesome/free-solid-svg-icons";
import { Produto, ResponsePagination, tiposProduto } from '../communs';
import { WithContext as ReactTags } from 'react-tag-input';

interface GridProdutoState {
    showModal: boolean;
    isEdit: boolean;
    novoItem: Partial<Produto>;

}

interface GridProdutoProps {
    itensResponse: ResponsePagination<Produto>;
    addHandle: (produto: Produto, callback: (produto: Produto) => void) => void;
    updateHandle: (produto: Produto, callback: (produto: Produto) => void) => void;
    onExcluirAtivo: (produto: Produto) => void;
    permissoes: string[] | undefined;
}

class GridProduto extends Component<GridProdutoProps, GridProdutoState> {
    constructor(props: GridProdutoProps) {
        super(props);
        this.state = {
            showModal: false,
            novoItem: {
                ativo: true,
                tipo: 'ACAO',
                strike:0,
                codigosAlternativos: []
            },
            isEdit: false
        };
    }

    handleOpenModal = (isEdit: boolean) => {
        this.setState({ showModal: true, isEdit: isEdit });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleAddItem = () => {
        const { novoItem } = this.state;
        // Adicionar novo item à lista de itens
        this.setState({ isEdit: false });
        this.props.addHandle(novoItem as Produto, (produto) => {
            this.handleCloseModal();
        });

    };

    handleEditItem = (item: Produto) => {
        // Abrir o modal para editar o item
        console.log('Produto:', item);
        this.setState({ novoItem: item, showModal: true, isEdit: true });
    };

    handleUpdateItem = () => {
        const { novoItem } = this.state;
        this.props.updateHandle(novoItem as Produto, (produto) => {
            this.handleCloseModal();
        });
    };

    handleDeleteItem = (lancamentoId: number) => {

        // Excluir o item da lista de itens
        //const updatedItems = items.filter(item => item.id !== itemId);
        //this.setState({ items: updatedItems });
    };

    render() {
        const { showModal, novoItem, isEdit } = this.state;
        const tags = novoItem.codigosAlternativos?.map(c=>{return {id: c, text: c}});
        return (
            <Container>
                <Row>
                    <Col md={{ offset: 12 }} >
                        <div className='mt-2 mb-2' style={{ float: 'right' }}>
                            <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => {
                                this.setState({ isEdit: false, novoItem: { ...this.state.novoItem, id: undefined } });
                                this.handleOpenModal(false)
                            }}>
                                Adicionar Novo Produto
                            </Button>
                        </div>
                    </Col>
                </Row>
                {/* export interface Produto{
  id: string;
  codigo: string;
  nome: string;
  descricao: string;
  tipo: string;
  vencimento: string;
  codigoProdutoBase: string;
  strike: number;
  ativo: boolean;
  codigosAlternativos: string[];
  dadosAdicionais: Object;
} */}
                <Row>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Codigo</th>
                                <th>Nome</th>
                                <th>Descricao</th>
                                <th>Tipo</th>
                                <th>Vencimento</th>
                                <th>Produto Base</th>
                                <th>Strike</th>
                                <th>Ativo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.itensResponse?.result?.map((produto: Produto) => (
                                <tr key={produto.id}>
                                    <td>{produto.codigo}</td>
                                    <td>{produto.nome}</td>
                                    <td>{produto.descricao}</td>
                                    <td>{produto.tipo}</td>
                                    <td>{produto.vencimento ? (new Date(produto.vencimento)).toLocaleDateString('pt-BR') : ''}</td>
                                    <td>{produto.codigoProdutoBase}</td>
                                    <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(produto.strike)}</td>
                                    <td>{produto.ativo ? 'Ativo' : 'Inativo'}</td>
                                    <td>
                                        <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => {
                                            console.log("produto:", produto);
                                            this.setState({ novoItem: produto, showModal: true, isEdit: true });
                                        }}>
                                            <FontAwesomeIcon icon={faPen} />
                                        </Button>{" "}
                                        <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="danger" onClick={() => this.props.onExcluirAtivo(produto)}>
                                            <FontAwesomeIcon icon={faX} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </Row>

                <Modal show={showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{novoItem.id ? 'Editar Item' : 'Adicionar Novo Item'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group hidden={!isEdit} controlId="id">
                                <Form.Label>ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    disabled
                                    value={novoItem.id}
                                />
                            </Form.Group>
                            <Form.Group controlId="produtoCodigo">
                                <Form.Label>Código Produto</Form.Label>
                                <Form.Control as="input" value={novoItem.codigo} onChange={(event) => {
                                    this.setState({ novoItem: { ...novoItem, ...{ codigo: event.target.value } } });
                                }}
                                />
                            </Form.Group>
                            <Form.Group controlId="produtoNome">
                                <Form.Label>Produto Nome</Form.Label>
                                <Form.Control as="input" value={novoItem.nome} onChange={(event) => {
                                    this.setState({ novoItem: { ...novoItem, ...{ nome: event.target.value } } });
                                }}
                                />
                            </Form.Group>
                            <Form.Group controlId="produtoDescricao">
                                <Form.Label>Produto Descrição</Form.Label>
                                <Form.Control as="input" value={novoItem.descricao} onChange={(event) => {
                                    this.setState({ novoItem: { ...novoItem, ...{ descricao: event.target.value } } });
                                }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="selectTipoProduto">
                                <Form.Label>Selecione a tipo Produto:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={novoItem.tipo}
                                    onChange={(event) => {
                                        this.setState({
                                            novoItem: {
                                                ...novoItem, ...{
                                                    tipo: event.target.value
                                                }
                                            }
                                        })
                                    }}
                                >
                                    <option value="">Selecione...</option>
                                    {tiposProduto.map(tipo =>
                                        <option key={tipo.codigo} id={tipo.codigo} value={tipo.codigo}>{tipo.descricao}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="vencimento">
                                <Form.Label>Vencimento</Form.Label>
                                <Form.Control type="date" value={novoItem.vencimento?.split('T')[0]} onChange={(event) => this.setState({ novoItem: { ...novoItem, ...{ vencimento: new Date(event.target.value).toISOString().split('T')[0] } } })} />
                            </Form.Group>
                            <Form.Group controlId="produtoBase">
                                <Form.Label>Produto Base</Form.Label>
                                <Form.Control as="input" value={novoItem.codigoProdutoBase} onChange={(event) => {
                                    this.setState({ novoItem: { ...novoItem, ...{ codigoProdutoBase: event.target.value } } });
                                }}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="strike">
                                <Form.Label>Strike</Form.Label>
                                <CurrencyInput value={this.state.novoItem.strike ?? 0 } onChange={(nv) => this.setState({ novoItem: { ...novoItem, ...{ strike: nv } } })} />
                            </Form.Group>
                            <Form.Group controlId="ativo">
                                <Form.Label>Ativo</Form.Label>
                                <Form.Check checked={novoItem.ativo} onChange={(event) => {
                                    console.log(event);
                                    this.setState({ novoItem: { ...novoItem, ...{ ativo: event.target.checked } } });
                                }}
                                />
                            </Form.Group>
                            <Form.Group controlId="codigosextras">
                                <ReactTags
                                    tags={tags ?? []}
                                    placeholder='Adicionar códigos extras'
                                    handleAddition={(tag)=>{
                                        console.log(tag);
                                        let codigos = novoItem.codigosAlternativos??[];
                                        codigos?.push(tag.id);
                                        this.setState({ novoItem: { ...novoItem, ...{ codigosAlternativos: codigos } } });
                                    }}
                                    handleDelete={(i)=>{
                                        let codigos = novoItem.codigosAlternativos as [];
                                        let codigo = codigos[i];
                                        let newcodigos : string[] = [];
                                        codigos?.forEach((c: string)=> {
                                            if(c != codigo ){
                                                newcodigos.push(c);
                                            } 
                                        })
                                        this.setState({ novoItem: { ...novoItem, ...{ codigosAlternativos: newcodigos  } } });
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button
                            variant={novoItem.id ? 'warning' : 'success'}
                            onClick={novoItem.id ? this.handleUpdateItem : this.handleAddItem}
                        >
                            {novoItem.id ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        );
    }
}

export default GridProduto;