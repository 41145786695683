import React, { Component } from 'react';
import { Container, Row, Col, Button, Table, Collapse, Form, Modal, DropdownButton, Dropdown, FormFloating, Pagination } from 'react-bootstrap';
import { Contas } from '../usuario/usuarioAPI';
import 'intl';
import 'intl/locale-data/jsonp/pt-BR';
import { CurrencyInput } from '../ui/CurrencyInput';
import { Ativo } from '../importarNotas/importarNotasAPI';
import { Contrato, ModeloContrato, Pessoa } from './contratoAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPiggyBank, faPen, faX, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { ResponsePagination } from '../communs';

interface GridContratoState {
    showModal: boolean;
    isEdit: boolean;
    novoItem: Partial<Contrato>;
    selectedModelos: ModeloContrato[];
    isLoadingProcessarRentabilidade: boolean;
    isLoadingProcessarContrato: boolean;
}

interface GridContratoProps {
    itensResponse: ResponsePagination<Contrato>;
    contas: Contas[];
    addHandle: (ativo: Contrato, callback: (ativo: Contrato) => void) => void;
    updateHandle: (ativo: Contrato, callback: (ativo: Contrato) => void) => void;
    onExcluirAtivo: (ativo: Contrato) => void;
    permissoes: string[] | undefined;
    modelos: ModeloContrato[];
    onProcessarRentabilidade: (contrato: Contrato, callback: () => void) => void;
    onProcessarContrato: (contrato: Contrato, callback: () => void) => void;
}

class GridContrato extends Component<GridContratoProps, GridContratoState> {
    constructor(props: GridContratoProps) {
        super(props);
        this.state = {
            showModal: false,
            novoItem: {
                dataInicio: new Date().toISOString().split('T')[0],
                prioridade: 0,
                condicoes: {},
                modelo: { condicoes: {}, descricao: "", id: "", nome: "", urlModelo: "" }
            },
            isEdit: false,
            selectedModelos: [],
            isLoadingProcessarContrato: false,
            isLoadingProcessarRentabilidade: false
        };
    }

    handleOpenModal = (isEdit: boolean) => {
        this.setState({ showModal: true, isEdit: isEdit });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleAddItem = () => {
        const { novoItem } = this.state;
        // Adicionar novo item à lista de itens
        this.setState({ isEdit: false });
        this.props.addHandle(novoItem as Contrato, (contrato) => {
            this.handleCloseModal();
        });

    };

    handleEditItem = (item: Ativo) => {
        // Abrir o modal para editar o item
        this.setState({ novoItem: item, showModal: true, isEdit: true });
    };

    handleUpdateItem = () => {
        const { novoItem } = this.state;
        this.props.updateHandle(novoItem as Contrato, (contrato) => {
            this.handleCloseModal();
        });
    };

    handleDeleteItem = (lancamentoId: number) => {

        // Excluir o item da lista de itens
        //const updatedItems = items.filter(item => item.id !== itemId);
        //this.setState({ items: updatedItems });
    };

    render() {
        const { showModal, novoItem, isEdit } = this.state;

        return (
            <Container>
                <Row>
                    <Col md={{ offset: 12 }} >
                        <div className='mt-2 mb-2' style={{ float: 'right' }}>
                            <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => {
                                this.setState({ isEdit: false, novoItem: { ...this.state.novoItem, id: undefined } });
                                this.handleOpenModal(false)
                            }}>
                                Adicionar Novo Contrato
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th style={{ whiteSpace: 'nowrap' }}>Contratante</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Conta</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Data Início</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Data Fim</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Modelo</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Nome</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Prioridade</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Situação</th>
                                <th style={{ whiteSpace: 'nowrap' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.itensResponse?.result?.map((contrato: Contrato) => (
                                <tr key={contrato.id}>
                                    <td>{contrato.pessoa.nomeFantasia}</td>
                                    <td>{contrato.conta.nome}</td>
                                    <td>{(new Date(contrato.dataInicio)).toLocaleDateString('pt-BR')}</td>
                                    <td>{contrato.dataFim ? (new Date(contrato.dataFim)).toLocaleDateString('pt-BR') : ''}</td>
                                    <td>{contrato.modelo.nome}</td>
                                    <td>{contrato.nome}</td>
                                    <td>{contrato.prioridade}</td>
                                    <td>{contrato.status}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => {
                                            this.setState({ novoItem: contrato, showModal: true, isEdit: true });
                                        }}>
                                            <FontAwesomeIcon icon={faPen} />
                                        </Button>{" "}
                                        <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="danger" onClick={() => this.props.onExcluirAtivo(contrato)}>
                                            <FontAwesomeIcon icon={faX} />
                                        </Button>
                                        <Button disabled={this.state.isLoadingProcessarRentabilidade} hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => {
                                            this.setState({ isLoadingProcessarRentabilidade: true });
                                            this.props.onProcessarRentabilidade(contrato, () => {
                                                this.setState({ isLoadingProcessarRentabilidade: false });
                                            });
                                        }}>
                                            <FontAwesomeIcon icon={faPiggyBank} />
                                        </Button>{" "}
                                        <Button disabled={this.state.isLoadingProcessarContrato} hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => {
                                            this.setState({ isLoadingProcessarContrato: true });
                                            this.props.onProcessarContrato(contrato, () => {
                                                this.setState({ isLoadingProcessarContrato: false });
                                            });
                                        }}>
                                            <FontAwesomeIcon icon={faListCheck} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </Row>

                <Modal show={showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{novoItem.id ? 'Editar Item' : 'Adicionar Novo Item'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group hidden={!isEdit} controlId="id">
                                <Form.Label>ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    disabled
                                    value={novoItem.id}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="dataInicio">
                                <Form.Label>Data Início</Form.Label>
                                <Form.Control type="date" value={novoItem.dataInicio?.split('T')[0]} onChange={(event) => this.setState({ novoItem: { ...novoItem, ...{ dataInicio: new Date(event.target.value).toISOString().split('T')[0] } } })} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="dataFim">
                                <Form.Label>Data Fim</Form.Label>
                                <Form.Control type="date" value={novoItem.dataFim?.split('T')[0]} onChange={(event) => this.setState({ novoItem: { ...novoItem, ...{ dataFim: new Date(event.target.value).toISOString().split('T')[0] } } })} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="selectConta">
                                <Form.Label>Selecione a conta:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={novoItem.contaId}
                                    onChange={(event) => { this.setState({ novoItem: { ...novoItem, ...{ contaId: event.target.value, conta: this.props.contas.find((v) => v.id == event.target.value) } } }) }}
                                >
                                    <option value="">Selecione...</option>
                                    {this.props.contas.map(conta =>
                                        <option key={conta.id} id={conta.id} value={conta.id}>{conta.nome}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="selectModelo">
                                <Form.Label>Selecione Modelo:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={novoItem.modeloContratoId}
                                    onChange={(event) => {
                                        this.setState({
                                            novoItem: {
                                                ...novoItem, ...{
                                                    modeloContratoId: event.target.value,
                                                    modelo: this.props.modelos.find((v) => v.id == event.target.value)
                                                }
                                            }
                                        })
                                    }}
                                >
                                    <option value="">Selecione...</option>
                                    {this.props.modelos.map(modelo =>
                                        <option key={modelo.id} id={modelo.id} value={modelo.id}>{modelo.nome}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="nome">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control as="input" value={novoItem.nome as string} onChange={(event) => {
                                    this.setState({
                                        novoItem: {
                                            ...novoItem,
                                            ...{
                                                nome: event.target.value
                                            }
                                        }
                                    })
                                }}
                                />
                            </Form.Group>
                            <Form.Group controlId="nomeCompleto">
                                <Form.Label>Nome Completo</Form.Label>
                                <Form.Control as="input" value={novoItem.pessoa?.nomeOficial as string} onChange={(event) => {
                                    this.setState({
                                        novoItem: {
                                            ...novoItem,
                                            ...{
                                                pessoa: { ...novoItem.pessoa as Pessoa, ...{ nomeOficial: event.target.value } }
                                            }
                                        }
                                    })
                                }}
                                />
                            </Form.Group>
                            <Form.Group controlId="nomeInformal">
                                <Form.Label>Nome Informal</Form.Label>
                                <Form.Control as="input" value={novoItem.pessoa?.nomeFantasia as string} onChange={(event) => {
                                    this.setState({
                                        novoItem: {
                                            ...novoItem,
                                            ...{
                                                pessoa: { ...novoItem.pessoa as Pessoa, ...{ nomeFantasia: event.target.value } }
                                            }
                                        }
                                    })
                                }}
                                />
                            </Form.Group>
                            <Form.Group controlId="documento">
                                <Form.Label>Documento</Form.Label>
                                <Form.Control as="input" value={novoItem.pessoa?.documento as string} onChange={(event) => {
                                    this.setState({
                                        novoItem: {
                                            ...novoItem,
                                            ...{
                                                pessoa: { ...novoItem.pessoa as Pessoa, ...{ documento: event.target.value } }
                                            }
                                        }
                                    })
                                }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="dataNascimento">
                                <Form.Label>Data Nascimento</Form.Label>
                                <Form.Control type="date" value={novoItem.pessoa?.dataNascimento?.split('T')[0]} onChange={(event) => this.setState({
                                    novoItem: {
                                        ...novoItem,
                                        ...{
                                            pessoa: { ...novoItem.pessoa as Pessoa, ...{ dataNascimento: new Date(event.target.value).toISOString().split('T')[0] } }
                                        }
                                    }
                                })} />
                            </Form.Group>
                            {

                                Object.keys(this.state.novoItem.modelo?.condicoes as Record<string, number>).map(k => {
                                    var dadosAdicionais: Record<string, number> = novoItem.condicoes as Record<string, number>;
                                    return <Form.Group key={k} controlId={k}>
                                        <Form.Label>{k}</Form.Label>
                                        <Form.Control as="input" type='number' value={dadosAdicionais[k]} onChange={(event) => {
                                            dadosAdicionais[k] = parseFloat(event.target.value);
                                            this.setState({
                                                novoItem: {
                                                    ...novoItem,
                                                    ...{
                                                        dadosAdicionais: dadosAdicionais
                                                    }
                                                }
                                            })
                                        }}
                                        />
                                    </Form.Group>
                                })
                            }
                            <Form.Group controlId="prioridade">
                                <Form.Label>Prioridade</Form.Label>
                                <Form.Control as="input" value={novoItem.prioridade as number} onChange={(event) => {
                                    this.setState({
                                        novoItem: {
                                            ...novoItem,
                                            ...{
                                                prioridade: parseFloat(event.target.value)
                                            }
                                        }
                                    })
                                }}
                                />
                            </Form.Group>
                            <Form.Group controlId="lucroGarantido">
                                <Form.Check // prettier-ignore
                                    type='checkbox'
                                    id="lucroGarantido"
                                    label="Garantido"
                                    value={novoItem.lucroGarantido?.toString()}
                                    onChange={(el) => {
                                        this.setState({
                                            novoItem: {
                                                ...novoItem,
                                                ...{
                                                    lucroGarantido: el.target.checked
                                                }
                                            }
                                        })
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button
                            variant={novoItem.id ? 'warning' : 'success'}
                            onClick={novoItem.id ? this.handleUpdateItem : this.handleAddItem}
                        >
                            {novoItem.id ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        );
    }
}

export default GridContrato;