import { AccountInfo, IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { ResponsePagination } from "../../features/communs";
import { Contrato, ListarContratosRequest, ModeloContrato, getListarContratosConta, getListarContratosContas, getListarModelos, postAdicionarContrato, putAtualizarContrato } from "../../features/contratos/contratoAPI";
import { AppThunkAction } from "..";
import { appInsights } from "../..";
import { apiRequest, loginRequest } from "../../authConfig";
import { DefaultConnection } from "../../features/connection/connectionAPI";
import { Action, Reducer } from 'redux';



export interface ContratoState {
    contratoResponse: Partial<ResponsePagination<Contrato>> | undefined;
    contratoContasResponse: Partial<ResponsePagination<Contrato>> | undefined;
    listarContratosFiltro: Partial<ListarContratosRequest> | undefined;
    modelos: ModeloContrato[];
    status: 'idle' | 'loading' | 'failed';
    statusModelos: 'idle' | 'loading' | 'failed';
}

const unloadedState: ContratoState = {
    contratoResponse: undefined,
    listarContratosFiltro: undefined,
    contratoContasResponse: undefined,
    status: 'idle',
    modelos: [],
    statusModelos: 'idle'
};

interface RequestGetListaraContratosContasAction {
    type: 'REQUEST_GET_LISTAR_CONTRATOS_CONTAS';
}

interface ReceiveGetListarContratosContasAction {
    type: 'RECEIVE_GET_LISTAR_CONTRATOS_CONTAS';
    response: ResponsePagination<Contrato>;
}

interface RequestGetListaraContratosAction {
    type: 'REQUEST_GET_LISTAR_CONTRATOS';
}

interface ReceiveGetListarContratosAction {
    type: 'RECEIVE_GET_LISTAR_CONTRATOS';
    response: ResponsePagination<Contrato>;
}
interface RequestGetListaraModelosAction {
    type: 'REQUEST_GET_LISTAR_MODELOS';
}

interface ReceiveGetListarModelosAction {
    type: 'RECEIVE_GET_LISTAR_MODELOS';
    response: ModeloContrato[];
}
interface RequestAdicionarContratoAction {
    type: 'REQUEST_POST_ADICIONAR_CONTRATO';
}

interface ReceiveAdicionarContratoAction {
    type: 'RECEIVE_POST_ADICIONAR_CONTRATO';
    response: Contrato;
}
interface RequestAtualizarContratoAction {
    type: 'REQUEST_PUT_ATUALIZAR_CONTRATO';
}

interface ReceiveAtualizarContratoAction {
    type: 'RECEIVE_PUT_ATUALIZAR_CONTRATO';
    response: Contrato;
}
interface ReceiveErrorNotasAction {
    type: 'RECEIVE_POST_ERROR';
}
type KnownAction = RequestGetListaraContratosContasAction | ReceiveGetListarContratosContasAction | RequestAdicionarContratoAction | ReceiveAdicionarContratoAction | RequestAtualizarContratoAction | ReceiveAtualizarContratoAction | RequestGetListaraContratosAction | ReceiveGetListarContratosAction | ReceiveErrorNotasAction | RequestGetListaraModelosAction | ReceiveGetListarModelosAction;

export const actionCreators = {
    listarContratosContaAsync: (request: Partial<ListarContratosRequest>, instance: IPublicClientApplication,
        callback: (response: ResponsePagination<Contrato>) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:listarContratosContaAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.ativoState && appState.ativoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_GET_LISTAR_CONTRATOS_CONTAS' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_GET_LISTAR_CONTRATOS_CONTAS' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await getListarContratosContas(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_GET_LISTAR_CONTRATOS_CONTAS', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao lista de lançamentos:' + JSON.stringify(error));
            }

        },
    listarContratosAsync: (request: ListarContratosRequest, instance: IPublicClientApplication,
        callback: (response: ResponsePagination<Contrato>) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:listarAtivosAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.ativoState && appState.ativoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_GET_LISTAR_CONTRATOS' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_GET_LISTAR_CONTRATOS' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await getListarContratosConta(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_GET_LISTAR_CONTRATOS', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao lista de lançamentos:' + JSON.stringify(error));
            }

        },
    listarModelosAsync: (instance: IPublicClientApplication,
        callback: (response: ModeloContrato[]) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:listarModelosAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.contratoState && appState.contratoState.statusModelos !== "loading") {
                    dispatch({ type: 'REQUEST_GET_LISTAR_MODELOS' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_GET_LISTAR_MODELOS' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await getListarModelos(DefaultConnection);
                    dispatch({ type: 'RECEIVE_GET_LISTAR_MODELOS', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao lista de lançamentos:' + JSON.stringify(error));
            }

        },
    adicionarContratoAsync: (request: Contrato, instance: IPublicClientApplication,
        callback: (response: Contrato) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:adicionarContratoAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.contratoState && appState.contratoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_POST_ADICIONAR_CONTRATO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_POST_ADICIONAR_CONTRATO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    // Acquire token interactive success
                                    //let accessToken = accessTokenResponse.accessToken;
                                    // Call your API with token
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await postAdicionarContrato(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_POST_ADICIONAR_CONTRATO', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        },
    atualizarContratoAsync: (request: Contrato, instance: IPublicClientApplication,
        callback: (response: Contrato) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:atualizarContratoAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.contratoState && appState.contratoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_PUT_ATUALIZAR_CONTRATO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_PUT_ATUALIZAR_CONTRATO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await putAtualizarContrato(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_PUT_ATUALIZAR_CONTRATO', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        }
}


export const reducer: Reducer<ContratoState> = (state: ContratoState | undefined, incomingAction: Action): ContratoState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_GET_LISTAR_CONTRATOS':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_GET_LISTAR_CONTRATOS':
            return { ...state, ...{ status: 'idle', contratoResponse: action.response } }
        case 'REQUEST_GET_LISTAR_CONTRATOS_CONTAS':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_GET_LISTAR_CONTRATOS_CONTAS':
            return { ...state, ...{ status: 'idle', contratoContasResponse: action.response } }
        case 'REQUEST_GET_LISTAR_MODELOS':
            return { ...state, ...{ statusModelos: 'loading' } }
        case 'RECEIVE_GET_LISTAR_MODELOS':
            return { ...state, ...{ statusModelos: 'idle', modelos: action.response } }
        case 'REQUEST_POST_ADICIONAR_CONTRATO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_POST_ADICIONAR_CONTRATO':
            state.contratoResponse?.result?.push(action.response);
            return { ...state, ...{ status: 'idle', contratoResponse: state.contratoResponse } }
        case 'REQUEST_PUT_ATUALIZAR_CONTRATO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_PUT_ATUALIZAR_CONTRATO':
            return {
                ...state, ...{
                    status: 'idle', contratoResponse: {
                        ...state.contratoResponse,
                        ...{
                            result: state.contratoResponse?.result?.map((l: Contrato) => {
                                if (l.id == action.response.id) {
                                    return action.response;
                                } else {
                                    return l;
                                }
                            })
                        }
                    }
                }
            }
        case 'RECEIVE_POST_ERROR':
            return { ...state, ...{ status: 'failed' } }
        default:
            break;
    }

    return state;
}