import React, { Component } from 'react';
import { Container, Row, Col, Button, Table, Collapse, Form, Modal, DropdownButton, Dropdown, FormFloating, Pagination } from 'react-bootstrap';
import { Contas } from '../usuario/usuarioAPI';
import { LancamentoConta, TipoLancamento, tiposLancamentos } from './lancamentoContaAPI';
import 'intl';
import 'intl/locale-data/jsonp/pt-BR';
import { CurrencyInput } from '../ui/CurrencyInput';
import { BsFillPlusSquareFill, BsDashSquareFill } from 'react-icons/bs';
import { faCheck, faTimes, faPen, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponsePagination } from '../communs';

interface GridLancamentoContaState {
    showModal: boolean;
    isEdit: boolean;
    novoItem: Partial<LancamentoConta>;
    tipoVisible: { [key: string]: boolean };
}

interface GridLancamentoContaProps {
    itensResponse: ResponsePagination<LancamentoConta>;
    contas: Contas[];
    addHandle: (lancamento: LancamentoConta, callback: (lancamento: LancamentoConta) => void) => void;
    updateHandle: (lancamento: LancamentoConta, callback: (lancamento: LancamentoConta) => void) => void;
    permissoes: string[] | undefined;
    onExcluirLancamento: (lancamento: LancamentoConta) => void;
}

class GridLancamentoConta extends Component<GridLancamentoContaProps, GridLancamentoContaState> {
    constructor(props: GridLancamentoContaProps) {
        super(props);
        this.state = {
            showModal: false,
            novoItem: {
                ativo: true,
                dataLancamento: new Date().toISOString().split('T')[0],
                valor: 0
            },
            isEdit: false,
            tipoVisible: {}
        };
    }

    handleOpenModal = (isEdit: boolean) => {
        this.setState({ showModal: true, isEdit: isEdit });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleAddItem = () => {
        const { novoItem } = this.state;
        // Adicionar novo item à lista de itens
        this.setState({ isEdit: false });
        this.props.addHandle(novoItem as LancamentoConta, (lancamento) => {
            this.handleCloseModal();
        });

    };

    handleEditItem = (item: LancamentoConta) => {
        // Abrir o modal para editar o item
        this.setState({ novoItem: item, showModal: true, isEdit: true });
    };

    handleUpdateItem = () => {
        const { novoItem } = this.state;
        this.props.updateHandle(novoItem as LancamentoConta, (lancamento) => {
            this.handleCloseModal();
        });
    };

    handleDeleteItem = (lancamentoId: number) => {

        // Excluir o item da lista de itens
        //const updatedItems = items.filter(item => item.id !== itemId);
        //this.setState({ items: updatedItems });
    };
    componentDidMount(): void {
        const itensVisble: { [key: string]: boolean } = {};
        this.props.itensResponse?.result.forEach((item:LancamentoConta) => {
            if (!itensVisble[item.tipoLancamentoId]) {
                itensVisble[item.tipoLancamentoId] = false;
            }
        });
        this.setState({ tipoVisible: itensVisble });
        console.log(itensVisble);
    }
    render() {
        const { showModal, novoItem, isEdit } = this.state;

        // Agrupar os itens por tipo de lançamento
        const itensPorTipo: { [key: string]: LancamentoConta[] } = {};

        this.props.itensResponse?.result.forEach((item:LancamentoConta) => {
            if (!itensPorTipo[item.tipoLancamentoId]) {
                itensPorTipo[item.tipoLancamentoId] = [];
            }
            itensPorTipo[item.tipoLancamentoId].push(item);
        });

        return (
            <Container>
                <Row>
                    <Col md={{ offset: 12 }} >
                        <div className='mt-2 mb-2' style={{ float: 'right' }}>
                            <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => this.handleOpenModal(false)}>
                                Adicionar Novo Lançamento
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Tipo</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(itensPorTipo).map(([tipo, itens]) => {

                                return <React.Fragment key={tipo} >
                                    <tr key={'grupo' + tipo}>
                                        <td style={{ cursor: 'pointer' }} onClick={() => {
                                            var tipoVisible = this.state.tipoVisible;
                                            tipoVisible[tipo] = !tipoVisible[tipo];
                                            this.setState({ tipoVisible: tipoVisible });
                                        }}>{this.state.tipoVisible[tipo] ? <BsDashSquareFill /> : <BsFillPlusSquareFill />}</td>
                                        <td>{itens[0].tipoLancamento.nome}</td>
                                        <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(itens.map(v => v.valor).reduce((acc, current) => acc + current, 0))}</td>
                                    </tr>
                                    <tr key={'itens' + tipo} hidden={!this.state.tipoVisible[tipo] || this.state.tipoVisible[tipo] == false}>
                                        <td colSpan={3}>
                                            <Table striped bordered hover responsive >
                                                <thead>
                                                    <tr>
                                                        <th>Conta</th>
                                                        <th>Tipo</th>
                                                        <th>Descrição</th>
                                                        <th>Data</th>
                                                        <th>Valor</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        itens.map(l => {
                                                            return <tr key={l.id}>
                                                                <td>{l.conta.nome}</td>
                                                                <td>{l.tipoLancamento.nome}</td>
                                                                <td>{l.descricao}</td>
                                                                <td>{l.dataLancamento}</td>
                                                                <td>{l.valor}</td>
                                                                <td>
                                                                <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="success" onClick={() => {
                                            this.setState({ novoItem: l, showModal: true, isEdit: true });
                                        }}>
                                            <FontAwesomeIcon icon={faPen} />
                                        </Button>{" "}
                                        <Button hidden={this.props.permissoes && !this.props.permissoes?.some(p => ["Admin"].includes(p))} variant="danger" onClick={() => this.props.onExcluirLancamento(l)}>
                                            <FontAwesomeIcon icon={faX} />
                                        </Button>

                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Total Geral</th>
                                <th></th>
                                <th align='right' style={{ 'alignContent': 'flex-end' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.props.itensResponse?.result?.map((v: LancamentoConta) => v.valor).reduce((acc: number, current: number) => acc + current, 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>

                </Row>

                <Modal show={showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{novoItem.id ? 'Editar Item' : 'Adicionar Novo Item'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group hidden={!isEdit} controlId="id">
                                <Form.Label>ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    value={novoItem.id}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="dataLancamento">
                                <Form.Label>Data</Form.Label>
                                <Form.Control type="date" value={novoItem.dataLancamento?.split('T')[0]} onChange={(event) => this.setState({ novoItem: { ...novoItem, ...{ dataLancamento: new Date(event.target.value).toISOString().split('T')[0] } } })} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="selectConta">
                                <Form.Label>Selecione a conta:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={novoItem.contaId}
                                    onChange={(event) => { this.setState({ novoItem: { ...novoItem, ...{ contaId: event.target.value, conta: this.props.contas.find((v) => v.id == event.target.value) } } }) }}
                                >
                                    <option value="">Selecione...</option>
                                    {this.props.contas.map(conta =>
                                        <option key={conta.id} id={conta.id} value={conta.id}>{conta.nome}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="selectTipoLancamento">
                                <Form.Label>Selecione a tipo Lançamento:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={novoItem.tipoLancamentoId}
                                    onChange={(event) => {
                                        this.setState({
                                            novoItem: {
                                                ...novoItem, ...{
                                                    tipoLancamentoId: event.target.value,
                                                    tipoLancamento: tiposLancamentos.find((v) => v.id == event.target.value)
                                                }
                                            }
                                        })
                                    }}
                                >
                                    <option value="">Selecione...</option>
                                    {tiposLancamentos.map(tipoLancamento =>
                                        <option key={tipoLancamento.id} id={tipoLancamento.id} value={tipoLancamento.id}>{tipoLancamento.nome}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="valor">
                                <Form.Label>Valor</Form.Label>
                                {/* <Form.Control as="input" type="number" value={novoItem.valor} onChange={(event) => {
                                    if (/^\d+(\.\d+)?$/.test(event.target.value)) {
                                        this.setState({ novoItem: { ...novoItem, ...{ valor: parseFloat(event.target.value) } } });
                                    } else if (event.target.value == '') {
                                        this.setState({ novoItem: { ...novoItem, ...{ valor: 0 } } });
                                    }
                                }} /> */}
                                <CurrencyInput value={this.state.novoItem.valor as number} onChange={(nv) => this.setState({ novoItem: { ...novoItem, ...{ valor: nv } } })} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button
                            variant={novoItem.id ? 'warning' : 'success'}
                            onClick={novoItem.id ? this.handleUpdateItem : this.handleAddItem}
                        >
                            {novoItem.id ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        );
    }
}

export default GridLancamentoConta;