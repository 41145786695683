import React, { Component } from "react";
interface LoadingProps {
  isLoading: boolean 
}
export default class Loading extends Component<LoadingProps> {
  constructor(props: LoadingProps){
    super(props);
    
  }
  render() {
    return (
      // Estrutura do componente de loading com animação de feixe de luz
      <div hidden={!this.props.isLoading} style={{ width: "100%", height: "10px", backgroundColor: "#f0f0f0" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            background: `linear-gradient(90deg, rgba(0, 123, 255, 0.3), rgba(0, 123, 255, 0.9), rgba(0, 123, 255, 0.3))`,
            backgroundSize: "200% 200%",
            animation: "loadingAnimation 1s infinite",
          }}
        />
        <style>
          {`
          @keyframes loadingAnimation {
            0% {
              background-position: left;
            }
            100% {
              background-position: right;
            }
          }
        `}
        </style>
      </div>
    );
  }
}