import React, { Component, FormEvent } from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { CustomNavigationClient } from '../../../utils/NavigationClient';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import * as UsuarioStore from '../../../store/usuario/usuarioStore';
import * as RelatoriosStore from '../../../store/relatorios/relatoriosStore';
import * as AtivoStore from '../../../store/ativo/ativoStore';
import { Contas } from '../../usuario/usuarioAPI';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { apiRequest } from '../../../authConfig';
import { AuthenticatedTemplate } from "@azure/msal-react";
import Layout from '../../layout/Layout';
import { Accordion, Button, Col, Dropdown, DropdownButton, Form, Pagination, Row, Table } from 'react-bootstrap';
import { TipoAtivo, tiposAtivos } from '../../ativos/ativoAPI';
import { ResponsePagination } from '../../communs';
import { Ativo } from '../../importarNotas/importarNotasAPI';


type Props = {
    navigation: CustomNavigationClient,
    window?: () => Window,
    instance: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators & RelatoriosStore.RelatoriosState 
& typeof RelatoriosStore.actionCreators & AtivoStore.AtivoState & typeof AtivoStore.actionCreators;

type RelAtivoPendenteNormalizacaoUIState = {
    prevState: boolean;
    selectedAccount: Contas[];
    Contas: Contas[];
    isLoading: boolean;
    dataInicial: string;
    dataFinal: string;
    tamanhoPagina: number;
    selectContasTodos: boolean;
    ws: W3CWebSocket | undefined;
    TiposAtivo: TipoAtivo[];
    selectedTiposAtivo: TipoAtivo[];
    RelatorioPosicaoAtivoResponse: ResponsePagination<Ativo> | undefined;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

class RelAtivoPendenteNormalizacao extends React.PureComponent<Props, RelAtivoPendenteNormalizacaoUIState>{
    constructor(props: any) {
        super(props);
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        datainicial.setFullYear(datainicial.getFullYear() - 2);
        this.state = {
            selectedAccount: [],
            prevState: false,
            Contas: [],
            isLoading: false,
            dataInicial: datainicial.toISOString().split('T')[0],
            dataFinal: new Date().toISOString().split('T')[0],
            tamanhoPagina: 100,
            selectContasTodos: true,
            ws: undefined,
            TiposAtivo: tiposAtivos,
            selectedTiposAtivo: tiposAtivos,
            RelatorioPosicaoAtivoResponse: undefined
        };

    }


    handleTiposChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const tipo = this.state.TiposAtivo.find(a => a.id === event.target.value) as TipoAtivo;
        if (tipo != null && tipo != undefined) {
            if (this.state.selectedTiposAtivo?.includes(tipo)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedTiposAtivo: this.state.selectedTiposAtivo.filter(t => t.id !== tipo.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedTiposAtivo: [...this.state.selectedTiposAtivo, tipo]
                });
            }
        }
    };

    handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        try {
            this.props.ConsultarRelatorioAtivosPendentesNormalizacao(this.props.instance,
                1,
                this.state.tamanhoPagina, this.state.selectedAccount.map(c=>c.id),
                (responsePosicaoAtivos: ResponsePagination<Ativo> | undefined) => {
                    this.setState({ RelatorioPosicaoAtivoResponse: responsePosicaoAtivos, isLoading: false });
                });
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };

    componentDidMount(): void {
        var datainicial = new Date();
        datainicial.setUTCDate(1);
        if (this.props.contas == undefined || this.props.contas.length == 0) {
            this.props.getContasAsync(this.props.instance, (response) => {
                this.setState({ Contas: response.contas, selectedAccount: response.contas });
                this.props.ConsultarRelatorioAtivosPendentesNormalizacao(this.props.instance,
                    1,
                    this.state.tamanhoPagina, response.contas.map(c=>c.id),
                    (responsePosicaoAtivos: ResponsePagination<Ativo> | undefined) => {
                        this.setState({ RelatorioPosicaoAtivoResponse: responsePosicaoAtivos, isLoading: false });
                    });
            });
        } else {
            this.setState({ Contas: this.props.contas as Contas[], selectedAccount: this.props.contas as Contas[] });
            this.props.ConsultarRelatorioAtivosPendentesNormalizacao(this.props.instance,
                1,
                this.state.tamanhoPagina,this.props.contas.map(c=>c.id) as string[],
                (responsePosicaoAtivos: ResponsePagination<Ativo> | undefined) => {
                    this.setState({ RelatorioPosicaoAtivoResponse: responsePosicaoAtivos, isLoading: false });
                });
        }
    }
    handleAccountChange = (event: React.ChangeEvent<FormControlElement>) => {
        console.log(event.target.value);
        const account = this.state.Contas.find(a => a.id === event.target.value) as Contas;
        if (account != null && account != undefined) {
            if (this.state.selectedAccount?.includes(account)) {
                // Remove a conta selecionada caso já esteja selecionada
                this.setState({
                    selectedAccount: this.state.selectedAccount.filter(conta => conta.id !== account.id)
                });
            } else {
                // Adiciona a conta selecionada caso não esteja selecionada
                this.setState({
                    selectedAccount: [...this.state.selectedAccount, account]
                });
            }
        }
    };
    componentWillUnmount(): void {
        if (this.state.ws) this.state.ws.close();
    }
    render(): React.ReactNode {
        return <AuthenticatedTemplate>
            <Layout navigation={this.props.navigation} isLoading={this.props.RelatorioPosicaoAtivos.status == 'loading'} permissoes={this.props.usuario?.permissoes}>
                <div className="container mt-5">
                    <h1>Pendentes de normalização</h1>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Opções de filtro</Accordion.Header>
                            <Accordion.Body>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                    <Col >
                                            <Form.Group controlId="selectConta">
                                                <Form.Label>Selecione a conta:</Form.Label>
                                                <DropdownButton title="Selecione as Contas" id="dropdown-contas" autoClose="outside">
                                                    <Dropdown.Item
                                                        key="select-selectConta-filtro"
                                                        as="label"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="select-selectConta-filtro"
                                                            label='Selecione Todos'
                                                            value='select-selectConta-filtro'
                                                            checked={this.state.selectContasTodos}
                                                            onChange={() => { this.setState({ selectedAccount: !this.state.selectContasTodos ? this.state.Contas : [], selectContasTodos: !this.state.selectContasTodos }) }}
                                                        />
                                                    </Dropdown.Item>
                                                    {this.state.Contas.map(conta => (
                                                        <Dropdown.Item
                                                            key={conta.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${conta.id}`}
                                                                label={conta.nome}
                                                                value={conta.id}
                                                                checked={this.state.selectedAccount.includes(conta)}
                                                                onChange={this.handleAccountChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>
                                        <Col >
                                            <Form.Group as={Col} controlId="tipoLancamento">
                                                <Form.Label>Tipo de Ativo</Form.Label>
                                                <DropdownButton title="Selecione os tipos de Ativos" id="dropdown-contas" autoClose="outside">
                                                    {this.state.TiposAtivo.map(tipo => (
                                                        <Dropdown.Item
                                                            key={tipo.id}
                                                            as="label"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id={`conta-${tipo.id}`}
                                                                label={tipo.nome}
                                                                value={tipo.id}
                                                                checked={this.state.selectedTiposAtivo.includes(tipo)}
                                                                onChange={this.handleTiposChange}
                                                            />
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Form.Group as={Col} controlId="dataFinal">
                                            <Form.Label>Data Base</Form.Label>
                                            <Form.Control type="date" value={this.state.dataFinal} onChange={(event) => this.setState({ dataFinal: new Date(event.target.value).toISOString().split('T')[0] })} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="tamanhoPagina">
                                            <Form.Label>Quantidade de Registros</Form.Label>
                                            <Form.Control as="input" type="number" value={this.state.tamanhoPagina} onChange={(event) => this.setState({ tamanhoPagina: parseFloat(event.target.value) })} />
                                        </Form.Group>
                                    </Row>
                                    <Button className='mt-4'
                                        variant="primary"
                                        type="submit"
                                        disabled={!this.state.selectedAccount || this.state.isLoading}
                                    >
                                        {this.state.isLoading ? "Pesquisando..." : "Pesquisar"}
                                    </Button>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div>
                        <Button className='mt-4'
                            variant="primary"
                            disabled={this.state.isLoading} onClick={(event) => {
                                event.preventDefault();
                                this.setState({isLoading: true});
                                try {
                                    this.props.normalizarAtivoProduto(this.props.instance, this.state.selectedAccount.map(c=>c.id), ()=>{
                                        this.setState({isLoading: true});
                                    });
                                } catch (error) {
                                    this.setState({isLoading: false});
                                    console.log(error);
                                }
                                
                            }}>{this.state.isLoading ? "Normalizando..." : "Normalizar"}</Button>
                    </div>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>Conta</th>
                                <th>Código</th>
                                <th>Nome</th>
                                <th>Descrição</th>
                                <th>Observação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.RelatorioPosicaoAtivoResponse?.result?.map((item: Ativo) => {
                                return <tr key={item.id}>
                                    <td>{item.conta.nome}</td>
                                    <td>{item.produto.codigo}</td>
                                    <td>{item.produto.nome}</td>
                                    <td>{item.produto.descricao}</td>
                                    <td>{item.observacao}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <Pagination>
                        <Pagination.Prev
                            disabled={this.state.RelatorioPosicaoAtivoResponse?.pagina === 1}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.ConsultarRelatorioAtivosPendentesNormalizacao(this.props.instance,

                                    this.state.RelatorioPosicaoAtivoResponse?.pagina as number - 1,
                                    this.state.tamanhoPagina, this.state.selectedAccount.map(c=>c.id),
                                    (responseAtivos: ResponsePagination<Ativo> | undefined) => {
                                        this.setState({ RelatorioPosicaoAtivoResponse: responseAtivos, isLoading: false });
                                    });
                            }}
                        />
                        {Array.from( {length: this.state.RelatorioPosicaoAtivoResponse?.totalPagina as number }, (_, index)=>index).map((v, page) => {
                            return <Pagination.Item
                                key={page + 1}
                                active={page + 1 === this.state.RelatorioPosicaoAtivoResponse?.pagina}
                                onClick={() => {
                                    this.setState({ isLoading: true });
                                    this.props.ConsultarRelatorioAtivosPendentesNormalizacao(this.props.instance,
                                        page + 1,
                                        this.state.tamanhoPagina, this.state.selectedAccount.map(c=>c.id),
                                        (responseAtivos: ResponsePagination<Ativo> | undefined) => {
                                            this.setState({ RelatorioPosicaoAtivoResponse: responseAtivos, isLoading: false });
                                        });
                                }}
                            >
                                {page + 1}
                            </Pagination.Item>
                        })}

                        <Pagination.Next
                            disabled={this.state.RelatorioPosicaoAtivoResponse?.pagina === this.state.RelatorioPosicaoAtivoResponse?.totalPagina}
                            onClick={() => {
                                this.setState({ isLoading: true });
                                this.props.ConsultarRelatorioAtivosPendentesNormalizacao(this.props.instance,
                                    this.state.RelatorioPosicaoAtivoResponse?.pagina as number + 1,
                                    this.state.tamanhoPagina, this.state.selectedAccount.map(c=>c.id),
                                    (responseAtivos: ResponsePagination<Ativo> | undefined) => {
                                        this.setState({ RelatorioPosicaoAtivoResponse: responseAtivos, isLoading: false });
                                    });
                            }}
                        />
                    </Pagination>
                </div>
            </Layout>
        </AuthenticatedTemplate>
    }
}


export default connect(
    (state: ApplicationState) => ({
        ...state.usuarioState, ...state.relatoriosState, ...state.ativoState
    }),
    { ...UsuarioStore.actionCreators, ...RelatoriosStore.actionCreators, ...AtivoStore.actionCreators })(RelAtivoPendenteNormalizacao);