import React from 'react';
import './App.css';
import PreHome from './features/home/PreHome';
import Home from './features/home/Home';
import LoadingHome from './features/home/Loading';

import { Routes, Route, useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";
import ImportarNotas from './features/importarNotas/importarNotas';
import LancamentoConta from './features/lancamentosConta/lancamentoConta';
import Ativos from './features/ativos/ativo';
import Produto from './features/produtos/produto';
import Contratos from './features/contratos';
import RelPosicaoAtivo from './features/relatorios/posicaoAtivo';
import RelAtivoPendenteNormalizacao from './features/relatorios/ativosPendenteNormalizacao';
import * as UsuarioStore from './store/usuario/usuarioStore';
import { ApplicationState } from './store';
import { connect } from 'react-redux';

type AppProps = {
  pca: IPublicClientApplication;
} & UsuarioStore.UsuarioState & typeof UsuarioStore.actionCreators;;
function App({ pca, usuario }: any) {

  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  const permissoes = usuario?.permissoes as string[];
  var getRotas = () => {
    console.log(usuario);
    if (permissoes && permissoes.find(v => v == "Admin") == "Admin") {
      console.log("Admin");
      return <Routes>
        <Route path="/" element={<PreHome navigation={navigationClient} instance={pca} />} />
        <Route path="/dash" element={<Home navigation={navigationClient} instance={pca} />} />
        <Route path="/Loading" element={<LoadingHome navigation={navigationClient} instance={pca} />} />
        <Route path="/importarNotas" element={<ImportarNotas navigation={navigationClient} instance={pca} />} />
        <Route path="/lancamentoConta" element={<LancamentoConta navigation={navigationClient} instance={pca} />} />
        <Route path="/ativos" element={<Ativos navigation={navigationClient} instance={pca} />} />
        <Route path="/contratos" element={<Contratos navigation={navigationClient} instance={pca} />} />
        <Route path="/relposicaoativo" element={<RelPosicaoAtivo navigation={navigationClient} instance={pca} />} />
        <Route path="/relpendentenormalizacao" element={<RelAtivoPendenteNormalizacao navigation={navigationClient} instance={pca} />} />
        <Route path="/produto" element={<Produto navigation={navigationClient} instance={pca} />} />
        <Route path="/*" element={<PreHome navigation={navigationClient} instance={pca} />} />
      </Routes>
    } else {
      return <Routes>
        <Route path="/" element={<PreHome navigation={navigationClient} instance={pca} />} />
        <Route path="/dash" element={<Home navigation={navigationClient} instance={pca} />} />
        <Route path="/Loading" element={<LoadingHome navigation={navigationClient} instance={pca} />} />
        <Route path="/lancamentoConta" element={<LancamentoConta navigation={navigationClient} instance={pca} />} />
        <Route path="/ativos" element={<Ativos navigation={navigationClient} instance={pca} />} />
        <Route path="/*" element={<PreHome navigation={navigationClient} instance={pca} />} />
      </Routes>
    }
  }
  return (
   
      <MsalProvider instance={pca}>
        {getRotas()}
      </MsalProvider>
    
  );
}

export default  connect(
  (state: ApplicationState) => ({
    ...state.usuarioState
  }),
  { ...UsuarioStore.actionCreators })(App);