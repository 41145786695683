import { AccountInfo, AuthenticationResult, IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../index';
import { apiRequest, loginRequest, msalConfig } from '../../authConfig';
import ConnectionAPI, { DefaultConnection } from '../../features/connection/connectionAPI';
import { Contas, getKPIContratoContaView, getKPIDadosContaView, getKPIRentabilidadeContaView, getUsuarioSSO, IUsuarioSSORequest, IUsuarioSSOResponse, KPIContratoContaView,  KPIDadosContaView, KPIProcessarContratoConta, KPIProcessarRentabilidadeConta, KPIRentabilidadeContaView, postProcessarNotas, Usuario } from '../../features/usuario/usuarioAPI';
import { appInsights } from '../..';


export interface UsuarioState {
    usuario: Partial<Usuario> | undefined;
    contas: Partial<Contas>[] | undefined;
    status: 'idle' | 'loading' | 'failed';
    statusGetConta: 'idle' | 'loading' | 'failed';
    statusGetKPIContaCorrente: 'idle' | 'loading' | 'failed';
    statusGetKPIRentabilidade: 'idle' | 'loading' | 'failed';
    statusGetKPIContrato: 'idle' | 'loading' | 'failed';
}


interface RequestGetUsuarioSSOAction {
    type: 'REQUEST_GET_USUARIO_SSO';
}

interface ReceiveGetUsuarioSSOAction {
    type: 'RECEIVE_GET_USUARIO_SSO';
    response: IUsuarioSSOResponse;
}


interface RequestGetContasUsuarioSSOAction {
    type: 'REQUEST_GET_CONTAS_USUARIO_SSO';
}

interface ReceiveGetContasUsuarioSSOAction {
    type: 'RECEIVE_GET_CONTAS_USUARIO_SSO';
    response: IUsuarioSSOResponse;
}

interface RequestProcessarNotasAction {
    type: 'REQUEST_POST_PROCESSAR_NOTAS';
}
interface ReceiveProcessarNotasAction {
    type: 'RECEIVE_POST_PROCESSAR_NOTAS';
}


interface RequestGetKPIDadosContaAction {
    type: 'REQUEST_GET_KPI_DADOS_CONTA';
}
interface ReceiveGetKPIDadosContaAction {
    type: 'RECEIVE_GET_KPI_DADOS_CONTA';
    response: KPIDadosContaView | undefined;
}

interface RequestGetKPIRentabilidadeContaAction {
    type: 'REQUEST_GET_KPI_RENTABILIDADE_CONTA';
}
interface ReceiveGetKPIRentabilidadeContaAction {
    type: 'RECEIVE_GET_KPI_RENTABILIDADE_CONTA';
    response: KPIRentabilidadeContaView | undefined;
}

interface RequestGetKPIContratoContaAction {
    type: 'REQUEST_GET_KPI_CONTRATO_CONTA';
}
interface ReceiveGetKPIContratoContaAction {
    type: 'RECEIVE_GET_KPI_CONTRATO_CONTA';
    response: KPIContratoContaView[] | [];
}


interface RequestProcessarKPIRentabilidadeContaAction {
    type: 'REQUEST_PROCESSAR_KPI_RENTABILIDADE_CONTA';
}
interface ReceiveProcessarKPIRentabilidadeContaAction {
    type: 'RECEIVE_PROCESSAR_KPI_RENTABILIDADE_CONTA';
    response: KPIRentabilidadeContaView | undefined;
}

interface RequestProcessarKPIContratoContaAction {
    type: 'REQUEST_PROCESSAR_KPI_CONTRATO_CONTA';
}
interface ReceiveProcessarKPIContratoContaAction {
    type: 'RECEIVE_PROCESSAR_KPI_CONTRATO_CONTA';
    response: KPIContratoContaView[] | [];
}




interface ReceiveErrorNotasAction {
    type: 'RECEIVE_POST_ERROR';
}
type KnownAction = ReceiveProcessarKPIContratoContaAction| RequestProcessarKPIContratoContaAction| ReceiveProcessarKPIRentabilidadeContaAction|RequestProcessarKPIRentabilidadeContaAction|ReceiveGetKPIContratoContaAction | RequestGetKPIContratoContaAction | RequestGetKPIRentabilidadeContaAction | ReceiveGetKPIRentabilidadeContaAction | RequestGetContasUsuarioSSOAction | ReceiveGetContasUsuarioSSOAction | RequestGetKPIDadosContaAction | ReceiveGetKPIDadosContaAction | RequestGetUsuarioSSOAction | ReceiveGetUsuarioSSOAction | RequestProcessarNotasAction | ReceiveProcessarNotasAction | ReceiveErrorNotasAction;

export const actionCreators = {
    getUsuarioSSOAsync: (request: IUsuarioSSORequest, instance: IPublicClientApplication, callback: (response: IUsuarioSSOResponse) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        try {
            console.log("Entrou:getUsuarioSSOAsync ");
            const appState = getState();
            console.log("AppState", appState);
            if (appState && appState.usuarioState && appState.usuarioState.status !== "loading") {
                dispatch({ type: 'REQUEST_GET_USUARIO_SSO' });
                const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                appInsights.trackEvent({ name: 'GET_USUARIO_SSO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenPopup(loginRequest)
                            .then(function (accessTokenResponse) {
                                return accessTokenResponse;
                            })
                            .catch(function (error) {
                                // Acquire token interactive failure
                                console.log('Erro ao buscar Token:', error);
                            });
                    }
                    console.log(error);
                    appInsights.trackException(error);
                });

                const bearer = `Bearer ${responseToken?.accessToken}`;
                DefaultConnection.header = { "Authorization": bearer };
                const response = await getUsuarioSSO(request, DefaultConnection);
                dispatch({ type: 'RECEIVE_GET_USUARIO_SSO', response: response as IUsuarioSSOResponse });
                callback(response as IUsuarioSSOResponse);
            }
        } catch (error) {
            dispatch({ type: 'RECEIVE_POST_ERROR' });
            alert('Falha ao carregar Usuário:' + JSON.stringify(error));
            console.log(error);
            window.location.href = "/";
        }

    },
    getContasAsync: (instance: IPublicClientApplication, callback: (response: IUsuarioSSOResponse) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        try {
            console.log("Entrou:getContasAsync ");

            const appState = getState();
            console.log("AppState", appState);
            if (appState && appState.usuarioState && appState.usuarioState.statusGetConta !== "loading") {
                dispatch({ type: 'REQUEST_GET_CONTAS_USUARIO_SSO' });
                const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                appInsights.trackEvent({ name: 'GET_CONTAS_POR_USUARIO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                console.log('Conta:', account);
                var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenPopup(loginRequest)
                            .then(function (accessTokenResponse) {
                                return accessTokenResponse;
                            })
                            .catch(function (error) {
                                // Acquire token interactive failure
                                console.log('Erro ao buscar Token:', error);
                            });
                    }
                    console.log(error);
                    appInsights.trackException(error);
                });

                const bearer = `Bearer ${responseToken?.accessToken}`;
                DefaultConnection.header = { "Authorization": bearer };
                let response = await getUsuarioSSO({ authority: msalConfig.auth.authority, uniqueId: account?.localAccountId as string, username: account?.username as string }, DefaultConnection);
                dispatch({ type: 'RECEIVE_GET_CONTAS_USUARIO_SSO', response: response as IUsuarioSSOResponse });
                callback(response as IUsuarioSSOResponse);
            }
        } catch (error) {
            dispatch({ type: 'RECEIVE_POST_ERROR' });
            console.log('error:', error);
            alert('Falha ao carregar Contas:' + JSON.stringify(error));
            window.location.href = "/";
        }
    },
    postProcessarNotasAsync: (instance: IPublicClientApplication, data: FormData, callback: (response: string[]) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        console.log("Entrou:postProcessarNotasAsync ");
        const appState = getState();
        console.log("AppState", appState);
        if (appState && appState.usuarioState && appState.usuarioState.status !== "loading") {

            dispatch({ type: 'REQUEST_POST_PROCESSAR_NOTAS' });
            const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
            appInsights.trackEvent({ name: 'GET_POST_PROCESSAR_NOTAS_CORRETAGEM' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
            var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });
            const bearer = `Bearer ${responseToken.accessToken}`;
            DefaultConnection.header = { "Authorization": bearer };
            const response = await postProcessarNotas(data, DefaultConnection);
            dispatch({ type: 'RECEIVE_POST_PROCESSAR_NOTAS' });
            callback(response);
        }
    },
    getKPIDadosConta: (instance: IPublicClientApplication, contas: string[], callback: (response: KPIDadosContaView | undefined) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        console.log("Entrou:getKPIDadosConta");
        const appState = getState();
        console.log("AppState", appState);
        if (appState && appState.usuarioState && appState.usuarioState.statusGetKPIContaCorrente !== "loading") {
            dispatch({ type: 'REQUEST_GET_KPI_DADOS_CONTA' });
            const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
            appInsights.trackEvent({ name: 'GET_KPI_DADOS_CONTA' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
            var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });
            const bearer = `Bearer ${responseToken.accessToken}`;
            DefaultConnection.header = { "Authorization": bearer };
            try {
                const response = await getKPIDadosContaView(contas, DefaultConnection);
                dispatch({ type: 'RECEIVE_GET_KPI_DADOS_CONTA', response: response });
                callback(response);
            } catch (error) {
                dispatch({ type: 'RECEIVE_GET_KPI_DADOS_CONTA', response: undefined });
                callback(undefined);
            }
        }
    },
    getKPIRentablidadeConta: (instance: IPublicClientApplication, contas: string[], callback: (response: KPIRentabilidadeContaView | undefined) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        console.log("Entrou:getKPIRentablidadeConta");
        const appState = getState();
        console.log("AppState", appState);
        if (appState && appState.usuarioState && appState.usuarioState.statusGetKPIRentabilidade !== "loading") {
            dispatch({ type: 'REQUEST_GET_KPI_RENTABILIDADE_CONTA' });
            const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
            appInsights.trackEvent({ name: 'GET_KPI_RENTABILIDADE' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
            var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });
            const bearer = `Bearer ${responseToken.accessToken}`;
            DefaultConnection.header = { "Authorization": bearer };
            try {
                const response = await getKPIRentabilidadeContaView(contas, DefaultConnection);
                dispatch({ type: 'RECEIVE_GET_KPI_RENTABILIDADE_CONTA', response: response });
                callback(response);
            } catch (error) {
                dispatch({ type: 'RECEIVE_GET_KPI_RENTABILIDADE_CONTA', response: undefined });
                callback(undefined);
            }

        }
    },
    getKPIContratoConta: (instance: IPublicClientApplication, contas: string[], competencia: string, callback: (response: KPIContratoContaView[] | []) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        console.log("Entrou:getKPIContratoConta");
        const appState = getState();
        console.log("AppState", appState);
        if (appState && appState.usuarioState && appState.usuarioState.statusGetKPIContrato !== "loading") {
            dispatch({ type: 'REQUEST_GET_KPI_CONTRATO_CONTA' });
            const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
            appInsights.trackEvent({ name: 'REQUEST_GET_KPI_CONTRATO_CONTA' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
            var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });
            const bearer = `Bearer ${responseToken.accessToken}`;
            DefaultConnection.header = { "Authorization": bearer };
            try {
                const response = await getKPIContratoContaView(contas, competencia, DefaultConnection);
                dispatch({ type: 'RECEIVE_GET_KPI_CONTRATO_CONTA', response: response });
                callback(response);
            } catch (error) {
                dispatch({ type: 'RECEIVE_GET_KPI_CONTRATO_CONTA', response: [] });
                callback([]);
            }

        }
    }
    ,
    ProcessarKPIContratoConta: (instance: IPublicClientApplication, contas: string[], callback: (response: KPIContratoContaView[] | []) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        console.log("Entrou:ProcessarKPIContratoConta");
        const appState = getState();
        console.log("AppState", appState);
        if (appState && appState.usuarioState && appState.usuarioState.statusGetKPIContrato !== "loading") {
            dispatch({ type: 'REQUEST_PROCESSAR_KPI_CONTRATO_CONTA' });
            const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
            appInsights.trackEvent({ name: 'REQUEST_PROCESSAR_KPI_CONTRATO_CONTA' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
            var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });
            const bearer = `Bearer ${responseToken.accessToken}`;
            DefaultConnection.header = { "Authorization": bearer };
            try {
                const response = await KPIProcessarContratoConta(contas,  DefaultConnection);
                dispatch({ type: 'RECEIVE_PROCESSAR_KPI_CONTRATO_CONTA', response: response });
                callback(response);
            } catch (error) {
                dispatch({ type: 'RECEIVE_PROCESSAR_KPI_CONTRATO_CONTA', response: [] });
                callback([]);
            }

        }
    },
    ProcessarKPIRentabilidadeConta: (instance: IPublicClientApplication, contas: string[], callback: (response:  KPIRentabilidadeContaView | undefined) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        console.log("Entrou:ProcessarKPIRentabilidadeConta");
        const appState = getState();
        console.log("AppState", appState);
        if (appState && appState.usuarioState && appState.usuarioState.statusGetKPIContrato !== "loading") {
            dispatch({ type: 'REQUEST_PROCESSAR_KPI_RENTABILIDADE_CONTA' });
            const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
            appInsights.trackEvent({ name: 'REQUEST_PROCESSAR_KPI_RENTABILIDADE_CONTA' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
            var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account });
            const bearer = `Bearer ${responseToken.accessToken}`;
            DefaultConnection.header = { "Authorization": bearer };
            try {
                const response = await KPIProcessarRentabilidadeConta(contas,  DefaultConnection);
                dispatch({ type: 'RECEIVE_PROCESSAR_KPI_RENTABILIDADE_CONTA', response: response });
                callback(response);
            } catch (error) {
                dispatch({ type: 'RECEIVE_PROCESSAR_KPI_RENTABILIDADE_CONTA', response:undefined });
                callback(undefined);
            }

        }
    }
}




const unloadedState: UsuarioState = {
    usuario: undefined,
    contas: undefined,
    status: 'idle',
    statusGetConta: 'idle',
    statusGetKPIContaCorrente: 'idle',
    statusGetKPIRentabilidade: 'idle',
    statusGetKPIContrato: 'idle'
};

export const reducer: Reducer<UsuarioState> = (state: UsuarioState | undefined, incomingAction: Action): UsuarioState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_GET_USUARIO_SSO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_GET_USUARIO_SSO':
            return { ...state, ...{ status: 'idle', usuario: action.response.usuario, contas: action.response.contas } }
        case 'REQUEST_GET_CONTAS_USUARIO_SSO':
            return { ...state, ...{ statusGetConta: 'loading' } }
        case 'RECEIVE_GET_CONTAS_USUARIO_SSO':
            return { ...state, ...{ statusGetConta: 'idle', usuario: action.response.usuario, contas: action.response.contas } }
        case 'REQUEST_POST_PROCESSAR_NOTAS':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_POST_PROCESSAR_NOTAS':
            return { ...state, ...{ status: 'idle' } }
        case 'REQUEST_GET_KPI_DADOS_CONTA':
            return { ...state, ...{ statusGetKPIContaCorrente: 'loading' } }
        case 'RECEIVE_GET_KPI_DADOS_CONTA':
            return { ...state, ...{ statusGetKPIContaCorrente: 'idle' } }
        case 'REQUEST_GET_KPI_RENTABILIDADE_CONTA':
            return { ...state, ...{ statusGetKPIRentabilidade: 'loading' } }
        case 'RECEIVE_GET_KPI_RENTABILIDADE_CONTA':
            return { ...state, ...{ statusGetKPIRentabilidade: 'idle' } }
        case 'REQUEST_GET_KPI_CONTRATO_CONTA':
            return { ...state, ...{ statusGetKPIRentabilidade: 'loading' } }
        case 'RECEIVE_GET_KPI_CONTRATO_CONTA':
            return { ...state, ...{ statusGetKPIRentabilidade: 'idle' } }
        case 'RECEIVE_POST_ERROR':
            return { ...state, ...{ status: 'failed' } }
        default:
            break;
    }

    return state;
}