import { RequestPaginacao, ResponsePagination } from "../communs";
import ConnectionAPI from "../connection/connectionAPI";
import { Contas } from "../usuario/usuarioAPI";

export interface ModeloContrato {
    id: string,
    nome: string,
    descricao: string,
    condicoes: Record<string | "meta" | "rateio" | "opcoesIR" | "avistaIsencao" |  "avistaIR" | "daytradeIR" | "rateioExcedenteMeta", number>,
    urlModelo: string
}

export interface Contrato {
    contaId: string;
    conta: Contas;
    pessoa: Pessoa;
    dataInicio: string;
    dataFim?: string;
    modeloContratoId: string;
    modelo: ModeloContrato;
    nome: string;
    prioridade: number;
    produtos: Produto[];
    status: string;
    condicoes: Record<string | "meta" | "rateio" | "opcoesIR" | "avistaIsencao" |  "avistaIR" | "daytradeIR" | "rateioExcedenteMeta", number>,
    id: string;
    lucroGarantido: boolean;
  }
  
  export interface Pessoa {
    nomeOficial: string
    nomeFantasia: string
    tipoPessoa: string
    dataNascimento: string
    documento: string
    sexo: string
  }
  
  export interface Produto {
    id: string
    nome: string
    descricao: string
    tipo: string
  }

  export interface ListarContratosRequest  extends RequestPaginacao {
    contas: string[];
    modelos: string[];
    dataInicial: string;
    dataFinal: string;
}



export async function getListarContratosConta(request: ListarContratosRequest, connection: ConnectionAPI) {
    return await connection.Get<ResponsePagination<Contrato>>(ConnectionAPI.Endpoints.ListarContratos(request));
}

export async function getListarContratosContas(request: Partial<ListarContratosRequest>, connection: ConnectionAPI) {
    return await connection.Get<ResponsePagination<Contrato>>(ConnectionAPI.Endpoints.ListarContratosContas(request));
}


export async function getListarModelos( connection: ConnectionAPI) :Promise< ModeloContrato[]> {
    return await connection.Get<ModeloContrato[]>(ConnectionAPI.Endpoints.ListarModelosContrato);
}

export async function postAdicionarContrato(request: Contrato, connection: ConnectionAPI ){
    return await connection.Post<Contrato>(ConnectionAPI.Endpoints.AdicionarContrato, JSON.stringify( request), {'Content-Type':'application/json' });
}

export async function putAtualizarContrato(request: Contrato, connection: ConnectionAPI) {
    return await connection.Put<Contrato>(ConnectionAPI.Endpoints.AtualizarContrato, JSON.stringify( request), {'Content-Type':'application/json' });
}