import { AccountInfo, IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest, loginRequest } from "../../authConfig";
import { DefaultConnection } from "../../features/connection/connectionAPI";
import { AppThunkAction } from "..";
import { Action, Reducer } from 'redux';
import { Ativo, ListarProcessamentoNotasCorretagemRequest, ProcessamentoNotasCorretagem, ProcessamentoNotasCorretagemRequest, getListarLancamentosPendenteAprovacao, postGerarProcessarNotasCorretagem, putAprovarProcessamento, putRejeitarProcessamento } from "../../features/importarNotas/importarNotasAPI";
import { ListarAtivosRequest, getListarAtivosConta, normalizarAtivoProduto, postAdicionarAtivo, postAtualizarAtivo } from "../../features/ativos/ativoAPI";
import { appInsights } from "../..";
import { Produto, ResponsePagination } from "../../features/communs";
import { ListarProdutosRequest, getListarProdutos, postAdicionarProduto, postAtualizarProduto } from "../../features/produtos/produtoAPI";

export interface ProdutoState {
    produtoResponse: Partial<ResponsePagination<Produto>> | undefined;
    listarProdutosFiltro: Partial<ListarProdutosRequest> | undefined;
    status: 'idle' | 'loading' | 'failed';
}

const unloadedState: ProdutoState = {
    produtoResponse: undefined,
    listarProdutosFiltro: undefined,
    status: 'idle',
};
interface RequestGetListarProdutoAction {
    type: 'REQUEST_GET_LISTAR_PRODUTOS';
}

interface ReceiveGetListarProdutoAction {
    type: 'RECEIVE_GET_LISTAR_PRODUTOS';
    response: ResponsePagination<Produto>;
}
interface RequestAdicionarProdutoAction {
    type: 'REQUEST_POST_ADICIONAR_PRODUTO';
}

interface ReceiveAdicionarProdutoAction {
    type: 'RECEIVE_POST_ADICIONAR_PRODUTO';
    response: Produto;
}
interface RequestAtualizarProdutoAction {
    type: 'REQUEST_PUT_ATUALIZAR_PRODUTO';
}

interface ReceiveAtualizarProdutoAction {
    type: 'RECEIVE_PUT_ATUALIZAR_PRODUTO';
    response: Produto;
}
interface ReceiveErrorNotasAction {
    type: 'RECEIVE_POST_ERROR';
}
type KnownAction = ReceiveAtualizarProdutoAction | RequestAtualizarProdutoAction | ReceiveAdicionarProdutoAction | RequestGetListarProdutoAction | RequestAdicionarProdutoAction | ReceiveGetListarProdutoAction | ReceiveErrorNotasAction;

export const actionCreators = {
    
    listarProdutosAsync: (request: ListarProdutosRequest, instance: IPublicClientApplication,
        callback: (response: ResponsePagination<Produto>) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:listarProdutosAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.ativoState && appState.ativoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_GET_LISTAR_PRODUTOS' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_GET_LISTAR_PRODUTOS' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await getListarProdutos(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_GET_LISTAR_PRODUTOS', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao lista de lançamentos:' + JSON.stringify(error));
            }

        },
    adicionarProdutoAsync: (request: Produto, instance: IPublicClientApplication,
        callback: (response: Produto) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:adicionarProdutoAsync ");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.ativoState && appState.ativoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_POST_ADICIONAR_PRODUTO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_POST_ADICIONAR_PRODUTO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await postAdicionarProduto(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_POST_ADICIONAR_PRODUTO', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        },
    atualizarProdutoAsync: (request: Produto, instance: IPublicClientApplication,
        callback: (response: Produto) => void): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            // Only load data if it's something we don't already have (and are not already loading)
            try {
                console.log("Entrou:atualizarProdutoAsync");
                const appState = getState();
                console.log("AppState", appState);
                if (appState && appState.ativoState && appState.ativoState.status !== "loading") {
                    dispatch({ type: 'REQUEST_PUT_ATUALIZAR_PRODUTO' });
                    const account: AccountInfo | undefined = instance.getActiveAccount() as AccountInfo | undefined;
                    appInsights.trackEvent({ name: 'REQUEST_PUT_ATUALIZAR_PRODUTO' }, { UsuarioBX: appState.usuarioState.usuario?.username as string, UsuarioSSO: account?.username });
                    var responseToken = await instance.acquireTokenSilent({ ...apiRequest, account: account }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(loginRequest)
                                .then(function (accessTokenResponse) {
                                    return accessTokenResponse;
                                })
                                .catch(function (error) {
                                    // Acquire token interactive failure
                                    console.log(error);
                                });
                        }
                        console.log(error);
                    });;


                    const bearer = `Bearer ${responseToken?.accessToken}`;
                    DefaultConnection.header = { "Authorization": bearer };
                    const response = await postAtualizarProduto(request, DefaultConnection);
                    dispatch({ type: 'RECEIVE_PUT_ATUALIZAR_PRODUTO', response: response });
                    callback(response);
                }
            } catch (error) {
                dispatch({ type: 'RECEIVE_POST_ERROR' });
                alert('Falha ao adicionar lançamento:' + JSON.stringify(error));
            }

        }
}




export const reducer: Reducer<ProdutoState> = (state: ProdutoState | undefined, incomingAction: Action): ProdutoState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_GET_LISTAR_PRODUTOS':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_GET_LISTAR_PRODUTOS':
            return { ...state, ...{ status: 'idle', produtoResponse: action.response } }
        case 'REQUEST_POST_ADICIONAR_PRODUTO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_POST_ADICIONAR_PRODUTO':
            state.produtoResponse?.result?.push(action.response);
            return { ...state, ...{ status: 'idle', produtoResponse:state.produtoResponse } }
        case 'REQUEST_PUT_ATUALIZAR_PRODUTO':
            return { ...state, ...{ status: 'loading' } }
        case 'RECEIVE_PUT_ATUALIZAR_PRODUTO':
            return { ...state, ...{ status: 'idle', produtoResponse:{
                ...state.produtoResponse, 
                ...{result:state.produtoResponse?.result?.map((l :Produto) =>{ 
                    if(l.id == action.response.id){ 
                        return action.response; 
                    }else{ 
                        return l; 
                    } })} } } }
        case 'RECEIVE_POST_ERROR':
            return { ...state, ...{ status: 'failed' } }
        default:
            break;
    }

    return state;
}